// import 'date-fns';
import React from 'react';
import NoImage from '../../images/no-img.png';
import PropTypes from 'prop-types';
import CompanyController from '../../controllers/CompanyController';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, FilledInput } from '@material-ui/core';
import { clearUploadUrls, getUploadUrls, readUploadUrls } from '../../helpers/UploadReader';
import { isNullOrUndefined } from '../../helpers/Utils';
import { ItemImage } from '../../components/Common/ItemImage';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

export function CompanyAddEdit(props) {
    const { company, open, onClose, onError, onDone, companyId } = props;
    const [scoreFrom, setScoreFrom] = React.useState(null);
    const [inputValue, setInputValue] = React.useState(null);

    const [name, setName] = React.useState('');
    const [image, setImage] = React.useState('');
    const [, updateState] = React.useState();
    const isEditing = Object.keys(company ?? {}).length !== 0;

    const forceUpdate = React.useCallback(() => {
        updateState({});
    }, []);

    // update fields with current company info
    React.useEffect(() => {
        setName(company?.name ?? '');
        setImage(company?.logoPath ?? '');
        setScoreFrom(company?.scoreFrom);
    }, [company]);

    function getImage() {
        return getUploadUrls()[0] ?? (image === '' ? NoImage : image);
    }

    async function handleSave(event) {
        event.preventDefault();
        onError(null);
        const response = isNullOrUndefined(company?.id)
            ? await CompanyController.addCompany(companyId, name, image)
            : await CompanyController.updateCompany(company.id, name, image, moment(scoreFrom).toISOString());
        if (response.hasError) {
            onError(response.data);
        } else {
            onDone(response.data);
        }
        onClose();
    }

    function handleInput(event) {
        const { value, name, files } = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'image':
                clearUploadUrls();
                if (!isNullOrUndefined(files[0])) {
                    setImage(files[0]);
                    readUploadUrls([files[0]], forceUpdate);
                } else {
                    setImage('');
                }
                break;
            case 'scoreFrom':
                setScoreFrom(value);
                break;
            default:
                break;
        }
    }

    const onDateChange = (date, value) => {
        setScoreFrom(date);
        setInputValue(value);
    };

    function handleClearImage() {
        clearUploadUrls();
        setImage('');
    }

    return (
        <Dialog open={open}>
            <form onSubmit={handleSave}>
                <DialogTitle>Add/Edit Company</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField variant="filled" label="Name" value={name} onChange={handleInput} name="name" required fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel shrink>Image</InputLabel>
                                        <FilledInput
                                            id="upload-input"
                                            aria-describedby="upload-helper"
                                            type="file"
                                            name="image"
                                            onChange={handleInput}
                                            inputProps={{
                                                accept: 'image/*',
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                {isEditing ? (
                                    <Grid item xs={12}>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                clearable
                                                autoOk={true}
                                                showTodayButton={true}
                                                value={scoreFrom}
                                                format="DD-MM-YYYY"
                                                inputValue={inputValue}
                                                onChange={onDateChange}
                                                inputVariant="filled"
                                                label="Score From"
                                                fullWidth
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <ItemImage image={getImage()} onClose={handleClearImage} disabled={image === ''} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit">
                        Save
                    </Button>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

CompanyAddEdit.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    company: PropTypes.object,
};
