import React from 'react';
import PropTypes from 'prop-types';
import QuestionController from '../../controllers/QuestionController';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    Slider,
    makeStyles,
    IconButton,
    Chip,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    FilledInput,
    Avatar,
} from '@material-ui/core';
import { DarkMenuItem } from '../../components/Common/DarkMenuItem';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Add, Clear, Delete, Done, Remove } from '@material-ui/icons';
import { Colours } from '../../helpers/Colours';
import { Alert } from '../../components/Common/Alert';
import { Autocomplete } from '@material-ui/lab';
import { Documents } from '../Documents/Documents';

const useStyles = makeStyles(() => ({
    slider: {
        paddingTop: 30,
    },
    answerRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .answer': {
            flexGrow: 1,
        },
        '& .toggle-correct': {
            backgroundColor: Colours.red,
            color: Colours.white,
            marginLeft: 16,
            '&.active': {
                backgroundColor: Colours.green,
            },
            '&:hover': {
                backgroundColor: Colours.darkRed,
                '&.active': {
                    backgroundColor: Colours.darkGreen,
                },
            },
        },
    },
    addRemoveWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectArea: {
        '& .MuiInputBase-root': {
            paddingTop: 23,
            paddingBottom: 4,
        },
    },
    chip: {
        marginRight: 2,
        marginBottom: 2,
    },
    loading: {
        maxHeight: '60vh',
        height: 512,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    questionsContainer: {
        position: 'relative',
    },
    imageAnswer: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    imageAnswerWrapper: {
        width: '100%',
    },
}));

const defaultAnswer = isCorrect => ({
    title: '',
    description: '',
    imageUrl: '',
    isCorrect,
});

export function ComplianceAddEditQuestion(props) {
    const { question, open, onClose, onDone, companyId, categoriesArray, shipsArray } = props;
    const [categoryOptions, setCategoryOptions] = React.useState([]);
    const [questionCategories, setQuestionCategories] = React.useState([]);
    const [selectedShips, setSelectedShips] = React.useState([]);
    const [selectedDocs, setSelectedDocs] = React.useState([]);
    const [content, setContent] = React.useState('');
    const [questionNote, setQuestionNote] = React.useState('');
    const [severity, setSeverity] = React.useState(50);
    const [startSeverity, setStartSeverity] = React.useState(50);
    const [frequency, setFrequency] = React.useState(100);
    const [startFrequency, setStartFrequency] = React.useState(100);
    const [answers, setAnswers] = React.useState([defaultAnswer(false), defaultAnswer(true)]);
    const [answerImages, setAnswerImages] = React.useState([]);
    const [allowNFC, setAllowNFC] = React.useState(false);
    const [showPickDocument, setShowPickDocument] = React.useState(false);
    const [showAddCategory, setShowAddCategory] = React.useState(false);
    const [categoryName, setCategoryName] = React.useState('');
    const [selectPageId, setSelectPageId] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [warning, setWarning] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const classes = useStyles();

    const questionExists = () => !isNullOrUndefined(question?.id);

    const loadQuestion = React.useCallback(async () => {
        if (question?.id !== null && question?.id !== undefined) {
            const response = await QuestionController.getQuestion(companyId, question.id);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                const { title, frequency, serverity, answers, category, documentItems, ships, allowNFC, description } = response.data;
                const categoryIds = category.map(e => e.id);
                setContent(title);
                setSelectedDocs(documentItems);
                setQuestionCategories(categoriesArray.filter(e => categoryIds.includes(e.id)));
                setSelectedShips(ships.map(e => e.id));
                setCategoryOptions(categoriesArray);
                setQuestionNote(description);
                setSeverity(serverity);
                setStartSeverity(serverity);
                setFrequency(frequency);
                setStartFrequency(frequency);
                setAnswers(answers);
                setAllowNFC(allowNFC);
            }
        } else {
            setContent('');
            setSelectedDocs([]);
            setQuestionCategories([]);
            setSelectedShips([]);
            setSeverity(50);
            setStartSeverity(50);
            setFrequency(100);
            setStartFrequency(100);
            setCategoryOptions(categoriesArray);
            setAnswers([defaultAnswer(false), defaultAnswer(true)]);
            setAllowNFC(false);
        }
        setAnswerImages([]);
    }, [categoriesArray, question, companyId]);

    // update fields with current question info
    React.useEffect(() => {
        async function init() {
            if (open) {
                await loadQuestion();
            }
        }
        setLoading(true);
        init().then(() => setLoading(false));
    }, [open, loadQuestion]);

    // update selected page number
    React.useEffect(() => {
        if (isNullOrUndefined(selectPageId)) {
            setPageNumber(0);
        } else {
            setPageNumber(selectedDocs.find(e => e.id === selectPageId).pageNum);
        }
    }, [selectPageId, selectedDocs]);

    async function handleSave(event) {
        event.preventDefault();
        setSaving(true);

        const response = questionExists()
            ? await QuestionController.editQuestion(
                  question.id,
                  companyId,
                  content,
                  questionNote,
                  frequency,
                  severity,
                  answers,
                  answerImages,
                  questionCategories.map(e => e.id),
                  selectedDocs.map(e => ({ documentId: e.id, pageNum: e.pageNum })),
                  selectedShips,
                  allowNFC
              )
            : await QuestionController.addQuestion(
                  companyId,
                  content,
                  questionNote,
                  1,
                  frequency,
                  severity,
                  answers,
                  answerImages,
                  questionCategories.map(e => e.id),
                  selectedDocs.map(e => ({ documentId: e.id, pageNum: e.pageNum })),
                  selectedShips,
                  allowNFC
              );
        if (response.hasError) {
            setWarning(response.data);
        } else {
            onDone(response.data);
        }

        setSaving(false);
        onClose();
    }

    async function handleAddCategory(event) {
        event.preventDefault();
        setSaving(true);

        const response = await QuestionController.addCategory(companyId, categoryName);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setCategoryOptions([...categoryOptions, response.data]);
        }

        setShowAddCategory(false);
        setSaving(false);
    }

    function handleInput(event) {
        const { value, name } = event.target;
        switch (name) {
            case 'content':
                setContent(value);
                break;
            case 'questionNote':
                setQuestionNote(value);
                break;
            case 'categoryName':
                setCategoryName(value);
                break;
            case 'pageNumber':
                if (value.length !== 0 && (isNaN(parseInt(value)) || parseInt(value) < 0)) {
                    break;
                }
                setPageNumber(value.length === 0 ? '' : parseInt(value));
                break;
            default:
                break;
        }
    }

    function handleSelectCategory(value) {
        if (value.includes('')) {
            setQuestionCategories([]);
        } else if (value.includes('new')) {
            setShowAddCategory(true);
            setCategoryName('');
        } else {
            setQuestionCategories(value);
        }
    }

    function handleUpdatePageNum(event) {
        event.preventDefault();
        setSelectedDocs(selectedDocs.map(e => (e.id === selectPageId ? { ...e, pageNum: pageNumber } : e)));
        setSelectPageId(null);
        setPageNumber(0);
    }

    function handleAddAnswer() {
        const newAnswers = [...answers, defaultAnswer(false)];
        setAnswers(newAnswers);
    }

    function handleRemoveAnswer() {
        if (answers.length === 1) {
            return;
        }
        const newAnswers = [...answers];
        newAnswers.pop();
        setAnswers(newAnswers);
    }

    function handleChangeCorrectAnswer(index) {
        setAnswers(answers.map((e, i) => ({ ...e, isCorrect: i === index })));
    }

    function handleChangeAnswerTitle(index, newTitle) {
        setAnswers(answers.map((e, i) => ({ ...e, title: i === index ? newTitle : e.title })));
    }

    function handleChangeAnswerImage(index, event) {
        const { files } = event.target;
        const newImageIndex = answerImages.length;
        const noEntry = index >= newImageIndex;
        if (files.length > 0) {
            if (noEntry) {
                setAnswerImages([...answerImages, files[0]]);
                setAnswers(answers.map((e, i) => (i === index ? { ...e, imageIndex: newImageIndex } : e)));
            } else {
                setAnswerImages(answerImages.map((e, i) => (i === index ? files[0] : e)));
                setAnswers(answers.map((e, i) => (i === index ? { ...e, imageIndex: newImageIndex } : e)));
            }
        }
    }

    function handleDeleteAnswerImage(index) {
        setAnswers(answers.map((e, i) => (i === index ? { ...e, imageUrl: null } : e)));
    }

    function buildAddCategoryDialog() {
        return (
            <Dialog open={showAddCategory} onClose={() => setShowAddCategory(false)}>
                <form onSubmit={handleAddCategory}>
                    <DialogTitle>Add New Category</DialogTitle>
                    <DialogContent>
                        <TextField variant="filled" label="Category Name" onChange={handleInput} value={categoryName} name="categoryName" required fullWidth />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" type="submit" disabled={saving}>
                            Add Category
                        </Button>
                        <Button color="primary" onClick={() => setShowAddCategory(false)}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }

    function buildSelectPageDialog() {
        return (
            <Dialog open={!isNullOrUndefined(selectPageId)} onClose={() => setSelectPageId(null)}>
                <form onSubmit={handleUpdatePageNum}>
                    <DialogTitle>Select Page Number</DialogTitle>
                    <DialogContent>
                        <TextField variant="filled" label="Page Number" type="number" onChange={handleInput} value={pageNumber} name="pageNumber" required fullWidth />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" type="submit" disabled={saving}>
                            Confirm
                        </Button>
                        <Button color="primary" onClick={() => setSelectPageId(null)}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }

    function buildErrorDialog() {
        return (
            <Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Alert header="Something went wrong!" text={warning} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setWarning(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildDialogContent() {
        return (
            <form onSubmit={handleSave}>
                <DialogTitle>Add/Edit Question</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel id="question-category-label">Categories</InputLabel>
                                        <Select
                                            labelId="question-category-label"
                                            value={questionCategories}
                                            onChange={e => handleSelectCategory(e.target.value)}
                                            name="categories"
                                            variant="filled"
                                            multiple
                                            fullWidth
                                        >
                                            {categoryOptions.map(e => (
                                                <DarkMenuItem key={e.id} value={e}>
                                                    {e.name}
                                                </DarkMenuItem>
                                            ))}
                                            <DarkMenuItem key="new" value="new">
                                                <em>Add New</em>
                                            </DarkMenuItem>
                                            <DarkMenuItem key="none" value="">
                                                <em>None Selected</em>
                                            </DarkMenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth variant="outlined" onClick={() => setShowPickDocument(true)} startIcon={<Add />}>
                                        Attach Document
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {selectedDocs.map((e, i) => {
                                        return (
                                            <Chip
                                                key={i}
                                                className={classes.chip}
                                                color="primary"
                                                avatar={e.fileType.includes('image') ? <Avatar src={e.url} /> : null}
                                                label={`${e.title}.${e.fileType.split('/')[e.fileType.split('/').length - 1]}${e.fileType.includes('pdf') ? ` (pg. ${e.pageNum})` : ''}`}
                                                onDelete={() => setSelectedDocs(selectedDocs.filter(d => d.id !== e.id))}
                                            />
                                        );
                                    })}
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        value={selectedShips}
                                        onChange={(_, v) => setSelectedShips(v)}
                                        className={classes.selectArea}
                                        options={shipsArray.sort((a, b) => -b.name[0].toUpperCase().localeCompare(a.name[0].toUpperCase())).map(e => e.id)}
                                        groupBy={item => shipsArray.find(e => e.id === item).name[0].toUpperCase()}
                                        getOptionLabel={item => shipsArray.find(e => e.id === item).name}
                                        renderInput={params => <TextField {...params} label="Linked Ships" variant="filled" InputLabelProps={{ shrink: true }} />}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                const name = shipsArray.find(e => e.id === option).name;
                                                return <Chip key={index} color="primary" label={name} {...getTagProps({ index })} />;
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField variant="filled" label="Question" onChange={handleInput} value={content} name="content" required fullWidth multiline rows={2} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField variant="filled" label="Question Note" onChange={handleInput} value={questionNote} name="questionNote" fullWidth multiline rows={2} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>Severity</InputLabel>
                                        <Slider
                                            className={classes.slider}
                                            defaultValue={startSeverity}
                                            valueLabelDisplay="auto"
                                            onChangeCommitted={(_, v) => setSeverity(v)}
                                            name="severity"
                                            step={1}
                                            min={0}
                                            max={100}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>Default Frequency</InputLabel>
                                        <Slider
                                            className={classes.slider}
                                            defaultValue={startFrequency}
                                            scale={x => (x * 0.01).toFixed(1)}
                                            valueLabelDisplay="auto"
                                            onChangeCommitted={(_, v) => setFrequency(v)}
                                            name="frequency"
                                            step={1}
                                            min={1}
                                            max={100}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormControlLabel control={<Checkbox checked={allowNFC} onChange={e => setAllowNFC(e.target.checked)} color="primary" />} label="Allow NFC" />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Grid container spacing={2} className={classes.questionsContainer}>
                                {answers.map((e, i) => (
                                    <Grid key={i} item xs={12} className={classes.answerRow}>
                                        <div className={classes.imageAnswerWrapper}>
                                            <TextField
                                                variant="filled"
                                                label={`Answer ${i + 1}`}
                                                className={'answer'}
                                                onChange={e => handleChangeAnswerTitle(i, e.target.value)}
                                                value={e.title}
                                                fullWidth
                                            />
                                            <br />
                                            {isNullOrUndefined(e.imageUrl) || e.imageUrl.trim().length === 0 ? (
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel shrink>{`Answer ${i + 1} Image`}</InputLabel>
                                                    <FilledInput
                                                        type="file"
                                                        name="image"
                                                        inputProps={{
                                                            accept: 'image/*',
                                                        }}
                                                        onChange={event => handleChangeAnswerImage(i, event)}
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            ) : (
                                                <div className={classes.imageAnswer} style={{ backgroundImage: `url(${e.imageUrl})` }}>
                                                    <IconButton onClick={() => handleDeleteAnswerImage(i)}>
                                                        <Delete />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                        <IconButton size="small" className={`toggle-correct${e.isCorrect ? ' active' : ''}`} onClick={() => handleChangeCorrectAnswer(i)} disabled={questionExists()}>
                                            {e.isCorrect ? <Done /> : <Clear />}
                                        </IconButton>
                                    </Grid>
                                ))}
                                <Grid item xs={12} className={classes.addRemoveWrapper}>
                                    <IconButton onClick={handleAddAnswer} disabled={questionExists()}>
                                        <Add />
                                    </IconButton>
                                    <IconButton onClick={handleRemoveAnswer} disabled={answers.length <= 1 || questionExists()}>
                                        <Remove />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={saving} type="submit">
                        Save
                    </Button>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        );
    }

    return (
        <>
            <Dialog open={open} maxWidth="md" fullWidth>
                {loading ? (
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    buildDialogContent()
                )}
            </Dialog>

            {buildSelectPageDialog()}
            {buildAddCategoryDialog()}
            {buildErrorDialog()}

            <Documents
                pickOnly={true}
                open={showPickDocument}
                companyId={companyId}
                onClose={() => setShowPickDocument(false)}
                onSelect={document => {
                    setSelectedDocs([...selectedDocs, document]);
                    if (document.fileType.includes('pdf')) {
                        setSelectPageId(document.id);
                    }
                }}
            />
        </>
    );
}

ComplianceAddEditQuestion.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    categoriesArray: PropTypes.array.isRequired,
    shipsArray: PropTypes.array.isRequired,
    question: PropTypes.object,
};
