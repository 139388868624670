import Axios from 'axios';
import UserController from './UserController';
import { APIPath, APIRoute } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const AdminController = {
    banUser,
    unBanUser,
};

async function banUser(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.BAN_USER + id, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function unBanUser(id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.UN_BAN_USER + id, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default AdminController;
