import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, FormControl, InputLabel } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import CompanyController from '../../controllers/CompanyController';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { DarkMenuItem } from '../../components/Common/DarkMenuItem';
import moment from 'moment';
import ShipController from '../../controllers/ShipController';

export default function MassRestPeriod(props) {
    const { open, onClose, companyId } = props;
    const [ships, setShips] = React.useState([]);
    const [selectedShip, setSelectedShip] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [monthSelect, setMonthSelect] = React.useState('01');
    const [yearSelect, setYearSelect] = React.useState('2021');
    const [documentDate, setDocumentDate] = React.useState(moment.now());
    const [documentUrl, setDocumentLink] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [warning, setWarning] = React.useState(null);

    // initialise when user or open state changes
    React.useEffect(() => {
        async function init() {
            const shipResponse = await ShipController.getShips(companyId);
            if (shipResponse.hasError) {
                setWarning(shipResponse.data);
                return;
            }
            setShips(shipResponse.data);
        }

        setLoading(true);
        init().then(() => setLoading(false));
    }, [companyId, setShips]);

    React.useEffect(() => {
        async function loadUsers() {
            if (isNullOrUndefined(selectedShip) || selectedShip === '') {
                setUsers([]);
                setSelectedUsers([]);
                return;
            }
            const userResponse = await ShipController.getShipUsers(companyId, selectedShip);
            if (userResponse.hasError) {
                setWarning(userResponse.data);
                return;
            }
            setUsers(userResponse.data);
            setSelectedUsers([]);
        }

        setLoading(true);
        loadUsers().then(() => setLoading(false));
    }, [companyId, setUsers, selectedShip]);

    const generateDocument = React.useCallback(async () => {
        if (isNullOrUndefined(selectedShip) || selectedShip === '') {
            setWarning('You must select a ship and users first');
            return;
        }
        if (selectedUsers.length === 0) {
            setWarning('You must select at least one user');
            return;
        }
        setLoading(true);
        const selectedDate = moment.utc(`01/${monthSelect}/${yearSelect}`, 'dd/MM/yyyy');

        const response = await CompanyController.getMassRestPeriodDocument(companyId, selectedUsers, selectedShip, selectedDate.toISOString());
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setDocumentDate(selectedDate);
            setDocumentLink(response.data);
        }
        setLoading(false);
    }, [companyId, selectedShip, selectedUsers, monthSelect, yearSelect, setDocumentLink, setDocumentDate]);

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function handelClose() {
        setDocumentLink(null);
        setDocumentDate('');

        onClose();
    }

    function buildErrorDialog() {
        return (
            <Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Alert header="Something went wrong!" text={warning} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setWarning(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function GenerateYears() {
        let testDate = moment('01/01/2020', 'dd/MM/yyyy');
        const years = [];
        while (testDate.isSameOrBefore(moment.now())) {
            years.push(testDate.format('yyyy'));
            testDate = testDate.add(1, 'year');
        }
        return years;
    }

    let link;
    if (documentUrl != null) {
        const blob = new Blob([base64ToArrayBuffer(documentUrl)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        link = window.URL.createObjectURL(blob);
    }
    return (
        <>
            <Dialog open={open} maxWidth="xs" fullWidth>
                <DialogTitle>User Rest Periods</DialogTitle>
                <DialogContent>
                    <LoadingOverlay loading={loading} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="filled">
                                <InputLabel id="select-ship-label">Ship</InputLabel>
                                <Select variant="filled" labelId="select-ship-label" value={selectedShip} onChange={e => setSelectedShip(e.target.value)} required fullWidth>
                                    {ships.map(e => (
                                        <DarkMenuItem key={e.id} value={e.id}>
                                            {e.name}
                                        </DarkMenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="filled">
                                <InputLabel id="select-users-label">Users</InputLabel>
                                <Select variant="filled" labelId="select-users-label" value={selectedUsers} onChange={e => setSelectedUsers(e.target.value)} required fullWidth multiple>
                                    {users.map(e => (
                                        <DarkMenuItem key={e.id} value={e.id}>{`${e.firstName} ${e.lastName}`}</DarkMenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth required variant="filled">
                                <InputLabel id="select-months-label">Month</InputLabel>
                                <Select variant="filled" labelId="select-months-label" value={monthSelect} onChange={e => setMonthSelect(e.target.value)} required fullWidth>
                                    <DarkMenuItem key={'01'} value={'01'}>
                                        01
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'02'} value={'02'}>
                                        02
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'03'} value={'03'}>
                                        03
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'04'} value={'04'}>
                                        04
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'05'} value={'05'}>
                                        05
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'06'} value={'06'}>
                                        06
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'07'} value={'07'}>
                                        07
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'08'} value={'08'}>
                                        08
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'09'} value={'09'}>
                                        09
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'10'} value={'10'}>
                                        10
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'11'} value={'11'}>
                                        11
                                    </DarkMenuItem>
                                    <DarkMenuItem key={'12'} value={'12'}>
                                        12
                                    </DarkMenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth required variant="filled">
                                <InputLabel id="select-Year-label">Year</InputLabel>
                                <Select variant="filled" labelId="select-Year-label" value={yearSelect} onChange={e => setYearSelect(e.target.value)} required fullWidth>
                                    {GenerateYears().map(e => (
                                        <DarkMenuItem key={e} value={e}>
                                            {e}
                                        </DarkMenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Button color="primary" onClick={generateDocument} disabled={selectedShip === '' || selectedUsers.length === 0} fullWidth>
                                Generate
                            </Button>
                        </Grid>
                        {documentUrl !== null ? (
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`${ships.find(e => e.id === selectedShip).name}-${moment(documentDate).format('MM-YYYY')}.xlsx`}
                                    fullWidth
                                >
                                    Download {moment(documentDate).format('MM/YYYY')}
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handelClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {buildErrorDialog()}
        </>
    );
}

MassRestPeriod.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
};
