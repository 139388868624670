import { isNullOrUndefined, generateResponse } from './Utils';

export const IndexedDB = {
    init,
    add,
    remove,
    fetch,
};

var db;
var dbInitalised = false;
const dbName = 'SentiniMarine-AdminPortal-IndexedDB';
const dbVersion = 1;

const hasIndexedDB = () => 'indexedDB' in window;

async function init() {
    return new Promise(resolve => {
        if (!hasIndexedDB) {
            resolve(generateResponse(true, 'IndexedDB is not supported'));
            return;
        }
        const request = indexedDB.open(dbName, dbVersion);
        request.onerror = () => resolve(generateResponse(true, 'IndexedDB is not supported!'));
        request.onsuccess = event => {
            db = event.target.result;
            resolve(setupDatabase());
        };
        request.onupgradeneeded = event => {
            const db = event.target.result;
            const objStore = db.createObjectStore('tokenData', { keyPath: 'name' });
            objStore.createIndex('content', 'content', { unique: false });
        };
    }).then(output => {
        return generateResponse(output.hasError, output.data);
    });
}

async function setupDatabase() {
    return new Promise(resolve => {
        if (isNullOrUndefined(db)) {
            resolve(generateResponse(true, 'Cannot setup database when none is found!'));
            return;
        }
        db.onerror = event => console.error('Database error: ' + event.target.errorCode);
        dbInitalised = true;
        resolve(generateResponse(false, null));
    }).then(output => {
        return generateResponse(output.hasError, output.data);
    });
}

async function add(name, content) {
    return new Promise(resolve => {
        if (!dbInitalised) {
            resolve(generateResponse(true, 'Cannot save tokens before the database has initialised!'));
            return;
        }
        const tokenObj = { name, content };
        const request = db.transaction('tokenData', 'readwrite').objectStore('tokenData').add(tokenObj);
        request.onsuccess = () => resolve(generateResponse(false, tokenObj));
        request.onerror = () => resolve(generateResponse(true, 'Token add failed!'));
    }).then(output => {
        return generateResponse(output.hasError, output.data);
    });
}

async function remove(name) {
    return new Promise(resolve => {
        if (!dbInitalised) {
            resolve(generateResponse(true, 'Cannot remove tokens before the database has initialised!'));
            return;
        }
        const request = db.transaction('tokenData', 'readwrite').objectStore('tokenData').delete(name);
        request.onsuccess = () => resolve(generateResponse(false, name));
        request.onerror = () => resolve(generateResponse(true, 'Token delete failed!'));
    }).then(output => {
        return generateResponse(output.hasError, output.data);
    });
}

async function fetch(name) {
    return new Promise(resolve => {
        if (!dbInitalised) {
            resolve(generateResponse(true, 'Cannot fetch tokens before the database has initialised!'));
            return;
        }
        const request = db.transaction('tokenData', 'readwrite').objectStore('tokenData').get(name);
        request.onsuccess = event => resolve(generateResponse(false, event.target.result));
        request.onerror = () => resolve(generateResponse(true, 'Token fetch failed!'));
    }).then(output => {
        return generateResponse(output.hasError, output.data);
    });
}
