import React from 'react';
import PropTypes from 'prop-types';
import QuestionController from '../../controllers/QuestionController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Add } from '@material-ui/icons';
import { makeStyles, Grid, Button, TextField, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { SearchCardMini } from '../../components/Common/SearchCardMini';
import { isNullOrUndefined } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import ComplianceCategoryAddEdit from './ComplianceCategoryAddEdit.js';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '16px 32px',
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
    topArea: {
        paddingBottom: 8,
        '& .MuiButtonBase-root': {
            height: '100%',
        },
    },
}));

function ComplianceCategory(props) {
    const { Auth, PushHistory } = props;
    const [category, setCategory] = React.useState([]);
    const [nameSearch, setNameSearch] = React.useState('');
    const [deleteRef, setDeleteRef] = React.useState(null);
    const [addEditRef, setAddEditRef] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const classes = useStyles();

    const loadCategory = React.useCallback(async () => {
        setLoading(true);
        const response = await QuestionController.getCategories(Auth.companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setCategory(response.data);
        }
        setLoading(false);
    }, [Auth]);

    // init & auth check
    React.useEffect(() => {
        // ensure user is signed in
        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }
        // fetch user groups for inital render
        async function init() {
            await loadCategory();
        }
        init();
    }, [Auth, loadCategory]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    function handleUpdateCategory(edited) {
        const { id } = edited;
        const exists = !isNullOrUndefined(category.filter(e => e.id === id)[0]);
        if (exists) {
            setCategory(category.map(e => (e.id === id ? edited : e)));
        } else {
            setCategory([...category, edited]);
        }
    }

    async function handleDeleteCategory() {
        setLoading(true);
        setWarning(null);
        const { id } = deleteRef;
        const response = await QuestionController.removeCategories(Auth.companyId, id);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setCategory(category.filter(e => e.id !== id));
        }
        setDeleteRef(null);
        setLoading(false);
    }

    function handleInput(event) {
        const { value, name } = event.target;
        switch (name) {
            case 'name':
                setNameSearch(value);
                break;
            default:
                break;
        }
    }

    function buildTopArea() {
        return (
            <Grid container spacing={2} className={classes.topArea}>
                <Grid item xs="auto">
                    <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setAddEditRef({})}>
                        Add Category
                    </Button>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <TextField label="Search by Name" variant="filled" value={nameSearch} onChange={handleInput} name="name" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Alert header="Something went wrong!" text={warning} />
                </Grid>
            </Grid>
        );
    }

    function buildCategory(category) {
        const { id, name } = category;
        return (
            <Grid key={id} item lg={2} md={3} sm={4} xs={12}>
                <SearchCardMini title={name}>
                    <Button onClick={() => setAddEditRef(category)}>Edit</Button>
                    <Button onClick={() => setRedirectUrl('/Compliance')}>Go Back</Button>
                    <Button onClick={() => setDeleteRef(category)}>Delete</Button>
                </SearchCardMini>
            </Grid>
        );
    }

    function buildUsers() {
        if (isNullOrUndefined(category)) {
            return null;
        }

        return (
            <Grid container spacing={2}>
                {category.filter(e => e.name.includes(nameSearch)).map(buildCategory)}
            </Grid>
        );
    }

    function buildConfirmDelete() {
        return (
            <Dialog open={!isNullOrUndefined(deleteRef)} onClose={() => setDeleteRef(null)}>
                <DialogTitle>Delete Category</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleDeleteCategory()}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => setDeleteRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay loading={loading} />
            {buildTopArea()}
            {buildUsers()}
            {buildConfirmDelete()}
            <ComplianceCategoryAddEdit
                open={!isNullOrUndefined(addEditRef)}
                onClose={() => setAddEditRef(null)}
                onError={setWarning}
                onDone={handleUpdateCategory}
                category={addEditRef}
                companyId={Auth.companyId}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceCategory);

ComplianceCategory.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};
