import React from 'react';
import PropTypes from 'prop-types';
import ShipController from '../../controllers/ShipController';
import UserGroupController from '../../controllers/UserGroupController';
import { Alert } from '../../components/Common/Alert';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import CompanyController from '../../controllers/CompanyController';

function SendPushNotificationRender(props) {
    const { open, companyId, onClose } = props;

    const [initialized, setInitialized] = React.useState(false);
    const [ships, setShips] = React.useState([]);
    const [userGroups, setUserGroups] = React.useState([]);
    const [title, setTitle] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [selectedShips, setSelectedShips] = React.useState([]);
    const [selectedUserGroups, setSelectedUserGroups] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [warning, setWarning] = React.useState(null);

    React.useEffect(() => {
        async function Init() {
            setLoading(true);
            const shipResponse = await ShipController.getShips(companyId);
            if (shipResponse.hasError) {
                setWarning('Failed to load ships - ' + shipResponse.data);
                setLoading(false);
                return;
            }
            setShips(shipResponse.data);
            const userGroupResponse = await UserGroupController.getUserGroups(companyId);
            if (userGroupResponse.hasError) {
                setWarning('Failed to load user groups - ' + userGroupResponse.data);
                setLoading(false);
                return;
            }
            setUserGroups(userGroupResponse.data);
            setInitialized(true);
            setLoading(false);
        }

        if (open && !initialized) {
            Init();
        }
    }, [open, initialized, companyId, setInitialized, setShips, setUserGroups, setLoading]);

    React.useEffect(() => {
        // on company Id change, Clear all cached Data
        setShips([]);
        setUserGroups([]);
        setInitialized(false);
    }, [companyId, setInitialized, setShips, setUserGroups]);

    function handleSelect(values = [], name) {
        switch (name) {
            case 'Ships':
                setSelectedShips(values);
                break;
            case 'UserGroup':
                setSelectedUserGroups(values);
                break;
            default:
                break;
        }
    }

    async function handelSend() {
        setLoading(true);
        setWarning(null);
        if (message.length === 0 || title.length === 0) {
            setWarning('The title and message must not be empty');
            setLoading(false);
            return;
        }

        if (selectedShips.length === 0) {
            setWarning('You must select at least one ship');
            setLoading(false);
            return;
        }

        const response = await CompanyController.sendCompanyPushNotif(
            companyId,
            title,
            message,
            selectedShips.map(e => e.id),
            selectedUserGroups.map(e => e.id)
        );
        if (response.hasError) {
            setWarning('Failed to send Push Notification - ' + response.data);
            setLoading(false);
            return;
        }

        setLoading(false);
        handelClose();
    }

    function handelClose() {
        setWarning(null);
        setTitle('');
        setMessage('');
        setSelectedShips([]);
        setSelectedUserGroups([]);
        setLoading(false);
        onClose();
    }

    function buildAutocompleteFromList(array, value, label, name) {
        return (
            <Autocomplete
                multiple
                options={array}
                getOptionLabel={e => e.name}
                getOptionSelected={e => value.filter(a => a.id === e.id).length > 0}
                /*className={classes.selectArea}*/
                onChange={(_, values) => handleSelect(values, name)}
                value={value}
                placeholder="Search by Name"
                filterSelectedOptions
                renderInput={params => <TextField {...params} variant="filled" label={label} InputLabelProps={{ shrink: true }} />}
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip key={index} color="primary" label={option.name} {...getTagProps({ index })} />)}
            />
        );
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Send Push Notification</DialogTitle>
            <DialogContent>
                <LoadingOverlay loading={loading} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {warning !== null ? <Alert header="Something went wrong!" text={warning} /> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Title" value={title} variant="filled" fullWidth required onChange={e => setTitle(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Message" value={message} variant="filled" fullWidth multiline required rows={3} onChange={e => setMessage(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        {buildAutocompleteFromList(ships, selectedShips, 'Ships *', 'Ships')}
                    </Grid>
                    <Grid item xs={12}>
                        {buildAutocompleteFromList(userGroups, selectedUserGroups, 'User Groups', 'UserGroup')}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handelClose}>
                    Close
                </Button>
                <Button color="primary" onClick={handelSend}>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const SendPushNotification = React.memo(SendPushNotificationRender, function areEqual(prevProps, currentProps) {
    return prevProps.open === currentProps.open && prevProps.companyId === currentProps.companyId;
});

export default SendPushNotification;

SendPushNotificationRender.propTypes = {
    open: PropTypes.bool.isRequired,
    companyId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
