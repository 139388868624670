import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse, isNullOrUndefined } from '../helpers/Utils';

const ComplianceController = {
    getUserComplianceTests,
    getComplianceTests,
    getComplianceTestsById,
    getComplianceTest,
    getComplianceTestCategories,
    addComplianceTest,
    editComplianceTest,
    getCompanyComplianceResults,
    getShipComplianceResults,
    getUserComplianceResults,
    getComplianceResults,
    getQuestionOverview,
    getAssessmentOverview,
    getUserGroupsOverview,
};

async function getUserComplianceTests(companyId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USER_COMPLIANCE_TESTS(companyId, userId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getComplianceTests(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPLIANCE_TESTS(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getComplianceTest(companyId, assessmentId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPLIANCE_TEST(companyId, assessmentId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getComplianceTestsById(companyId, assessmentIds) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.GET_COMPLIANCE_TESTS_SPECIFIC(companyId), assessmentIds, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getComplianceTestCategories(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPLIANCE_TEST_CATEGORIES(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addComplianceTest(
    companyId,
    title,
    iconIndex,
    categoryId,
    cronScheduling,
    start,
    end,
    allQuestions,
    questionCount,
    scoreRequirement,
    numPrevTestsToAccount,
    questions,
    shipIds,
    userIds,
    userGroupIds,
    complianceTestLevels
) {
    const userHeaders = await UserController.getUserHeaders();
    const complianceModel = {
        title,
        iconIndex,
        categoryId,
        cronScheduling,
        start,
        end,
        allQuestions,
        questionCount,
        scoreRequirement,
        numPrevTestsToAccount,
        questions,
        shipIds,
        userIds,
        userGroupIds,
        complianceTestLevels,
    };
    return Axios.post(APIRoute + APIPath.ADD_COMPLIANCE_TEST(companyId), complianceModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editComplianceTest(
    companyId,
    id,
    title,
    iconIndex,
    categoryId,
    cronScheduling,
    start,
    end,
    allQuestions,
    questionCount,
    scoreRequirement,
    numPrevTestsToAccount,
    questions,
    shipIds,
    userIds,
    userGroupIds,
    complianceTestLevels
) {
    const userHeaders = await UserController.getUserHeaders();
    const complianceModel = {
        id,
        title,
        iconIndex,
        categoryId,
        cronScheduling,
        start,
        end,
        allQuestions,
        questionCount,
        scoreRequirement,
        numPrevTestsToAccount,
        questions,
        shipIds,
        userIds,
        userGroupIds,
        complianceTestLevels,
    };
    return Axios.post(APIRoute + APIPath.EDIT_COMPLIANCE_TEST(companyId), complianceModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCompanyComplianceResults(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.COMPANY_COMPLIANCE_TEST_RESULTS(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getShipComplianceResults(companyId, shipId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.SHIP_COMPLIANCE_TEST_RESULTS(companyId, shipId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getUserComplianceResults(companyId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.USER_COMPLIANCE_TEST_RESULTS(companyId, userId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getComplianceResults(companyId, userId, complianceId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.USER_TEST_RESULTS(companyId, userId, complianceId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getQuestionOverview(companyId, shipId = null, userId = null) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.QUESTION_OVERVIEW(companyId, isNullOrUndefined(shipId) ? null : shipId, isNullOrUndefined(userId) ? null : userId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getAssessmentOverview(companyId, shipId = null) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.ASSESSMENT_OVERVIEW(companyId, isNullOrUndefined(shipId) ? null : shipId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getUserGroupsOverview(companyId, shipId = null) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.USER_GROUPS_OVERVIEW(companyId, isNullOrUndefined(shipId) ? null : shipId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default ComplianceController;
