import React from 'react';
import BridgeImage from '../../images/bridge.png';
import SentiniLogo from '../../images/fathom-final.png';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Button, Grid, Container, Typography, TextField, makeStyles } from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';
import { Link } from 'react-router-dom';
import { AuthLayout } from '../../components/Auth/AuthLayout';

const useStyles = makeStyles(() => ({
    formRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginBottom: 32,
        maxWidth: 300,
    },
}));

function PasswordForgot(props) {
    const [userName, setUserName] = React.useState('');
    const [warningText, setWarningText] = React.useState(null);
    const [emailSent, setEmailSent] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const classes = useStyles();

    // initialise
    React.useEffect(() => {
        if (props.Auth.isAuthenticated) {
            props.PushHistory('/Login');
        }
    }, [props]);

    async function handleSubmit(event) {
        event.preventDefault();
        setWarningText(null);
        setSubmitting(true);
        const response = await UserController.forgotPassword(userName);
        if (response.hasError) {
            setWarningText(response.data);
        } else {
            setEmailSent(true);
        }
        setSubmitting(false);
    }

    function handleInput(event) {
        const value = event.target.value;
        setUserName(value);
    }

    function buildEmailForm() {
        return (
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField name="userName" label="Enter User Name" margin="normal" variant="filled" onChange={handleInput} value={userName} type="text" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert header="Something went wrong!" text={warningText} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={submitting} color="primary" variant="contained" type="submit" fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }

    function buildSuccessContent() {
        return (
            <Grid container spacing={3} style={{ marginTop: 20 }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" align="center" paragraph>
                        An email has been sent to your account containing a reset password link
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button component={Link} to="/Login" variant="contained" color="primary" fullWidth>
                        Back to Login
                    </Button>
                </Grid>
            </Grid>
        );
    }

    function buildContent() {
        return !emailSent ? buildEmailForm() : buildSuccessContent();
    }

    return (
        <AuthLayout heroImage={BridgeImage}>
            <Container maxWidth="xs" className={classes.formRoot}>
                <img src={SentiniLogo} alt="Sentini Marine" className={classes.logo} />
                <Typography variant="h2" align="center" gutterBottom style={{ fontSize: 42 }}>
                    Forgot Password
                </Typography>
                {buildContent()}
            </Container>
        </AuthLayout>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgot);

PasswordForgot.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};
