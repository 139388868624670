import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import UserGroupController from '../../controllers/UserGroupController';

export default function UsersGroupsAddEdit(props) {
    const { userGroup, open, onClose, onError, onDone, companyId } = props;
    const [name, setName] = React.useState('');
    const [scored, setScored] = React.useState(true);

    const userGroupExists = () => !isNullOrUndefined(userGroup?.id);

    // update fields with current user group info
    React.useEffect(() => {
        setName(userGroup?.name ?? '');
        setScored(userGroup?.scored ?? true);
    }, [userGroup]);

    async function handleSave(event) {
        event.preventDefault();
        onError(null);
        const response = userGroupExists() ? await UserGroupController.editUserGroup(companyId, userGroup.id, name, scored) : await UserGroupController.addUserGroup(companyId, name, scored);
        if (response.hasError) {
            onError(response.data);
        } else {
            onDone(response.data);
        }
        onClose();
    }

    function handleInput(event) {
        const { name, value, checked } = event.target;

        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'scored':
                setScored(checked);
                break;
            default:
                break;
        }
    }

    return (
        <Dialog open={open}>
            <form onSubmit={handleSave}>
                <DialogTitle>Add/Edit User Group</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField variant="filled" label="Name" value={name} onChange={handleInput} name="name" required fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox checked={scored} onChange={handleInput} name="scored" />} label="Results scored?" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit">
                        Save
                    </Button>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

UsersGroupsAddEdit.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    userGroup: PropTypes.object,
};
