import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FileController from '../../controllers/FileController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { isNullOrUndefined } from '../../helpers/Utils';
import { makeStyles, Button, FormControl, FormHelperText, Input, InputLabel, Grid, Typography, Container, Card, CardMedia } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import { SpacedDivider } from '../../components/Common/SpacedDivider';

const useStyles = makeStyles(() => ({
    media: {
        height: 126,
    },
}));

function FileManager(props) {
    const { Auth, PushHistory } = props;
    const [loading, setLoading] = React.useState(true);
    const [file, setFile] = React.useState(null);
    const [imageUrls, setImageUrls] = React.useState([]);
    const [warningText, setWarningText] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const classes = useStyles();

    const fetchUserImages = useCallback(async () => {
        setWarningText(null);
        const response = await FileController.getUsersFiles();
        if (!response.hasError) {
            setImageUrls(response.data?.urls);
        } else {
            setWarningText(response.data);
        }
    }, []);

    // initialise
    React.useEffect(() => {
        async function init() {
            if (!Auth.isAuthenticated) {
                setRedirectUrl('/Login');
                return;
            }
            await fetchUserImages();
            setLoading(false);
        }
        init();
    }, [Auth, fetchUserImages, setRedirectUrl]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [PushHistory, redirectUrl]);

    async function handleSubmit(event) {
        event.preventDefault();
        setWarningText(null);
        setLoading(true);
        const response = await FileController.uploadFile(file);
        if (response.hasError) {
            setWarningText(response.data);
        }
        await fetchUserImages();
        setLoading(false);
    }

    const handleFile = event => {
        const value = event.target.files[0];
        setFile(value);
    };

    function buildImages() {
        if (isNullOrUndefined(imageUrls) || imageUrls.length === 0) {
            return null;
        }
        return imageUrls.map((image, index) => (
            <Grid item xs={4} key={index}>
                <Card>
                    <CardMedia className={classes.media} image={image} />
                </Card>
            </Grid>
        ));
    }

    function buildUploadForm() {
        return (
            <form onSubmit={handleSubmit}>
                <Typography variant="h3" align="center" gutterBottom>
                    Upload File
                </Typography>
                <Alert header="Something went wrong!" text={warningText} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Upload File</InputLabel>
                            <Input id="upload-input" aria-describedby="upload-helper" type="file" name="uploadFile" onChange={handleFile} required fullWidth />
                            <FormHelperText>Insert max file size here!</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" type="submit">
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }

    return (
        <Container maxWidth="xs">
            <LoadingOverlay loading={loading} />
            {buildUploadForm()}

            <SpacedDivider className="lg-bottom lg-top" />

            <Grid container spacing={1}>
                {buildImages()}
            </Grid>
        </Container>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});
const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileManager);

FileManager.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};
