import Axios from 'axios';
import moment from 'moment';
import { APIRoute, APIPath, LoginRoute, SSOKey } from '../helpers/Constants';
import { flattenFormContent, generateResponse, isNullOrUndefined, isStatusOK } from '../helpers/Utils';
import { IndexedDB } from '../helpers/IndexedDB';

const UserController = {
    getUserHeaders,
    getToken,
    hasTokenExpired,
    refreshToken,
    registerMagicLink,
    createMagicLink,
    editMagicLink,
    resendMagicLink,
    removeMagicLink,
    login,
    loginWithPasswordReset,
    logout,
    finishExternalLogin,
    getPasswordRequirements,
    forgotPassword,
    resetPassword,
    changePassword,
    addDeviceToken,
    getMOTD,
    seenMOTD,
    requestEmailConfirmation,
    checkForEmailConfirmation,
    getUserInfo,
    getRanks,
    getAuthenticatorCode,
    addTwoFactor,
    removeTwoFactor,
    verifyTwoFactor,
    refreshTokenCache,
    fetchCachedUserData,
    updateUserLeave,
    deleteUser,
};

async function getUserHeaders(contentType = null) {
    return await IndexedDB.fetch('authToken')
        .then(response => {
            if (isNullOrUndefined(contentType)) {
                return { headers: { Authorization: 'Bearer ' + response.data?.content } };
            } else {
                return {
                    headers: {
                        'Content-Type': contentType,
                        Authorization: 'Bearer ' + response.data?.content,
                    },
                };
            }
        })
        .catch(() => {
            return null;
        });
}

async function getToken() {
    return await IndexedDB.fetch('authToken')
        .then(response => {
            return response.data?.content;
        })
        .catch(() => {
            return null;
        });
}

async function hasTokenExpired() {
    return await IndexedDB.fetch('expires')
        .then(response => {
            return moment.utc(response.data.content).isSameOrBefore(moment.utc());
        })
        .catch(() => {
            return null;
        });
}

async function refreshToken(refreshToken) {
    const userHeaders = await getUserHeaders();
    const refreshModel = {
        refreshToken,
    };
    return Axios.post(LoginRoute + APIPath.REFRESH_TOKEN, refreshModel, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function registerMagicLink(userName, token, password, rememberMe) {
    const regModel = {
        userName,
        token,
        password,
        rememberMe,
    };
    return Axios.post(LoginRoute + APIPath.REGISTER_MAGIC_LINK, regModel)
        .then(async response => {
            return generateResponse(false, response.data);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function createMagicLink(userName, email, firstName, lastName, phoneNumber, profilePic, companyRole, userGroupsId, ships, restPeriodsEnabled, canGenerateNFCTags, rank, secondaryEmail = null) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('secondaryEmail', secondaryEmail);
    formData.append('email', email);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    if (phoneNumber !== null) {
        formData.append('phoneNumber', phoneNumber);
    }
    formData.append('profilePic', profilePic);
    formData.append('companyRole', companyRole);
    formData.append('userGroupsId', userGroupsId);
    formData.append('restPeriodsEnabled', restPeriodsEnabled);
    formData.append('canGenerateNFCTags', canGenerateNFCTags);
    formData.append('rank', rank);

    ships.forEach((e, i) => flattenFormContent('ships', formData, e, i));
    return Axios.post(APIRoute + APIPath.CREATE_MAGIC_LINK, formData, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editMagicLink(userName, email, firstName, lastName, phoneNumber, profilePic, companyRole, userGroupsId, ships, restPeriodsEnabled, canGenerateNFCTags, rank, secondaryEmail = null) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('email', email);
    formData.append('secondaryEmail', secondaryEmail);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    if (phoneNumber !== null) {
        formData.append('phoneNumber', phoneNumber);
    }
    formData.append('profilePic', profilePic);
    formData.append('companyRole', companyRole);
    formData.append('userGroupsId', userGroupsId);
    formData.append('restPeriodsEnabled', restPeriodsEnabled);
    formData.append('canGenerateNFCTags', canGenerateNFCTags);
    formData.append('rank', rank);

    ships.forEach((e, i) => flattenFormContent('ships', formData, e, i));
    return Axios.post(APIRoute + APIPath.EDIT_MAGIC_LINK, formData, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function resendMagicLink(userName) {
    const userHeaders = await getUserHeaders();
    return Axios.get(APIRoute + APIPath.RESEND_MAGIC_LINK + `?userName=${encodeURIComponent(userName)}`, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function removeMagicLink(userName) {
    const userHeaders = await getUserHeaders();
    return Axios.get(APIRoute + APIPath.REMOVE_MAGIC_LINK + `?userName=${encodeURIComponent(userName)}`, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function login(userName, password, rememberMe, twoFactorToken = null) {
    const loginModel = {
        userName,
        password,
        rememberMe,
        twoFactorToken,
        Token: SSOKey,
    };
    return Axios.post(LoginRoute + APIPath.LOGIN, loginModel)
        .then(async response => {
            if (isStatusOK(response.status)) {
                await clearCachedUserData();
                const infoResponse = await getMyInfo(response.data.token);
                if (infoResponse.hasError) {
                    return generateResponse(true, 'failed to get user info');
                }
                const userData = { ...response.data, ...infoResponse.data };
                return await cacheUserData(userData).then(cacheResponse => {
                    const output = cacheResponse.hasError ? cacheResponse.data : userData;
                    return generateResponse(cacheResponse.hasError, output, response);
                });
            } else {
                return generateResponse(true, response.data);
            }
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function loginWithPasswordReset(userName, password, rememberMe, twoFactorToken, newPassword, newConfirmationPassword) {
    const resetModel = {
        userName,
        password,
        rememberMe,
        twoFactorToken,
        newPassword,
        newConfirmationPassword,
        Token: SSOKey,
    };
    return Axios.post(LoginRoute + APIPath.LOGIN_PASSWORD_RESET, resetModel)
        .then(async response => {
            if (isStatusOK(response.status)) {
                const infoResponse = await getMyInfo(response.data.token);
                if (infoResponse.hasError) {
                    return generateResponse(true, 'failed to get user info');
                }
                return await cacheUserData(response.data).then(cacheResponse => {
                    const output = cacheResponse.hasError ? cacheResponse.data : response.data;
                    return generateResponse(cacheResponse.hasError, { ...output, ...infoResponse.data }, response);
                });
            } else {
                return generateResponse(true, response.data);
            }
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function logout() {
    const userHeaders = await getUserHeaders();
    const clearResponse = await clearCachedUserData();
    if (clearResponse.hasError) {
        return clearResponse;
    }
    return Axios.post(LoginRoute + APIPath.LOGOUT, null, userHeaders)
        .then(async response => {
            return generateResponse(false, null, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function finishExternalLogin() {
    const userHeaders = await getUserHeaders();
    return Axios.get(LoginRoute + APIPath.FINISH_EXTERNAL_LOGIN, userHeaders)
        .then(response => {
            if (!response.data.exists) {
                return generateResponse(true, 'Unable to find account, please register first');
            } else if (!response.data) {
                return generateResponse(true, 'Failed to connect to service');
            }
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getPasswordRequirements() {
    return Axios.get(LoginRoute + APIPath.PASSWORD_REQUIREMENTS)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function forgotPassword(userName) {
    const forgotModel = { userName, Token: SSOKey };
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.FORGOT_PASSWORD, forgotModel, userHeaders)
        .then(response => {
            return generateResponse(false, null, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function resetPassword(userId, code, password, passwordConfirmation) {
    const resetModel = {
        userId,
        code,
        password,
        passwordConfirmation,
    };
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.RESET_PASSWORD, resetModel, userHeaders)
        .then(response => {
            return generateResponse(false, null, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function changePassword(currentPassword, newPassword, newPasswordConfirmation) {
    const changeModel = {
        currentPassword,
        newPassword,
        newPasswordConfirmation,
    };
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.CHANGE_PASSWORD, changeModel, userHeaders)
        .then(response => {
            return generateResponse(false, null, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addDeviceToken(token) {
    const userHeaders = await getUserHeaders();
    return Axios.post(APIRoute + APIPath.ADD_DEVICE + '?deviceToken=' + token, null, userHeaders)
        .then(() => {
            console.log('Device token updated.');
            return true;
        })
        .catch(reason => {
            console.log('Device token update failed!', reason);
            return false;
        });
}

async function getMOTD() {
    const userHeaders = await getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MOTD, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function seenMOTD() {
    const userHeaders = await getUserHeaders();
    return Axios.get(APIRoute + APIPath.SEEN_MOTD, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function requestEmailConfirmation() {
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.REQUEST_EMAIL_CONFIRMATION, null, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function checkForEmailConfirmation(userId, code) {
    const data = { userId, code };
    return Axios.post(LoginRoute + APIPath.EMAIL_CONFIRMATION, data)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getUserInfo() {
    const userHeaders = await getUserHeaders();
    return Axios.get(LoginRoute + APIPath.GET_USER_INFO, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getAuthenticatorCode() {
    const userHeaders = await getUserHeaders();
    return Axios.get(LoginRoute + APIPath.TWO_FACTOR_GET_KEY, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addTwoFactor(secret, token) {
    const addModel = { secret, token };
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.TWO_FACTOR_ADD, addModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function removeTwoFactor(token) {
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.TWO_FACTOR_REMOVE, { token }, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function verifyTwoFactor(twoFactorToken) {
    const data = { twoFactorToken };
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.VERIFY_TWO_FACTOR, data, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

//Only used by login functions
async function getMyInfo(authToken) {
    return Axios.get(APIRoute + APIPath.MY_INFO, { headers: { Authorization: 'Bearer ' + authToken } })
        .then(async response => {
            return await cacheUserInfo(response.data);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getRanks() {
    const userHeaders = await getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_RANKS, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function updateUserLeave(userId, onLeave) {
    const data = { userId, onLeave };
    const userHeaders = await getUserHeaders();
    return Axios.post(LoginRoute + APIPath.UPDATE_USER_LEAVE, data, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function deleteUser(userId) {
    const userHeaders = await getUserHeaders();
    return Axios.delete(`${APIRoute + APIPath.USER}/${userId}`, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function refreshTokenCache(token, refreshToken, expires) {
    try {
        await IndexedDB.remove('expires').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('expires', expires).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('authToken').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('authToken', token).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('refreshToken').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('refreshToken', refreshToken).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        return generateResponse(false, { token, expires });
    } catch (e) {
        return generateResponse(true, e?.data);
    }
}

export async function updateCompanyId(companyId) {
    try {
        await IndexedDB.remove('companyId').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('companyId', companyId).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        return generateResponse(false, companyId);
    } catch (e) {
        return generateResponse(true, e?.data);
    }
}

async function cacheUserInfo(userInfo) {
    try {
        const { companyId, companyRole, shipRoles } = userInfo;
        await IndexedDB.add('companyId', companyId).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('companyRole', companyRole).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('shipRoles', shipRoles).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        return generateResponse(false, userInfo);
    } catch (e) {
        return generateResponse(true, e?.data);
    }
}

async function cacheUserData(userData) {
    try {
        const { userName, role, token, refreshToken, expires, id } = userData;
        await IndexedDB.add('userName', userName).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('role', role).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('expires', expires).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('authToken', token).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('refreshToken', refreshToken).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.add('id', id).then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        return generateResponse(false, userData);
    } catch (e) {
        return generateResponse(true, e?.data);
    }
}

async function fetchCachedUserData() {
    try {
        let userName, role, token, refreshToken, expires, companyId, companyRole, shipRoles, id;
        await IndexedDB.fetch('userName').then(response => {
            if (response.hasError) {
                throw response;
            }
            userName = response.data?.content;
        });
        await IndexedDB.fetch('role').then(response => {
            if (response.hasError) {
                throw response;
            }
            role = response.data?.content;
        });
        await IndexedDB.fetch('expires').then(response => {
            if (response.hasError) {
                throw response;
            }
            expires = response.data?.content;
        });
        await IndexedDB.fetch('authToken').then(response => {
            if (response.hasError) {
                throw response;
            }
            token = response.data?.content;
        });
        await IndexedDB.fetch('refreshToken').then(response => {
            if (response.hasError) {
                throw response;
            }
            refreshToken = response.data?.content;
        });
        await IndexedDB.fetch('companyId').then(response => {
            if (response.hasError) {
                throw response;
            }
            companyId = response.data?.content;
        });
        await IndexedDB.fetch('companyRole').then(response => {
            if (response.hasError) {
                throw response;
            }
            companyRole = response.data?.content;
        });
        await IndexedDB.fetch('shipRoles').then(response => {
            if (response.hasError) {
                throw response;
            }
            shipRoles = response.data?.content;
        });
        await IndexedDB.fetch('id').then(response => {
            if (response.hasError) {
                throw response;
            }
            id = response.data?.content;
        });
        return generateResponse(false, { userName, role, token, refreshToken, expires, companyId, companyRole, shipRoles, id });
    } catch (e) {
        return generateResponse(true, e?.data);
    }
}

async function clearCachedUserData() {
    try {
        await IndexedDB.remove('userName').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('role').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('expires').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('refreshToken').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('authToken').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('companyId').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('companyRole').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('shipRoles').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        await IndexedDB.remove('id').then(response => {
            if (response.hasError) {
                throw response;
            }
        });
        return generateResponse(false, null);
    } catch (e) {
        return generateResponse(true, e?.data);
    }
}

export default UserController;
