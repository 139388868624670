import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, CardContent, Typography, IconButton, Popover, Chip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Colours } from '../../helpers/Colours';
import { isNullOrUndefined } from '../../helpers/Utils';

const useStyles = makeStyles(theme => ({
    questionItem: props => ({
        position: 'relative',
        display: 'flex',
        padding: '16px !important',
        backgroundColor: props.isArchived ? Colours.archived : Colours.white,
        '& .question': {
            flexGrow: 1,
            [theme.breakpoints.down('xs')]: {
                marginRight: 30,
            },
        },
        '& .categories': {
            width: 140,
            textAlign: 'right',
            marginRight: 30,
            '& .MuiChip-root': {
                margin: 2,
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        '& .options': {
            position: 'absolute',
            right: 4,
            top: 4,
        },
        '& .archived': {
            color: Colours.bodyText,
        },
    }),
    popover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiButton-root': {
            textTransform: 'none',
            minWidth: 120,
        },
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
            fontWeight: 400,
        },
    },
}));

export function ComplianceQuestionCard(props) {
    const { id, title, categories, isArchived, children } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles(props);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? 'search-item-popover' : undefined;

    return (
        <>
            <Card variant="outlined">
                <CardContent className={classes.questionItem}>
                    <div className="question">
                        {isArchived ? (
                            <Typography variant="caption" className="archived">
                                <b>Archived</b>
                            </Typography>
                        ) : null}
                        <Typography variant="h5" gutterBottom>
                            Q{id}
                        </Typography>
                        <Typography variant="body1">{title}</Typography>
                    </div>
                    <div className="categories">
                        {categories.map(e => (
                            <Chip key={e.id} variant="outlined" label={e.name} />
                        ))}
                    </div>
                    {!isNullOrUndefined(children) ? (
                        <IconButton className="options" size="small" onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                    ) : null}
                </CardContent>
            </Card>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.popover}
            >
                {children}
            </Popover>
        </>
    );
}

ComplianceQuestionCard.propTypes = {
    id: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    isArchived: PropTypes.bool.isRequired,
    categories: PropTypes.array.isRequired,
    children: PropTypes.any,
};
