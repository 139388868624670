export const setCompanyCompliance = items => ({
    type: 'SET_COMPANY_COMPLIANCE',
    items,
});

export const setShipCompliance = items => ({
    type: 'SET_SHIP_COMPLIANCE',
    items,
});

export const setUserCompliance = items => ({
    type: 'SET_USER_COMPLIANCE',
    items,
});

export const setAssessmentCompliance = items => ({
    type: 'SET_ASSESSMENT_COMPLIANCE',
    items,
});

export const setShips = items => ({
    type: 'SET_SHIPS',
    items,
});

export const setShipUsers = items => ({
    type: 'SET_SHIP_USERS',
    items,
});

export const setUserGroups = items => ({
    type: 'SET_USER_GROUPS',
    items,
});

export const clearStore = () => ({
    type: 'INITIALISE_STORE',
});
