import React from 'react';
import SentiniLogo from '../../images/fathom-final.png';
import BridgeImage from '../../images/bridge.png';
import AppStore from '../../images/app-store.png';
import GooglePlay from '../../images/google-play.png';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { TextField, Button, Grid, Container, FormControlLabel, Checkbox, makeStyles, Typography } from '@material-ui/core';
import { checkPassword } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { LogIn } from '../../stores/Actions/Authentication';
import { AuthLayout } from '../../components/Auth/AuthLayout';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    formRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginBottom: 32,
        maxWidth: 300,
    },
    storeLink: {
        display: 'inline-block',
        width: '50%',
        '& img': {
            WebkitBoxShadow: '0px 0px 2px 0px rgba(0,0,0,0.6)',
            boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.6)',
            borderRadius: 12,
            maxWidth: 160,
            margin: 6,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

function Register(props) {
    const { userName, token } = queryString.parse(props.location.search);
    const [password, setPassword] = React.useState('');
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
    const [acceptsTerms, setAcceptsTerms] = React.useState(false);
    const [passwordRequirements, setPasswordRequirements] = React.useState(null);
    const [passwordErrors, setPasswordErrors] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);
    const [warningText, setWarningText] = React.useState(null);
    const [completeRegistration, setCompleteRegistration] = React.useState(false);
    const classes = useStyles();

    // initialise
    React.useEffect(() => {
        async function init() {
            const pwordReqResponse = await UserController.getPasswordRequirements();
            if (!pwordReqResponse.hasError) {
                setPasswordRequirements(pwordReqResponse.data);
            } else {
                setWarningText('Failed to fetch password requirements');
            }
        }
        init();
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();
        setSubmitting(true);
        setWarningText(null);

        if (!acceptsTerms) {
            setWarningText('You must confirm you have read and agree to the Terms of Service and Privacy Policy');
            setSubmitting(false);
            return;
        }

        if (password !== passwordConfirmation) {
            setWarningText('Password and confirmation do not match');
            setSubmitting(false);
            return;
        }

        const response = await UserController.registerMagicLink(userName, token, password, false);
        if (!response.hasError) {
            setCompleteRegistration(true);
        } else {
            setWarningText(response.data);
            setSubmitting(false);
        }
    }

    function handleInput(event) {
        const { name, value, checked } = event.target;
        switch (name) {
            case 'password':
                setPassword(value);
                break;
            case 'passwordConfirmation':
                setPasswordConfirmation(value);
                break;
            case 'acceptsTerms':
                setAcceptsTerms(checked);
                break;
            default:
                return;
        }
    }

    async function checkPasswordIsValid() {
        setPasswordErrors(checkPassword(passwordRequirements, password));
    }

    function buildPasswordErrors() {
        if (passwordErrors.length === 0) {
            return null;
        }
        return passwordErrors.map((e, i) => (
            <React.Fragment key={i}>
                <span>{e}</span>
                <br />
            </React.Fragment>
        ));
    }

    function buildFormContent() {
        if (completeRegistration) {
            return null;
        }
        return (
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField id="username-input" label="User Name" required value={userName} disabled name="username" variant="filled" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="password-input"
                            label="Password"
                            type="password"
                            autoComplete="new-password"
                            required
                            value={password}
                            onChange={handleInput}
                            name="password"
                            onBlur={() => checkPasswordIsValid()}
                            helperText={buildPasswordErrors()}
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="password-input"
                            label="Confirm Password"
                            type="password"
                            autoComplete="new-password"
                            required
                            value={passwordConfirmation}
                            onChange={handleInput}
                            name="passwordConfirmation"
                            variant="filled"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={acceptsTerms} onChange={handleInput} name="acceptsTerms" color="primary" />}
                            label={
                                <span>
                                    I have read and agree to the{' '}
                                    <a href="/EULA" target="_blank">
                                        Terms of Service
                                    </a>{' '}
                                    and{' '}
                                    <a href="/Privacy" target="_blank">
                                        Privacy Policy
                                    </a>
                                </span>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth type="submit" disabled={submitting} variant="contained" color="primary">
                            Register
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert header="Something went wrong!" text={warningText} />
                    </Grid>
                </Grid>
            </form>
        );
    }

    function buildCompleteForm() {
        if (!completeRegistration) {
            return null;
        }
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h6" align="center">
                        Registration Complete
                    </Typography>
                    <Typography gutterBottom align="center">
                        You can now login to the app using the email and password provided, or click the button below to be taken to the administration login.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" align="center">
                        Download the mobile app here
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="a" href="https://apps.apple.com/us/app/yacht-knowledge/id1539901275" align="center" className={classes.storeLink}>
                        <img alt="App Store" src={AppStore} />
                    </Typography>
                    <Typography component="a" href="https://play.google.com/store/apps/details?id=com.sentinimarine.cbt" align="center" className={classes.storeLink}>
                        <img alt="Google Play" src={GooglePlay} />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" component={Link} to="/Login" color="primary">
                        Admin Login
                    </Button>
                </Grid>
            </Grid>
        );
    }

    return (
        <AuthLayout heroImage={BridgeImage}>
            <Container maxWidth="xs" className={classes.formRoot}>
                <img src={SentiniLogo} alt="Sentini Marine" className={classes.logo} />
                {buildFormContent()}
                {buildCompleteForm()}
            </Container>
        </AuthLayout>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    LogIn: data => dispatch(LogIn(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

Register.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    LogIn: PropTypes.func,
    location: PropTypes.object,
};
