import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isNullOrUndefined } from '../../helpers/Utils';
import moment from 'moment';

function QuestionTagRender(props) {
    const { assessments, questions, onClose, open } = props;
    const [tagAmount, setTagAmount] = React.useState(1);
    const [selectedAssessments, setSelectedAssessments] = React.useState([]);
    const [generatedCSV, setGeneratedCSV] = React.useState(null);

    // update fields with current user group info

    async function generateCsv() {
        const csvRows = ['Content Type,Data,qr code,Quantity,Question Id'];
        for (let i = 0; i < selectedAssessments.length; i++) {
            const assessment = selectedAssessments[i];
            const assessmentNFCQuestions = assessment.questions.filter(e => e.allowNFC);
            for (let k = 0; k < assessmentNFCQuestions.length; k++) {
                const question = questions.find(e => e.id === assessmentNFCQuestions[k].id);
                for (let j = 0; j < question.answers.length; j++) {
                    const answer = question.answers[j];
                    csvRows.push(`application/com.sentinimarine.cbt.answertag,${answer.id},${answer.id},${tagAmount},${question.id}`);
                }
            }
        }
        setGeneratedCSV(csvRows.join('\n'));
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Generate Question Tags Export</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="amount of each tag"
                            value={tagAmount}
                            onChange={event => {
                                setTagAmount(Math.max(1, event.target.valueAsNumber));
                                setGeneratedCSV(null);
                            }}
                            fullWidth
                            variant="filled"
                            type="Number"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            fullWidth
                            options={assessments.filter(e => e.questions.some(r => r.allowNFC))}
                            getOptionLabel={e => e.title}
                            getOptionSelected={e => selectedAssessments.some(r => r === e.id)}
                            /*className={classes.selectArea}*/
                            onChange={(_, values) => {
                                setSelectedAssessments(values);
                                setGeneratedCSV(null);
                            }}
                            value={selectedAssessments}
                            placeholder="Search by Name"
                            filterSelectedOptions
                            renderInput={params => <TextField {...params} variant="filled" label="Filter Assessment" InputLabelProps={{ shrink: true }} />}
                            renderTags={(value, getTagProps) => value.map((option, index) => <Chip key={index} color="primary" label={option.title} {...getTagProps({ index })} />)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {!isNullOrUndefined(generatedCSV) ? (
                            <Button href={'data:application/octet-stream,' + generatedCSV} download={`TagExport-${moment().format('DD-MM-YYYY HH-mm')}.csv`} fullWidth>
                                Download
                            </Button>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={generateCsv}>
                    Generate
                </Button>
                <Button color="primary" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const QuestionTagExport = React.memo(QuestionTagRender, function areEqual(prevProps, currentProps) {
    return prevProps.open === currentProps.open;
});

export default QuestionTagExport;

QuestionTagRender.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    questions: PropTypes.array,
    assessments: PropTypes.array,
};
