import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const CompanyController = {
    getCompanyUsers,
    getCompanyFiltererdUsers,
    getCurrentMagicLinks,
    getUserProfilePic,
    getMagicLinkProfilePic,
    editUser,
    getUsersRestPeriodDate,
    getUsersRestPeriodDocument,
    getMassRestPeriodDocument,
    sendCompanyPushNotif,
    getCompanies,
    addCompany,
    updateCompany,
    getCompanyLogo,
    switchCompany,
};

async function getCompanyUsers(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANY_USERS(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCompanyFiltererdUsers(companyId, userQuery, shipQuery) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_FILTERED_COMPANY_USERS(companyId) + `?userQuery=${userQuery}&shipQuery=${shipQuery}`, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCurrentMagicLinks(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_CURRENT_MAGIC_LINKS(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getUserProfilePic(companyId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USER_PROFILE_PIC(companyId, userId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getMagicLinkProfilePic(companyId, userName) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_MAGIC_LINK_PROFILE_PIC(companyId, userName), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editUser(
    companyId,
    id,
    email,
    firstName,
    lastName,
    phoneNumber,
    profilePic,
    deleteImage,
    companyRole,
    userGroupsId,
    restPeriodsEnabled,
    canGenerateNFCTags,
    rank,
    secondaryEmail = null
) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('id', id);
    formData.append('email', email);
    formData.append('secondaryEmail', secondaryEmail);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    if (phoneNumber !== null) {
        formData.append('phoneNumber', phoneNumber);
    }
    formData.append('profilePic', profilePic);
    formData.append('deleteImage', deleteImage);
    formData.append('companyRole', companyRole);
    formData.append('userGroupsId', userGroupsId);
    formData.append('restPeriodsEnabled', restPeriodsEnabled);
    formData.append('canGenerateNFCTags', canGenerateNFCTags);
    formData.append('rank', rank);

    return Axios.post(APIRoute + APIPath.EDIT_USER(companyId), formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getUsersRestPeriodDate(companyId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USER_REST_PERIOD_DATES(companyId, userId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getUsersRestPeriodDocument(companyId, userId, startDate) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.GET_USER_REST_PERIOD_DOCUMENT(companyId), { userId, startDate }, userHeaders)
        .then(response => {
            return { ...generateResponse(false, response.data, response), response };
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getMassRestPeriodDocument(companyId, userIds, shipId, startDate) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.GET_MASS_REST_PERIOD_DOCUMENT(companyId), { userIds, shipId, startDate }, userHeaders)
        .then(response => {
            return { ...generateResponse(false, response.data, response), response };
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function sendCompanyPushNotif(companyId, messageTitle, messageContent, shipIds, userGroupIds) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.SEND_COMPANY_PUSH_NOTIF(companyId), { messageTitle, messageContent, shipIds, userGroupIds }, userHeaders)
        .then(response => {
            return { ...generateResponse(false, response.data, response), response };
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCompanies(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANIES(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addCompany(companyId, name, logo) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('name', name);
    if (logo != null) {
        formData.append('logo', logo);
    }
    return Axios.post(APIRoute + APIPath.ADD_COMPANY(companyId), formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function updateCompany(companyId, name, logo, scoreFrom) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('id', companyId);
    formData.append('name', name);
    if (logo != null) {
        formData.append('logo', logo);
    }
    if (scoreFrom != null) {
        formData.append('scoreFrom', scoreFrom);
    }
    return Axios.post(APIRoute + APIPath.UPDATE_COMPANY(companyId), formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCompanyLogo(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANY_LOGO(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function switchCompany(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.SWITCH_COMPANY(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default CompanyController;
