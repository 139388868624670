import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const ShipController = {
    getShips,
    getShipLogo,
    addShip,
    updateShip,
    deleteShip,
    getShipUsers,
    addUserToShip,
    editUserToShip,
    removeUserFromShip,
};

async function getShips(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_SHIPS(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getShipLogo(companyId, shipId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.SHIP_LOGO(companyId, shipId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addShip(companyId, name, imo, portOfRegistry, logo) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('name', name);
    formData.append('imo', imo);
    formData.append('portOfRegistry', portOfRegistry);
    formData.append('logo', logo);
    return Axios.post(APIRoute + APIPath.ADD_SHIP(companyId), formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function updateShip(companyId, shipId, name, imo, portOfRegistry, deleteImage, logo) {
    const userHeaders = await UserController.getUserHeaders();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('imo', imo);
    formData.append('portOfRegistry', portOfRegistry);
    formData.append('logo', logo);
    formData.append('deleteImage', deleteImage);
    formData.append('id', shipId);
    return Axios.post(APIRoute + APIPath.UPDATE_SHIP(companyId), formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function deleteShip(companyId, shipId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_SHIP(companyId, shipId), null, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getShipUsers(companyId, shipId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_SHIP_USERS(companyId, shipId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addUserToShip(companyId, shipId, shipRole, userId) {
    const userHeaders = await UserController.getUserHeaders();
    const shipUserModel = {
        shipId,
        userId,
        shipRole,
    };
    return Axios.post(APIRoute + APIPath.ADD_USER_TO_SHIP(companyId), shipUserModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editUserToShip(companyId, shipId, shipRole, userId) {
    const userHeaders = await UserController.getUserHeaders();
    const shipUserModel = {
        shipId,
        userId,
        shipRole,
    };
    return Axios.post(APIRoute + APIPath.EDIT_USER_TO_SHIP(companyId), shipUserModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function removeUserFromShip(companyId, shipId, userId) {
    const userHeaders = await UserController.getUserHeaders();
    const shipUserModel = {
        shipId,
        userId,
    };
    return Axios.post(APIRoute + APIPath.REMOVE_USER_FROM_SHIP(companyId), shipUserModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default ShipController;
