import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { LogIn } from '../../stores/Actions/Authentication';
import { Add } from '@material-ui/icons';
import { makeStyles, Grid, Button, TextField } from '@material-ui/core';
import { isNullOrUndefined, updateQueryString } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import { SearchCard } from '../../components/Common/SearchCard';
import { CompanyAddEdit } from './CompanyAddEdit';
import { clearUploadUrls } from '../../helpers/UploadReader';
import CompanyController from '../../controllers/CompanyController';
import { updateCompanyId } from '../../controllers/UserController';
import { clearStore } from '../../stores/Actions/Dashboard';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '16px 32px',
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
    topArea: {
        paddingBottom: 8,
        '& .MuiButtonBase-root': {
            height: '100%',
        },
    },
}));

function Company(props) {
    const { Auth, PushHistory, LogIn, clearStore } = props;
    const { name: nameQuery } = queryString.parse(window.location.search);
    const [companies, setCompanies] = React.useState([]);
    const [nameSearch, setNameSearch] = React.useState(nameQuery ?? '');
    // const [deleteRef, setDeleteRef] = React.useState(null);
    const [addEditRef, setAddEditRef] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const classes = useStyles();

    const loadCompanies = React.useCallback(async () => {
        setLoading(true);
        const response = await CompanyController.getCompanies(Auth.companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            for (let i = 0; i < response.data.length; i++) {
                const imgResponse = await CompanyController.getCompanyLogo(response.data[i].id);
                if (imgResponse.hasError) {
                    continue;
                }
                response.data[i].logoPath = imgResponse.data;
            }
            setCompanies(response.data);
        }
        setLoading(false);
    }, [Auth]);

    // init & auth check
    React.useEffect(() => {
        // ensure user is signed in
        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }
        // fetch companies for inital render
        async function init() {
            await loadCompanies();
        }
        init();
    }, [Auth, loadCompanies]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    function generateQueryString(name) {
        let output = '';
        if (name.trim().length > 0) {
            output += `name=${name}`;
        }
        return output;
    }

    async function handleUpdateCompany(company) {
        const { id } = company;
        const imgResponse = await CompanyController.getCompanyLogo(id);
        if (!imgResponse.hasError) {
            company.logoPath = imgResponse.data;
        }

        const exists = companies.some(e => e.id === id);
        if (exists) {
            setCompanies(companies.map(e => (e.id === id ? company : e)));
        } else {
            setCompanies([...companies, company]);
        }
    }

    async function handleCompanySwitch(company) {
        setLoading(true);
        const { id } = company;
        const switchResponse = await CompanyController.switchCompany(id);
        if (switchResponse.hasError) {
            setWarning(switchResponse.data);
            setLoading(false);
            return;
        }

        await updateCompanyId(id);

        clearStore();

        LogIn({ ...Auth, companyId: id });
    }

    async function handleViewCompanyResults(company) {
        await handleCompanySwitch(company);
        setRedirectUrl(`/`);
    }

    // async function handleDeleteCompany() {
    //     setLoading(true);
    //     setWarning(null);
    //     const { id } = deleteRef;
    //     const response = await CompanyController.deleteCompany(Auth.companyId, id);
    //     if (response.hasError) {
    //         setWarning(response.data);
    //     } else {
    //         setCompanies(companies.filter(e => e.id !== id));
    //     }
    //     setDeleteRef(null);
    //     setLoading(false);
    // }

    function handleInput(event) {
        const { value, name } = event.target;
        switch (name) {
            case 'name':
                setNameSearch(value);
                updateQueryString(generateQueryString(value));
                break;
            default:
                break;
        }
    }

    function buildTopArea() {
        return (
            <Grid container spacing={2} className={classes.topArea}>
                <Grid item xs="auto">
                    <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setAddEditRef({})}>
                        Add Company
                    </Button>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <TextField label="Search by Name" variant="filled" value={nameSearch} onChange={handleInput} name="name" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Alert header="Something went wrong!" text={warning} />
                </Grid>
            </Grid>
        );
    }

    function buildCompany(company) {
        const { id, name, logoPath } = company;
        return (
            <Grid key={id} item lg={2} md={3} sm={4} xs={12}>
                <SearchCard title={name} fitImage={logoPath?.length > 0} image={logoPath}>
                    <Button onClick={() => setAddEditRef(company)}>Edit</Button>
                    <Button onClick={() => handleViewCompanyResults(company)}>Results</Button>
                    <Button onClick={() => handleCompanySwitch(company)}>Select Company</Button>
                </SearchCard>
            </Grid>
        );
    }

    function buildCompanies() {
        if (isNullOrUndefined(companies)) {
            return null;
        }

        return (
            <Grid container spacing={2}>
                {companies.filter(e => e.name.toLowerCase().includes(nameSearch.toLowerCase())).map(buildCompany)}
            </Grid>
        );
    }

    // function buildConfirmDelete() {
    //     return <Dialog open={!isNullOrUndefined(deleteRef)} onClose={() => setDeleteRef(null)}>
    //         <DialogTitle>Delete Company</DialogTitle>
    //         <DialogContent>Are you sure you want to perform this action?</DialogContent>
    //         <DialogActions>
    //             <Button color="primary" onClick={() => handleDeleteCompany()}>
    //                 Yes
    //             </Button>
    //             <Button color="primary" onClick={() => setDeleteRef(null)}>
    //                 No
    //             </Button>
    //         </DialogActions>
    //     </Dialog>;
    // }

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay loading={loading} />
            {buildTopArea()}
            {buildCompanies()}
            {/* {buildConfirmDelete()} */}
            <CompanyAddEdit
                open={!isNullOrUndefined(addEditRef)}
                onClose={() => {
                    setAddEditRef(null);
                    clearUploadUrls();
                }}
                onError={setWarning}
                onDone={handleUpdateCompany}
                company={addEditRef}
                companyId={Auth.companyId}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    LogIn: data => dispatch(LogIn(data)),
    clearStore: () => dispatch(clearStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);

Company.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    LogIn: PropTypes.func,
    clearStore: PropTypes.func,
};
