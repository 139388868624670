import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Authentication from './Reducers/Authentication';
import Notifications from './Reducers/Notifications';
import Dashboard from './Reducers/Dashboard';
import Fleet from './Reducers/Fleet';
import Users from './Reducers/Users';

export default history =>
    combineReducers({
        router: connectRouter(history),
        Authentication,
        Notifications,
        Dashboard,
        Fleet,
        Users,
    });
