import { isNullOrUndefined } from './Utils';

var readers = [];

export function clearUploadUrls() {
    readers = [];
}

export function getUploadUrls() {
    return readers.map(e => {
        return e.result;
    });
}

export function removeIndex(i) {
    readers.splice(i, 1);
}

export function readUploadUrls(files, callback) {
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (isNullOrUndefined(file)) {
            continue;
        }
        var reader = new FileReader();
        readers.push(reader);
        reader.onload = () => {
            callback();
        };
        reader.readAsDataURL(file);
    }
}

export function tsvJson(tsvData) {
    const lines = tsvData.split('\n');
    const result = [];
    const headers = lines[0].split('\t');

    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split('\t');

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentLine[j];
        }

        result.push(obj);
    }

    return result;
}
