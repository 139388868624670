export const Colours = {
    primary: 'rgb(3, 84, 120)',
    primaryDark: 'rgb(17,43,66)',
    secondary: 'rgb(70, 228, 209)',
    offWhite: '#FEFEFE',
    offerWhite: '#FBFBFB',
    white: '#FFF',
    black: '#000',
    red: '#E23737',
    darkRed: '#bc2e2e',
    green: '#74CC6A',
    darkGreen: '#599F52',
    bodyText: 'rgba(0, 0, 0, 0.6)',
    border: 'rgba(0, 0, 0, 0.42)',
    field: 'rgba(0, 0, 0, 0.09)',
    archived: '#f1f1f1',
};
