import React from 'react';
import PropTypes from 'prop-types';
import CompanyController from '../../controllers/CompanyController';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Add, CloudUpload, GetApp, MoreVert } from '@material-ui/icons';
import {
    makeStyles,
    Grid,
    Button,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    Switch,
    Chip,
    CircularProgress,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    IconButton,
    Popover,
} from '@material-ui/core';
import { CompanyRole, Role, ShipRole } from '../../helpers/Constants';
import { descendingComparator, isEmpty, isNullOrUndefined, updateQueryString } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { SearchCard } from '../../components/Common/SearchCard';
import AdminController from '../../controllers/AdminController';
import UserController from '../../controllers/UserController';
import UsersAddEdit from './UsersAddEdit';
import UsersAssessmentLevels from './UsersAssessmentLevels';
import UserRestPeriod from './UserRestPeriod';
import MassRestPeriod from './MassRestPeriod';
import UserGroupController from '../../controllers/UserGroupController';
import { Colours } from '../../helpers/Colours';
import SearchIcon from '@material-ui/icons/Search';
import { setUsers } from '../../stores/Actions/Users';
import { Skeleton } from '../../components/Common/Skeleton';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { UploadUsers } from './UploadUsers';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '16px 32px',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        '& .skeleton': {
            margin: 0,
        },

        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
    topArea: {
        paddingBottom: 8,
        '& .MuiButtonBase-root': {
            height: '100%',
        },
    },
    userInfoCards: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 12,
        '& .MuiChip-root': {
            marginRight: 4,
            marginBottom: 4,
        },
    },
    fieldWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',

        '& .MuiFilledInput-root': {
            borderTopRightRadius: 0,
        },
    },
    buttonWrapper: {
        transition: 'background-color 0.2s',
        height: '100%',
        width: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${Colours.field}`,
        borderBottom: `1px solid ${Colours.border}`,
        borderLeft: 'none',
        cursor: 'pointer',
        borderTopRightRadius: 4,

        '&:hover': {
            backgroundColor: '#eee',
        },
    },
    table: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 6,
        '& th': {
            fontWeight: 'bold',
        },
        '& tr': {
            '&:last-child': {
                '& td': {
                    borderBottom: 'none',
                },
            },
        },
    },
    popover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiButton-root': {
            textTransform: 'none',
            minWidth: 120,
        },
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
            fontWeight: 400,
        },
    },
}));

function Users(props) {
    const { Auth, PushHistory, UsersStore, setUsers } = props;
    const { users } = UsersStore;

    const { ship: shipQuery, name: nameQuery, userGroup: userGroupQuery } = queryString.parse(window.location.search);
    const [nameSearch, setNameSearch] = React.useState(nameQuery ?? '');
    const [prevNameSearch, setPrevNameSearch] = React.useState(nameQuery ?? '');
    const [shipSearch, setShipSearch] = React.useState(shipQuery ?? '');
    const [prevShipSearch, setPrevShipSearch] = React.useState(shipQuery ?? '');
    const [showDisabled, setShowDisabled] = React.useState(false);
    const [showListView, setShowListView] = React.useState(false);
    const [showUploadTsv, setShowUploadTsv] = React.useState(false);
    const [deleteUserRef, setDeleteUserRef] = React.useState(null);
    const [removeLinkRef, setRemoveLinkRef] = React.useState(null);
    const [resendLinkRef, setResendLinkRef] = React.useState(null);
    const [enableUserRef, setEnableUserRef] = React.useState(null);
    const [addEditUserRef, setAddEditUserRef] = React.useState(null);
    const [assessmentUserRef, setAssessmentUserRef] = React.useState(null);
    const [restPeriodUserRef, setRestPeriodUserRef] = React.useState(null);
    const [userGroups, setUserGroups] = React.useState([]);
    const [showMassRestPeriodExport, setShowMassRestPeriodExport] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverIndex, setPopoverIndex] = React.useState(null);

    // Sorting
    const [orderRow, setOrderRow] = React.useState('');
    const [orderDirection, setOrderDirection] = React.useState('desc');

    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const classes = useStyles();

    const userIsAdmin = Auth.role.includes(Role.Admin);
    const userIsCaptain = Auth.shipRoles.includes(ShipRole.Captain) && Auth.companyRole !== CompanyRole.Owner;

    const loadUserProfilePic = React.useCallback(
        async user => {
            const isInvite = isNullOrUndefined(user.id);
            const imgResponse = isInvite ? await CompanyController.getMagicLinkProfilePic(Auth.companyId, user.userName) : await CompanyController.getUserProfilePic(Auth.companyId, user.id);
            if (imgResponse.hasError) {
                return null;
            } else {
                return imgResponse.data;
            }
        },
        [Auth]
    );

    const loadUsers = React.useCallback(async () => {
        setLoading(true);
        const authedUserResponse = await CompanyController.getCompanyFiltererdUsers(Auth.companyId, nameQuery ?? '', shipQuery ?? '');
        if (authedUserResponse.hasError) {
            setWarning(authedUserResponse.data);
        } else {
            const invitedUserResponse = await CompanyController.getCurrentMagicLinks(Auth.companyId);
            if (invitedUserResponse.hasError) {
                setWarning(invitedUserResponse.data);
            } else {
                const users = [
                    ...authedUserResponse.data.filter(e => (userGroupQuery ?? '').length === 0 || e.userGroupId === userGroupQuery),
                    ...invitedUserResponse.data
                        .filter(e => (userGroupQuery ?? '').length === 0 || e.userGroupId === userGroupQuery)
                        .filter(e => (nameQuery ?? '').length === 0 || (e.userName + e.firstName + e.lastName).toLowerCase().includes(nameQuery.toLowerCase()))
                        .filter(e => (shipQuery ?? '').length === 0 || e.ships.filter(s => s.name.toLowerCase().includes(shipQuery.toLowerCase())).length > 0),
                ];
                users.sort((a, b) => {
                    if (a.lastName < b.lastName) {
                        return -1;
                    }
                    if (a.lastName > b.lastName) {
                        return 1;
                    }
                    return 0;
                });
                setUsers(users);
            }
        }
        setLoading(false);
    }, [Auth, loadUserProfilePic]);

    // init & auth check
    React.useEffect(() => {
        // ensure user is signed in
        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }
        // fetch users for initial render
        async function init() {
            const showList = localStorage.getItem('showListView');
            setShowListView(showList === 'false' || showList === '' || isNullOrUndefined(showList) ? false : true);
            const response = await UserGroupController.getUserGroups(Auth.companyId);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                setUserGroups(response.data);
            }
            await loadUsers();
        }
        init();
    }, [Auth, loadUsers]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverIndex(null);
    };

    const handelSortChange = React.useCallback(
        newSort => {
            if (newSort === orderRow) {
                if (orderDirection === 'desc') {
                    setOrderDirection('asc');
                } else {
                    setOrderDirection('desc');
                }
                return;
            }
            setOrderRow(newSort);
            setOrderDirection('desc');
        },
        [orderRow, orderDirection, setOrderDirection, setOrderRow]
    );

    function generateQueryString() {
        let output = '';
        if (nameSearch.trim().length > 0) {
            output += `name=${nameSearch}`;
        }
        if (shipSearch.trim().length > 0) {
            if (output.length > 0) {
                output += '&';
            }
            output += `ship=${shipSearch}`;
        }
        if ((userGroupQuery ?? '').trim().length > 0) {
            if (output.length > 0) {
                output += '&';
            }
            output += `userGroup=${userGroupQuery}`;
        }
        return output;
    }

    async function handleChangeOnLeaveStatus(id, onLeave) {
        setSaving(true);
        const response = await UserController.updateUserLeave(id, onLeave);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setUsers(users.map(user => (user.id === id ? { ...user, onLeave } : user)));
        }
        setSaving(false);
    }

    async function handleFilterUsers(event) {
        if (!isNullOrUndefined(event)) {
            event.preventDefault();
        }

        if (prevNameSearch === nameSearch && prevShipSearch === shipSearch) {
            return;
        }

        setLoading(true);
        setPrevNameSearch(nameSearch);
        setPrevShipSearch(shipSearch);

        updateQueryString(generateQueryString());

        const authedUserResponse = await CompanyController.getCompanyFiltererdUsers(Auth.companyId, nameSearch, shipSearch);
        if (authedUserResponse.hasError) {
            setWarning(authedUserResponse.data);
        } else {
            const invitedUserResponse = await CompanyController.getCurrentMagicLinks(Auth.companyId);
            if (invitedUserResponse.hasError) {
                setWarning(invitedUserResponse.data);
            } else {
                const newUsers = [
                    ...authedUserResponse.data,
                    ...invitedUserResponse.data
                        .filter(e => nameSearch.length === 0 || (e.userName + e.firstName + e.lastName).toLowerCase().includes(nameSearch.toLowerCase()))
                        .filter(e => shipSearch.length === 0 || e.ships.filter(s => s.name.toLowerCase().includes(shipSearch.toLowerCase())).length > 0),
                ];
                newUsers.sort((a, b) => {
                    if (a.lastName < b.lastName) {
                        return -1;
                    }
                    if (a.lastName > b.lastName) {
                        return 1;
                    }
                    return 0;
                });
                setUsers(newUsers);
            }
        }

        setLoading(false);
    }

    function handleToggleUserEnabled() {
        if (isNullOrUndefined(enableUserRef)) {
            return;
        }
        const { userIsBanned, id } = enableUserRef;
        (userIsBanned ? handleUnBanUser : handleBanUser)(id);
        setEnableUserRef(null);
    }

    async function handleResendMagicLink() {
        setLoading(true);
        const { userName } = resendLinkRef;
        setResendLinkRef(null);
        const response = await UserController.resendMagicLink(userName);
        if (response.hasError) {
            setWarning('Failed to resend link, please contact the developer');
        }
        setLoading(false);
    }

    async function handleRemoveMagicLink() {
        setLoading(true);
        const { userName } = removeLinkRef;
        setRemoveLinkRef(null);
        const response = await UserController.removeMagicLink(userName);
        if (response.hasError) {
            setWarning('Failed to delete link, please contact the developer');
        } else {
            setUsers(users.filter(e => e.userName !== userName));
        }
        setLoading(false);
    }

    async function handleDeleteUser() {
        if (!deleteUserRef) return;
        setLoading(true);
        setDeleteUserRef(null);
        const { id } = deleteUserRef;
        const response = await UserController.deleteUser(id);
        if (response.hasError) {
            setWarning('Failed to delete user, please contact the developer');
        } else {
            setUsers(users.filter(e => e.id !== id));
        }
        setLoading(false);
    }

    async function handleBanUser(userId) {
        setLoading(true);
        const response = await AdminController.banUser(userId);
        if (response.hasError) {
            setWarning('Failed to disable user, please contact the developer');
        } else {
            setUsers(users.map(e => (e.id === userId ? { ...e, userIsBanned: true } : e)));
        }
        setLoading(false);
    }

    async function handleUnBanUser(userId) {
        setLoading(true);
        const response = await AdminController.unBanUser(userId);
        if (response.hasError) {
            setWarning('Failed to enable user, please contact the developer');
        } else {
            setUsers(users.map(e => (e.id === userId ? { ...e, userIsBanned: false } : e)));
        }
        setLoading(false);
    }

    function handleInput(event) {
        const { value, name } = event.target;
        switch (name) {
            case 'name':
                setNameSearch(value);
                break;
            case 'ship':
                setShipSearch(value);
                break;
            default:
                break;
        }
    }

    function buildTopArea() {
        return (
            <Grid container spacing={2} className={classes.topArea}>
                <Grid item xs="auto">
                    <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setAddEditUserRef({})}>
                        Add User
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button variant="contained" color="primary" disabled={!userIsAdmin} startIcon={<CloudUpload />} onClick={() => setShowUploadTsv(true)}>
                        Upload Users
                    </Button>
                </Grid>
                <Grid item md="auto" sm={5}>
                    <Button variant="contained" color="primary" disabled={userIsCaptain} startIcon={<Add />} component={Link} to="/UserGroups">
                        Add User Group
                    </Button>
                </Grid>
                <Grid item sm="auto">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setShowMassRestPeriodExport(true);
                        }}
                        startIcon={<GetApp />}
                    >
                        Mass Rest Period Export
                    </Button>
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <div className={classes.fieldWrapper}>
                        <TextField
                            label="Search by Ship"
                            variant="filled"
                            value={shipSearch}
                            onChange={handleInput}
                            onKeyDown={e => (e.key === 'Enter' ? handleFilterUsers(e) : null)}
                            onBlur={handleFilterUsers}
                            name="ship"
                            fullWidth
                        />
                        <div className={classes.buttonWrapper} onClick={() => handleFilterUsers()}>
                            <SearchIcon />
                        </div>
                    </div>
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    <div className={classes.fieldWrapper}>
                        <TextField
                            label="Search by Name"
                            variant="filled"
                            value={nameSearch}
                            onChange={handleInput}
                            onKeyDown={e => (e.key === 'Enter' ? handleFilterUsers(e) : null)}
                            onBlur={handleFilterUsers}
                            name="name"
                            fullWidth
                        />
                        <div className={classes.buttonWrapper} onClick={() => handleFilterUsers()}>
                            <SearchIcon />
                        </div>
                    </div>
                </Grid>
                {loading ? (
                    <Grid item lg md sm xs>
                        <CircularProgress size={24} style={{ marginTop: 12, marginLeft: 16 }} />
                    </Grid>
                ) : null}
                <Grid item lg={12}>
                    <FormControlLabel control={<Switch checked={showDisabled} onChange={e => setShowDisabled(e.target.checked)} color="primary" />} label="Show Disabled Users" />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showListView}
                                onChange={e => {
                                    setShowListView(e.target.checked);
                                    localStorage.setItem('showListView', e.target.checked);
                                }}
                                color="primary"
                            />
                        }
                        label="Show List View"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Alert header="Something went wrong!" text={warning} />
                </Grid>
            </Grid>
        );
    }

    function buildMenuItems(user) {
        const { id, userName, onLeave } = user;
        const isMe = Auth.userName === userName;
        const isInvite = isNullOrUndefined(id);
        return (
            <>
                {!isMe || userIsAdmin ? (
                    <>
                        {!isInvite ? (
                            <>
                                <Button onClick={() => setDeleteUserRef(user)}>Delete</Button>
                                <Button onClick={() => setAssessmentUserRef(user)}>Edit Assessment Levels</Button>
                                {!userIsCaptain && !isMe ? <Button onClick={() => setEnableUserRef(user)}>Enable / Disable</Button> : null}
                                <Button onClick={() => setRestPeriodUserRef(user)}>Rest Periods</Button>
                                <Button onClick={() => setRedirectUrl(`/?userId=${user.id}&userName=${user.firstName + ' ' + user.lastName}`)}>Results</Button>
                                {!showListView ? <Button onClick={() => handleChangeOnLeaveStatus(id, !onLeave)}>Toggle Leave Status</Button> : null}
                                <Button onClick={() => setAddEditUserRef(user)}>View / Edit</Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={() => setRemoveLinkRef(user)}>Delete</Button>
                                <Button onClick={() => setResendLinkRef(user)}>Resend Registration Link</Button>
                                <Button onClick={() => setAddEditUserRef(user)}>View / Edit</Button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Button onClick={() => setRestPeriodUserRef(user)}>Rest Periods</Button>
                        <Button onClick={() => setRedirectUrl(`/?userId=${user.id}&userName=${user.firstName + ' ' + user.lastName}`)}>Results</Button>
                        {!showListView ? <Button onClick={() => handleChangeOnLeaveStatus(id, !onLeave)}>Toggle Leave Status</Button> : null}
                    </>
                )}
            </>
        );
    }

    function buildUser(user) {
        const { id, firstName, lastName, userName, userIsBanned, ships, userGroupId, onLeave } = user;
        const isMe = Auth.userName === userName;
        const isInvite = isNullOrUndefined(id);

        return (
            <Grid key={id} item lg={2} md={3} sm={4} xs={12}>
                <SearchCard
                    title={`${firstName} ${lastName}`}
                    fitImage={true}
                    imageGetter={async () => loadUserProfilePic(user)}
                    description={
                        <>
                            <Typography variant="body2">{userName + (isMe ? ' (me)' : '')}</Typography>
                            {isInvite ? (
                                <Typography variant="body2">
                                    <em>Account creation pending</em>
                                </Typography>
                            ) : (
                                <Typography style={{ width: '100%', color: onLeave ? 'red' : 'green' }} variant="body2">
                                    {onLeave ? 'On Leave' : 'On Active Duty'}
                                </Typography>
                            )}
                            {userIsBanned ? <Typography variant="body2">User Disabled</Typography> : null}
                            <div className={classes.userInfoCards}>
                                <Chip key="user-group" variant="outlined" label={userGroups.find(e => e.id === userGroupId)?.name ?? 'Not Set'} />
                                {ships.map((e, i) => (
                                    <Chip key={`${i}-${userName}`} variant="outlined" label={e.name} />
                                ))}
                            </div>
                        </>
                    }
                >
                    {buildMenuItems(user)}
                </SearchCard>
            </Grid>
        );
    }

    function buildUsers() {
        if (isEmpty(users) || isNullOrUndefined(users)) {
            return <Skeleton />;
        }

        return (
            <Grid container spacing={2}>
                {users.filter(e => (showDisabled ? true : !e.userIsBanned)).map((u, i) => buildUser(u, i))}
            </Grid>
        );
    }

    function buildTableHead() {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'firstName'} direction={orderRow === 'firstName' ? orderDirection : 'desc'} onClick={() => handelSortChange('firstName')}>
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'userName'} direction={orderRow === 'userName' ? orderDirection : 'desc'} onClick={() => handelSortChange('userName')}>
                            Username
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'userGroupId'} direction={orderRow === 'userGroupId' ? orderDirection : 'desc'} onClick={() => handelSortChange('userGroupId')}>
                            User Group
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Ships</TableCell>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'id'} direction={orderRow === 'id' ? orderDirection : 'desc'} onClick={() => handelSortChange('id')}>
                            Invite Pending
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'userIsBanned'} direction={orderRow === 'userIsBanned' ? orderDirection : 'desc'} onClick={() => handelSortChange('userIsBanned')}>
                            User Disabled
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'onLeave'} direction={orderRow === 'onLeave' ? orderDirection : 'desc'} onClick={() => handelSortChange('onLeave')}>
                            On Leave Status
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Toggle Leave Status</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
        );
    }

    function buildUserRow(user, index) {
        const { id, firstName, lastName, userName, userIsBanned, ships, userGroupId, onLeave } = user;
        const isInvite = isNullOrUndefined(id);

        return (
            <>
                <TableRow key={userName}>
                    <TableCell>{`${firstName} ${lastName}`}</TableCell>
                    <TableCell>{userName}</TableCell>
                    <TableCell>
                        <Chip variant="outlined" label={userGroups.find(e => e.id === userGroupId)?.name ?? 'Not Set'} />
                    </TableCell>
                    <TableCell>
                        {ships.map((e, i) => (
                            <Chip style={{ marginRight: 4 }} key={i} variant="outlined" label={e.name} />
                        ))}
                    </TableCell>
                    <TableCell>{isInvite ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{isInvite ? 'N/A' : userIsBanned ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{isInvite ? 'N/A' : onLeave ? 'On Leave' : 'On Active Duty'}</TableCell>
                    <TableCell align="center">
                        {!isInvite ? <FormControlLabel control={<Switch checked={onLeave} onChange={e => handleChangeOnLeaveStatus(id, e.target.checked)} color="primary" />} label="" /> : null}
                    </TableCell>
                    <TableCell>
                        <IconButton
                            aria-describedby={id}
                            onClick={e => {
                                setAnchorEl(e.currentTarget);
                                setPopoverIndex(index);
                            }}
                        >
                            <MoreVert />
                        </IconButton>
                    </TableCell>
                </TableRow>
                <Popover
                    id={id}
                    open={popoverIndex === index}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    onClick={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.popover}
                >
                    {buildMenuItems(user)}
                </Popover>
            </>
        );
    }

    function getDefaultSortValue() {
        if (orderRow === 'userIsBanned' || orderRow === 'onLeave') {
            return -1;
        }

        return '';
    }

    function buildListView() {
        return (
            <TableContainer className={classes.table}>
                <Table stickyHeader size="small">
                    {buildTableHead()}
                    <TableBody>
                        {users
                            .filter(e => (showDisabled ? true : !e.userIsBanned))
                            .sort((a, b) => {
                                return orderDirection === 'desc' ? descendingComparator(a, b, orderRow, getDefaultSortValue()) : -descendingComparator(a, b, orderRow, getDefaultSortValue());
                            })
                            .map((user, i) => buildUserRow(user, i))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    function buildConfirmEnableDisable() {
        return (
            <Dialog open={!isNullOrUndefined(enableUserRef)} onClose={() => setEnableUserRef(null)}>
                <DialogTitle>Disable/Enable User</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleToggleUserEnabled()}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => setEnableUserRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildConfirmDeleteUser() {
        return (
            <Dialog open={!isNullOrUndefined(deleteUserRef)} onClose={() => setDeleteUserRef(null)}>
                <DialogTitle>Permanently Delete User</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleDeleteUser()}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => setDeleteUserRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildConfirmResendLink() {
        return (
            <Dialog open={!isNullOrUndefined(resendLinkRef)} onClose={() => setResendLinkRef(null)}>
                <DialogTitle>Resend Registration Link</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleResendMagicLink()}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => setResendLinkRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildConfirmRemoveLink() {
        return (
            <Dialog open={!isNullOrUndefined(removeLinkRef)} onClose={() => setRemoveLinkRef(null)}>
                <DialogTitle>Delete Unregistered User</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleRemoveMagicLink()}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => setRemoveLinkRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay loading={saving} />
            {buildTopArea()}
            {showListView ? buildListView() : buildUsers()}
            {buildConfirmEnableDisable()}
            {buildConfirmResendLink()}
            {buildConfirmRemoveLink()}
            {buildConfirmDeleteUser()}
            <UsersAssessmentLevels
                open={!isNullOrUndefined(assessmentUserRef)}
                onClose={() => setAssessmentUserRef(null)}
                isMe={Auth?.userName === assessmentUserRef?.userName}
                user={assessmentUserRef}
                companyId={Auth.companyId}
                userCompanyRole={Auth.companyRole}
            />
            <UsersAddEdit
                open={!isNullOrUndefined(addEditUserRef)}
                onDone={() => loadUsers()}
                onClose={() => setAddEditUserRef(null)}
                isMe={Auth?.userName === addEditUserRef?.userName}
                isUserAdmin={userIsAdmin}
                user={addEditUserRef}
                companyId={Auth.companyId}
                userCompanyRole={Auth.companyRole}
            />
            <UserRestPeriod open={!isNullOrUndefined(restPeriodUserRef)} onClose={() => setRestPeriodUserRef(null)} user={restPeriodUserRef} companyId={Auth.companyId} />
            <MassRestPeriod open={showMassRestPeriodExport} onClose={() => setShowMassRestPeriodExport(null)} companyId={Auth.companyId} />
            <UploadUsers open={showUploadTsv} onClose={() => setShowUploadTsv(false)} onSave={loadUsers} userCompanyRole={Auth.companyRole} companyId={Auth.companyId} isUserAdmin={userIsAdmin} />
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
    UsersStore: state.Users,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    setUsers: items => dispatch(setUsers(items)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);

Users.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    UsersStore: PropTypes.object,
    setUsers: PropTypes.func,
};
