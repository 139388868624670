import React from 'react';
import PropTypes from 'prop-types';
import ShipController from '../../controllers/ShipController';
import UserGroupController from '../../controllers/UserGroupController';
import DocumentController from '../../controllers/DocumentController';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FolderIcon from '@material-ui/icons/Folder';
import ImageIcon from '@material-ui/icons/Image';
import {
    makeStyles,
    Dialog,
    Typography,
    Button,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    FormControl,
    InputLabel,
    FilledInput,
    CircularProgress,
    Chip,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Colours } from '../../helpers/Colours';
import { isNullOrEmpty, isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { ItemType } from '../../helpers/Constants';
import { Delete, CloudUpload, Cancel, Check } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    docWrapper: {
        '& .MuiDialog-paper': {
            flexDirection: 'row',
            height: '800px',
            maxHeight: '100%',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                height: '100%',
            },
        },
    },
    leftArea: {
        flexGrow: 1,
        backgroundColor: Colours.offerWhite,
        overflowY: 'auto',
    },
    rightArea: {
        width: 400,
        padding: 16,
        backgroundColor: Colours.white,
        WebkitBoxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
        MozBoxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
        boxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    topPaneButton: {
        marginTop: 8,
    },
    item: {
        paddingLeft: 30,
        paddingRight: 30,
        '& .MuiListItemIcon-root': {
            minWidth: 40,
        },
        '& svg': {
            fill: Colours.black,
            transform: 'scale(1.5)',
        },
        '& .MuiTypography-body1': {
            color: Colours.primary,
        },
        '&.Mui-selected': {
            backgroundColor: Colours.primary,
            '& .MuiTypography-body1': {
                color: Colours.white,
            },
            '& svg': {
                fill: Colours.white,
            },
            '&:hover': {
                backgroundColor: Colours.primaryDark,
            },
        },
    },
    button: {
        justifyContent: 'space-between',
        maxWidth: 200,
        height: 44,
        '&.red': {
            backgroundColor: Colours.red,
            '&:hover': {
                backgroundColor: Colours.darkRed,
            },
            '&.Mui-disabled': {
                backgroundColor: Colours.bodyText,
            },
        },
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    selectArea: {
        '& .MuiInputBase-root': {
            paddingTop: 23,
            paddingBottom: 4,
        },
    },
    closeBtn: {
        position: 'absolute',
        top: 2,
        right: 2,
    },
}));

export function Documents(props) {
    const { open, pickOnly, onClose, onSelect, companyId } = props;
    const [ships, setShips] = React.useState([]);
    const [userGroups, setUserGroups] = React.useState([]);
    const [folders, setFolders] = React.useState([]);
    const [documentUrl, setDocumentUrl] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [showNewFile, setShowNewFile] = React.useState(false);
    const [showNewFolder, setShowNewFolder] = React.useState(false);
    const [showDeleteItem, setShowDeleteItem] = React.useState(false);
    const [itemName, setItemName] = React.useState('');
    const [itemDesc, setItemDesc] = React.useState('');
    const [itemFile, setItemFile] = React.useState('');
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    const loadShips = React.useCallback(async () => {
        const response = await ShipController.getShips(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setShips(response.data);
        }
    }, [companyId]);

    const loadUserGroups = React.useCallback(async () => {
        const response = await UserGroupController.getUserGroups(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setUserGroups(response.data);
        }
    }, [companyId]);

    const loadInnerFolderRecursively = React.useCallback(
        async folder => {
            const { id, documentItems } = folder;
            const response = await DocumentController.getCompanyCategoryChildren(companyId, id);
            if (response.hasError) {
                setWarning(response.data);
                return [];
            } else {
                const folderCopy = { ...folder };
                folderCopy.childFolders = response.data;
                for (let i = 0; i < folderCopy.childFolders.length; i++) {
                    const innerFolder = { ...folderCopy.childFolders[i] };
                    folderCopy.childFolders[i] = await loadInnerFolderRecursively(innerFolder);
                }
                folderCopy.documentItems = documentItems.map(e => ({ ...e, parentId: id }));
                return folderCopy;
            }
        },
        [companyId]
    );

    const loadFolders = React.useCallback(async () => {
        const response = await DocumentController.getCompanyRootCategories(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            const folderMap = [];
            for (let i = 0; i < response.data.length; i++) {
                folderMap.push(await loadInnerFolderRecursively(response.data[i]));
            }
            setFolders(folderMap);
        }
    }, [companyId, loadInnerFolderRecursively]);

    const loadDocumentUrl = React.useCallback(
        async document => {
            const { id, parentId } = document;
            const response = await DocumentController.getCompanyCategoryItem(companyId, parentId, id);
            if (response.hasError) {
                setWarning(response.data);
            } else {
                setDocumentUrl(response.data);
            }
        },
        [companyId]
    );

    React.useEffect(() => {
        async function init() {
            if (pickOnly !== true) {
                await loadShips();
                await loadUserGroups();
            }
            await loadFolders();
        }
        if (open) {
            setLoading(true);
            init().then(() => setLoading(false));
        }
    }, [open, pickOnly, loadFolders, loadShips, loadUserGroups]);

    React.useEffect(() => {
        setDocumentUrl(null);
        if (!isNullOrUndefined(selectedItem)) {
            loadDocumentUrl(selectedItem);
        }
    }, [selectedItem, loadDocumentUrl]);

    function getItemType(item) {
        return 'documentItems' in item ? ItemType.Folder : ItemType.File;
    }

    function handleInput(event) {
        const { name, value, files } = event.target;
        switch (name) {
            case 'itemName':
                setItemName(value);
                break;
            case 'itemDesc':
                setItemDesc(value);
                break;
            case 'itemFile':
                setItemFile(files[0] ?? null);
                break;
            default:
                break;
        }
    }

    async function handleSelect(values = [], name) {
        let newSelectedItem = { ...selectedItem };
        for (let i = 0; i < values.length; i++) {
            const item = values[i];
            if ((selectedItem[name] ?? []).filter(e => e.id === item.id).length === 0) {
                // needs adding
                const response = await (name === 'ships' ? DocumentController.addShip : DocumentController.addUserGroup)(companyId, newSelectedItem.id, item.id);
                if (response.hasError) {
                    setWarning(response.data);
                } else {
                    const newItem = { ...newSelectedItem, [name]: [...(newSelectedItem[name] ?? []), item] };
                    setFolders(folders.map(e => (e.id === selectedItem.id ? newItem : e)));
                    newSelectedItem = newItem;
                }
            }
        }
        for (let i = 0; i < newSelectedItem[name].length; i++) {
            const item = newSelectedItem[name][i];
            if (values.filter(e => e.id === item.id).length === 0) {
                // needs removing
                const response = await (name === 'ships' ? DocumentController.removeShip : DocumentController.removeUserGroup)(companyId, newSelectedItem.id, item.id);
                if (response.hasError) {
                    setWarning(response.data);
                } else {
                    const newItem = { ...newSelectedItem, [name]: newSelectedItem[name].filter(o => o.id !== item.id) };
                    setFolders(folders.map(e => (e.id === selectedItem.id ? newItem : e)));
                    newSelectedItem = newItem;
                }
            }
        }
        setSelectedItem(newSelectedItem);
    }

    function handleCloseAndClearFormDialogs() {
        setShowNewFile(false);
        setShowNewFolder(false);
        setItemFile(null);
        setItemName('');
        setItemDesc('');
    }

    async function handleAddNewFolder(event) {
        if (!isNullOrUndefined(event.preventDefault)) {
            event.preventDefault();
        }
        setSaving(true);

        const response = await DocumentController.createCategory(companyId, itemName, itemDesc, selectedItem?.id);
        if (response.hasError) {
            setWarning(response.data);
        } else if (isNullOrUndefined(selectedItem)) {
            setFolders([...folders, { ...response.data, childFolders: [], documentItems: [] }]);
        } else {
            setFolders(handleAddNestedFolder(selectedItem?.id, response.data));
        }

        setSaving(false);
        handleCloseAndClearFormDialogs();
    }

    function handleAddNestedFolder(parentFolderId, folderToAdd, srcArray = folders) {
        for (let i = 0; i < srcArray.length; i++) {
            const folder = srcArray[i];
            if (folder.id === parentFolderId) {
                srcArray[i].childFolders = [...folder.childFolders, { ...folderToAdd, childFolders: [], documentItems: [] }];
                return srcArray;
            } else {
                handleAddNestedFolder(parentFolderId, folderToAdd, srcArray[i].childFolders);
            }
        }
        return srcArray;
    }

    async function handleAddNewFile(event) {
        if (!isNullOrUndefined(event.preventDefault)) {
            event.preventDefault();
        }
        setSaving(true);

        const response = await DocumentController.addDocumentItem(companyId, selectedItem?.id, itemName, itemDesc, itemFile);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setFolders(handleAddNewDocumentItem(selectedItem?.id, response.data));
        }

        setSaving(false);
        handleCloseAndClearFormDialogs();
    }

    function handleAddNewDocumentItem(parentFolderId, documentToAdd, srcArray = folders) {
        for (let i = 0; i < srcArray.length; i++) {
            const folder = srcArray[i];
            if (folder.id === parentFolderId) {
                srcArray[i].documentItems = [...(folder.documentItems ?? []), documentToAdd];
                return srcArray;
            } else {
                handleAddNewDocumentItem(parentFolderId, documentToAdd, srcArray[i].childFolders);
            }
        }
        return srcArray;
    }

    async function handleDeleteSelectedItem() {
        setSaving(true);

        const isFolder = getItemType(selectedItem) === ItemType.Folder;
        const response = await (isFolder ? DocumentController.deleteCategory : DocumentController.deleteDocumentItem)(companyId, selectedItem.id);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setFolders(handleFindAndRemoveItem(selectedItem));
            setSelectedItem(null);
        }

        setShowDeleteItem(false);
        setSaving(false);
    }

    function handleFindAndRemoveItem(item, srcArray = folders) {
        const isFolder = getItemType(item) === ItemType.Folder;
        for (let i = 0; i < srcArray.length; i++) {
            if (!isFolder) {
                srcArray[i].documentItems = srcArray[i].documentItems.filter(e => e.id !== item.id);
            }
            srcArray[i].childFolders = handleFindAndRemoveItem(item, srcArray[i].childFolders ?? []);
        }
        return isFolder ? srcArray.filter(e => e.id !== item.id) : srcArray;
    }

    function buildLeftPane() {
        if (loading) {
            return (
                <div className={classes.loading}>
                    <CircularProgress color="primary" />
                </div>
            );
        }
        return <List>{folders.map(e => buildFolderItemContent(e))}</List>;
    }

    function buildFolderItemContent(item, depth = 0) {
        const items = [];
        items.push(buildItem(item, depth));
        if (getItemType(item) === ItemType.Folder) {
            for (let i = 0; i < (item.childFolders ?? []).length; i++) {
                const folder = item.childFolders[i];
                items.push(buildFolderItemContent(folder, depth + 1));
            }
            for (let i = 0; i < (item.documentItems ?? []).length; i++) {
                const doc = item.documentItems[i];
                items.push(buildItem(doc, depth + 1));
            }
        }
        return items;
    }

    function buildItem(item, indent = 0) {
        const { id, title } = item;
        const selected = id === selectedItem?.id;
        return (
            <ListItem
                button
                key={id}
                selected={selected}
                onClick={() => setSelectedItem(selected ? null : item)}
                className={classes.item}
                style={{ paddingLeft: indent === 0 ? 30 : 30 + indent * 40 }}
            >
                <ListItemIcon>{buildItemIcon(item)}</ListItemIcon>
                <ListItemText primary={title} />
            </ListItem>
        );
    }

    function buildItemIcon(item) {
        const isFolder = getItemType(item) === ItemType.Folder;
        if (isFolder) {
            return <FolderIcon />;
        }
        const typeName = String(item.fileType);
        switch (typeName) {
            case 'application/pdf':
                return <PictureAsPdfIcon />;
            default:
                if (typeName.includes('image')) {
                    return <ImageIcon />;
                }
                return <FileCopyIcon />;
        }
    }

    function buildRightPane() {
        if (isNullOrUndefined(selectedItem)) {
            return buildHowToContent();
        }
        return getItemType(selectedItem) === ItemType.Folder ? buildFolderOptions(selectedItem) : buildDocumentOptions(selectedItem);
    }

    function buildHowToContent() {
        return (
            <>
                <Typography variant="body1" paragraph>
                    <b>How To</b>
                </Typography>
                <Typography variant="body1" paragraph>
                    {pickOnly !== true
                        ? `To get started either create a new folder or select 
                        an existing folder. Files can only be added below the 
                        root level.`
                        : `Select a document from inside one of the folders on the 
                        left and press 'Select' to confirm your choice.`}
                </Typography>
                {pickOnly !== true && buildAddNewFolderButton()}
            </>
        );
    }

    function buildOptionsHeader(item) {
        const typeName = String(item.fileType);
        const { title, description } = item;
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        <b>Options for...</b>
                    </Typography>
                    <ListItem className={classes.item}>
                        <ListItemIcon>{buildItemIcon(item)}</ListItemIcon>
                        <ListItemText primary={title} />
                    </ListItem>
                </Grid>
                {!isNullOrEmpty(description) && !isNullOrWhitespace(description) ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                <b>File Description</b>
                            </Typography>
                        </Grid>
                        {typeName.includes('image') && !isNullOrUndefined(documentUrl) && (
                            <Grid item xs={12}>
                                <img src={documentUrl} alt={title} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="body1" paragraph>
                                {description}
                            </Typography>
                        </Grid>
                    </>
                ) : null}
            </>
        );
    }

    function buildFolderOptions(folder) {
        const isRoot = folders.filter(e => e.id === folder.id).length > 0;
        const { childFolders, documentItems } = folder;
        return (
            <Grid container spacing={2}>
                {buildOptionsHeader(folder)}
                {pickOnly !== true ? (
                    <>
                        {isRoot ? buildRootFolderPermissions() : buildNoPermissionsText()}
                        <Grid item xs={12}>
                            {buildAddNewFolderButton()}
                        </Grid>
                        <Grid item xs={12}>
                            {buildAddNewDocumentButton()}
                        </Grid>
                        <Grid item xs={12}>
                            {buildDeleteItemButton(childFolders.length > 0 || documentItems.length > 0)}
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
            </Grid>
        );
    }

    function buildNoPermissionsText() {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <b>Access Permissions</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        Cannot specify access permissions below top level. Please assign permissions from the root folder.
                    </Typography>
                </Grid>
            </>
        );
    }

    function buildRootFolderPermissions() {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <b>Access Permissions</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {buildAutocompleteFromList(ships, selectedItem?.ships ?? [], 'Ships', 'ships')}
                </Grid>
                <Grid item xs={12}>
                    {buildAutocompleteFromList(userGroups, selectedItem?.userGroups ?? [], 'User Groups', 'userGroups')}
                </Grid>
                <Grid item xs={12}></Grid>
            </>
        );
    }

    function buildDocumentOptions(document) {
        return (
            <Grid container spacing={2}>
                {buildOptionsHeader(document)}
                {pickOnly !== true && buildNoPermissionsText()}
                <Grid item xs={12}>
                    {pickOnly !== true ? buildDeleteItemButton() : buildSelectItemButton(document)}
                </Grid>
            </Grid>
        );
    }

    function buildAutocompleteFromList(array, value, label, name) {
        return (
            <Autocomplete
                multiple
                options={array}
                getOptionLabel={e => e.name}
                getOptionSelected={e => value.filter(a => a.id === e.id).length > 0}
                className={classes.selectArea}
                onChange={(_, values) => handleSelect(values, name)}
                value={value}
                placeholder="Search by Name"
                filterSelectedOptions
                renderInput={params => <TextField {...params} variant="filled" label={label} InputLabelProps={{ shrink: true }} />}
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip key={index} color="primary" label={option.name} {...getTagProps({ index })} />)}
            />
        );
    }

    function buildAddNewFolderButton() {
        return (
            <Button className={`${classes.button} ${classes.paneButton}`} variant="contained" startIcon={<CreateNewFolderIcon />} onClick={() => setShowNewFolder(true)} color="primary" fullWidth>
                New Folder
            </Button>
        );
    }

    function buildAddNewDocumentButton() {
        return (
            <Button className={`${classes.button} ${classes.paneButton}`} variant="contained" startIcon={<CloudUpload />} onClick={() => setShowNewFile(true)} color="primary" fullWidth>
                Add File
            </Button>
        );
    }

    function buildDeleteItemButton(disabled = false) {
        return (
            <Button className={`${classes.button} red`} variant="contained" startIcon={<Delete />} onClick={() => setShowDeleteItem(true)} color="primary" disabled={disabled} fullWidth>
                Delete
            </Button>
        );
    }

    function buildSelectItemButton(document) {
        return (
            !isNullOrUndefined(onSelect) && (
                <Button
                    className={classes.button}
                    variant="contained"
                    startIcon={<Check />}
                    onClick={() => {
                        onSelect({ ...document, url: documentUrl });
                        onClose();
                    }}
                    color="primary"
                    fullWidth
                >
                    Select
                </Button>
            )
        );
    }

    function buildAddNewItemDialog() {
        return (
            <Dialog open={showNewFolder || showNewFile} maxWidth="xs" fullWidth onClose={() => handleCloseAndClearFormDialogs()}>
                <form onSubmit={showNewFile ? handleAddNewFile : handleAddNewFolder}>
                    <DialogTitle>{showNewFile ? 'Add New File' : 'Add New Folder'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField value={itemName} variant="filled" label={showNewFile ? 'New File Name' : 'New Folder Name'} name="itemName" onChange={handleInput} required fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField value={itemDesc} variant="filled" label="Description" name="itemDesc" onChange={handleInput} required fullWidth multiline rows={3} />
                            </Grid>
                            {showNewFile ? (
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel shrink>Select File (optional)</InputLabel>
                                        <FilledInput id="upload-input" aria-describedby="upload-helper" type="file" name="itemFile" onChange={handleInput} fullWidth />
                                    </FormControl>
                                </Grid>
                            ) : null}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" disabled={saving} type="submit">
                            Save
                        </Button>
                        <Button color="primary" onClick={() => handleCloseAndClearFormDialogs()}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }

    function buildConfirmDeleteDialog() {
        return (
            <Dialog open={showDeleteItem} onClose={() => setShowDeleteItem(false)}>
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        Are you sure you want to delete <b>{selectedItem?.title ?? ' the selected item'}</b>?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" disabled={saving} onClick={() => handleDeleteSelectedItem()}>
                        I&apos;m Sure
                    </Button>
                    <Button color="primary" onClick={() => setShowDeleteItem(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildErrorDialog() {
        return (
            <Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Alert header="Something went wrong!" text={warning} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setWarning(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <>
            <Dialog
                open={open}
                fullScreen={matches}
                onClose={() => {
                    onClose();
                    setSelectedItem(null);
                }}
                className={classes.docWrapper}
                maxWidth="lg"
                fullWidth
            >
                <div className={classes.leftArea}>{buildLeftPane()}</div>
                <div className={classes.rightArea}>
                    {buildRightPane()}
                    <IconButton
                        className={classes.closeBtn}
                        onClick={() => {
                            onClose();
                            setSelectedItem(null);
                        }}
                    >
                        <Cancel />
                    </IconButton>
                </div>
            </Dialog>

            {buildAddNewItemDialog()}
            {buildConfirmDeleteDialog()}
            {buildErrorDialog()}
        </>
    );
}

Documents.propTypes = {
    open: PropTypes.bool.isRequired,
    companyId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    pickOnly: PropTypes.bool,
};
