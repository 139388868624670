import UserController from '../controllers/UserController';
import { IndexedDB } from './IndexedDB';
import { isNullOrUndefined } from './Utils';

const TokenRefresher = {
    checkAndRefeshToken,
};

var isRefreshing = false;

async function checkAndRefeshToken(response) {
    if (isRefreshing) {
        return;
    }
    isRefreshing = true;
    const { jwtrequiresrefresh } = response?.headers;
    if (!isNullOrUndefined(jwtrequiresrefresh) && jwtrequiresrefresh === 'true') {
        const dbTokenResponse = await IndexedDB.fetch('refreshToken');
        if (dbTokenResponse.hasError) {
            console.log(dbTokenResponse.data);
            isRefreshing = false;
        }
        const refreshResponse = await UserController.refreshToken(dbTokenResponse.data?.content);
        if (refreshResponse.hasError) {
            console.error(refreshResponse.data);
            isRefreshing = false;
        }
        const { token, expires, refreshToken } = refreshResponse.data;
        const cacheResponse = await UserController.refreshTokenCache(token, refreshToken, expires);
        if (cacheResponse.hasError) {
            console.error(cacheResponse.data);
            isRefreshing = false;
        }
    }
    isRefreshing = false;
}

export default TokenRefresher;
