import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, Typography, IconButton, Checkbox } from '@material-ui/core';
import { AllInclusive, ArrowLeft, ArrowRight } from '@material-ui/icons';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
    root: {
        '& .selector': {
            display: 'flex',
            height: '400px',
        },
        '& .top': {
            position: 'absolute',
            width: '100%',
            padding: 8,
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: Colours.white,
            WebkitBoxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
            MozBoxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
            boxShadow: '-2px 0px 5px 0px rgba(0,0,0,0.25)',
            '& .MuiTypography-button': {
                fontSize: 12,
            },
        },
        '& .pane': {
            width: '50%',
            height: '100%',
            overflowY: 'hidden',
            position: 'relative',
            paddingTop: 102,
            '&:last-child': {
                borderLeft: `1px solid ${Colours.archived}`,
            },
        },
        '& .inner': {
            overflowY: 'auto',
            height: '100%',
            padding: 8,
        },
        '& .actions': {
            borderTop: `1px solid ${Colours.archived}`,
            textAlign: 'center',
        },
        '& .select-all': {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        '& .empty-message': {
            padding: 12,
            textAlign: 'center',
        },
    },
}));

export function ComplianceSelector(props) {
    const { leftTitle, leftSubtitle, leftChildren, rightTitle, rightSubtitle, rightChildren, onSelectAllLeft, onSelectAllRight, onMoveLeft, onMoveRight, emptyRightMessage } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={2}>
            <div className="selector">
                <div className="pane">
                    <div className="top">
                        <Typography variant="button" component="div">
                            {leftTitle}
                        </Typography>
                        <Typography variant="caption" component="div" gutterBottom>
                            {leftSubtitle}
                        </Typography>
                        <div className="select-all">
                            <AllInclusive />
                            <Checkbox onChange={onSelectAllLeft} />
                        </div>
                    </div>
                    <div className="inner">{leftChildren}</div>
                </div>

                <div className="pane">
                    <div className="top">
                        <Typography variant="button" component="div">
                            {rightTitle}
                        </Typography>
                        <Typography variant="caption" component="div" gutterBottom>
                            {rightSubtitle}
                        </Typography>
                        <div className="select-all">
                            <AllInclusive />
                            <Checkbox onChange={onSelectAllRight} />
                        </div>
                    </div>
                    <div className="inner">{rightChildren?.length > 0 ? rightChildren : <Typography className="empty-message">{emptyRightMessage}</Typography>}</div>
                </div>
            </div>
            <div className="actions">
                <IconButton color="primary" onClick={onMoveLeft}>
                    <ArrowLeft />
                </IconButton>
                <IconButton color="primary" onClick={onMoveRight}>
                    <ArrowRight />
                </IconButton>
            </div>
        </Card>
    );
}

ComplianceSelector.propTypes = {
    leftTitle: PropTypes.string.isRequired,
    leftSubtitle: PropTypes.string.isRequired,
    leftChildren: PropTypes.array.isRequired,
    rightTitle: PropTypes.string.isRequired,
    rightSubtitle: PropTypes.string.isRequired,
    rightChildren: PropTypes.array.isRequired,
    onSelectAllLeft: PropTypes.func.isRequired,
    onSelectAllRight: PropTypes.func.isRequired,
    onMoveLeft: PropTypes.func.isRequired,
    onMoveRight: PropTypes.func.isRequired,
    emptyRightMessage: PropTypes.string,
};
