import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const UserGroupController = {
    getUserGroups,
    addUserGroup,
    editUserGroup,
    deleteUserGroup,
};

async function getUserGroups(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_USER_GROUPS(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addUserGroup(companyId, name, scored) {
    const userHeaders = await UserController.getUserHeaders();
    const userGroupModel = { name, scored };
    return Axios.post(APIRoute + APIPath.ADD_USER_GROUP(companyId), userGroupModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editUserGroup(companyId, userGroupId, name, scored) {
    const userHeaders = await UserController.getUserHeaders();
    const userGroupModel = { id: userGroupId, name, scored };
    return Axios.post(APIRoute + APIPath.EDIT_USER_GROUP(companyId), userGroupModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function deleteUserGroup(companyId, userGroupId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_USER_GROUP(companyId) + userGroupId, null, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default UserGroupController;
