const { usersCache } = window?.history?.state ?? {};

const USERS_INITIAL_STATE = {
    users: usersCache ?? [],
};

const Users = (state = USERS_INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_USERS':
            return { ...state, users: action.items };
        case 'LOGGED_OUT':
            return USERS_INITIAL_STATE;
        default:
            return state;
    }
};

export default Users;
