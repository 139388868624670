import React from 'react';
import NoImage from '../../images/no-img.png';
import PropTypes from 'prop-types';
import ShipController from '../../controllers/ShipController';
import { Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, FilledInput } from '@material-ui/core';
import { clearUploadUrls, getUploadUrls, readUploadUrls } from '../../helpers/UploadReader';
import { isNullOrUndefined } from '../../helpers/Utils';
import { ItemImage } from '../../components/Common/ItemImage';

export function FleetAddEdit(props) {
    const { ship, open, onClose, onError, onDone, companyId } = props;
    const [name, setName] = React.useState('');
    const [imo, setImo] = React.useState('');
    const [portOfRegistry, setPortOfRegistry] = React.useState('');
    const [image, setImage] = React.useState('');
    const [removeImage, setRemoveImage] = React.useState(false);
    const [, updateState] = React.useState();

    const forceUpdate = React.useCallback(() => {
        updateState({});
    }, []);

    const shipExists = () => !isNullOrUndefined(ship?.id);

    // update fields with current ship info
    React.useEffect(() => {
        setName(ship?.name ?? '');
        setImo(ship?.imo ?? '');
        setPortOfRegistry(ship?.portOfRegistry ?? '');
        setImage(ship?.logoPath ?? '');
    }, [ship]);

    function getImage() {
        return getUploadUrls()[0] ?? (image === '' ? NoImage : image);
    }

    async function handleSave(event) {
        event.preventDefault();
        onError(null);
        const response = !shipExists()
            ? await ShipController.addShip(companyId, name, imo, portOfRegistry, image)
            : await ShipController.updateShip(companyId, ship.id, name, imo, portOfRegistry, removeImage, image);
        if (response.hasError) {
            onError(response.data);
        } else {
            onDone(response.data);
        }
        onClose();
    }

    function handleInput(event) {
        const { value, name, files } = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'imo':
                setImo(value);
                break;
            case 'portOfRegistry':
                setPortOfRegistry(value);
                break;
            case 'image':
                clearUploadUrls();
                if (!isNullOrUndefined(files[0])) {
                    setImage(files[0]);
                    setRemoveImage(false);
                    readUploadUrls([files[0]], forceUpdate);
                } else {
                    setImage('');
                }
                break;
            default:
                break;
        }
    }

    function handleClearImage() {
        clearUploadUrls();
        setImage('');
        setRemoveImage(true);
    }

    return (
        <Dialog open={open}>
            <form onSubmit={handleSave}>
                <DialogTitle>Add/Edit Ship</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField variant="filled" label="Name" value={name} onChange={handleInput} name="name" required fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField variant="filled" label="IMO" value={imo} onChange={handleInput} name="imo" required fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField variant="filled" label="Port of registry" value={portOfRegistry} onChange={handleInput} name="portOfRegistry" required fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel shrink>Image</InputLabel>
                                        <FilledInput
                                            id="upload-input"
                                            aria-describedby="upload-helper"
                                            type="file"
                                            name="image"
                                            onChange={handleInput}
                                            inputProps={{
                                                accept: 'image/*',
                                            }}
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <ItemImage image={getImage()} onClose={handleClearImage} disabled={image === ''} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit">
                        Save
                    </Button>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

FleetAddEdit.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    ship: PropTypes.object,
};
