import React from 'react';
import SentiniLogo from '../../images/fathom-final.png';
import BridgeImage from '../../images/bridge.png';
import AppStore from '../../images/app-store.png';
import GooglePlay from '../../images/google-play.png';
import { makeStyles, Button, Grid, Container, Typography } from '@material-ui/core';
import { AuthLayout } from '../../components/Auth/AuthLayout';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    logo: {
        marginBottom: 20,
        maxWidth: '100%',
    },
    storeLink: {
        display: 'inline-block',
        width: '50%',
        '& img': {
            WebkitBoxShadow: '0px 0px 2px 0px rgba(0,0,0,0.6)',
            boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.6)',
            borderRadius: 12,
            maxWidth: 160,
            margin: 6,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

export default function NoAccess() {
    const classes = useStyles();

    return (
        <AuthLayout heroImage={BridgeImage}>
            <Container maxWidth="xs">
                <img src={SentiniLogo} alt="Sentini Marine" className={classes.logo} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography gutterBottom align="center">
                            Sorry, you do not have sufficient permissions to access the administrators&apos; portal
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" align="center">
                            Please download the CBT mobile app instead
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="a" href="https://apps.apple.com/us/app/yacht-knowledge/id1539901275" align="center" className={classes.storeLink}>
                            <img alt="App Store" src={AppStore} />
                        </Typography>
                        <Typography component="a" href="https://play.google.com/store/apps/details?id=com.sentinimarine.cbt" align="center" className={classes.storeLink}>
                            <img alt="Google Play" src={GooglePlay} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button component={Link} to="/Login" variant="contained" color="primary" fullWidth>
                            Back to Login
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </AuthLayout>
    );
}
