import React from 'react';
import PropTypes from 'prop-types';
import parser from 'cron-parser';
import moment from 'moment';
import { makeStyles, Card, CardContent, Typography, IconButton, Popover, Chip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Colours } from '../../helpers/Colours';
import { isNullOrUndefined } from '../../helpers/Utils';

const useStyles = makeStyles(theme => ({
    assessmentItem: {
        position: 'relative',
        padding: '16px !important',
        backgroundColor: Colours.white,
        '& .assessment': {
            flexGrow: 1,
            [theme.breakpoints.down('xs')]: {
                marginRight: 30,
            },
        },
        '& .options': {
            position: 'absolute',
            right: 4,
            top: 4,
        },
        '& .categories': {
            display: 'block',
            '& .MuiChip-root': {
                margin: 2,
            },
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        '& .totals': {
            display: 'flex',
            flexWrap: 'wrap',
        },
        '& .total': {
            marginTop: 8,
            marginRight: 16,
        },
    },
    popover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiButton-root': {
            textTransform: 'none',
            minWidth: 120,
        },
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
            fontWeight: 400,
        },
    },
}));

export function ComplianceAssessmentCard(props) {
    const { title, categories, totalQuestions, userCount, shipCount, children, cron, start, end } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles(props);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getUniqueCategories() {
        const cats = [];
        categories.forEach(e => {
            if (!cats.includes(e)) {
                cats.push(e);
            }
        });
        return cats;
    }

    function getNextDueDate() {
        if (isNullOrUndefined(cron)) {
            return 'Never';
        }
        const startDate = moment.utc(start);
        const endDate = moment.utc(end);
        const today = moment().utc();
        const workingDate = today.isBefore(endDate) ? (today.isAfter(startDate) ? today : startDate) : null;
        if (isNullOrUndefined(workingDate)) {
            return 'Never';
        }
        const options = {
            currentDate: workingDate.toDate(),
            endDate: endDate.toDate(),
            iterator: true,
        };
        try {
            const interval = parser.parseExpression(cron, options);
            const next = interval.next();
            const date = moment(next.value.toString()).format('D MMM YYYY');
            return date;
        } catch {
            return 'Never';
        }
    }

    const open = Boolean(anchorEl);
    const popoverId = open ? 'search-item-popover' : undefined;

    return (
        <>
            <Card variant="outlined">
                <CardContent className={classes.assessmentItem}>
                    <div className="assessment">
                        <Typography variant="h5">{title}</Typography>
                        <Typography variant="body2" gutterBottom>
                            Asks {totalQuestions} question{totalQuestions > 1 ? 's' : ''} per assessment
                        </Typography>
                    </div>
                    <div className="categories">
                        {getUniqueCategories().map(e => (
                            <Chip key={e} variant="outlined" label={e} />
                        ))}
                    </div>
                    <div className="totals">
                        <div className="total">
                            <Typography>
                                <b>Assigned Crew</b>
                            </Typography>
                            <Typography>{userCount}</Typography>
                        </div>
                        <div className="total">
                            <Typography>
                                <b>Assigned Ships</b>
                            </Typography>
                            <Typography>{shipCount}</Typography>
                        </div>
                        <div className="total">
                            <Typography>
                                <b>Next Due</b>
                            </Typography>
                            <Typography>{getNextDueDate()}</Typography>
                        </div>
                    </div>
                    <IconButton className="options" size="small" onClick={handleClick}>
                        <MoreVert />
                    </IconButton>
                </CardContent>
            </Card>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.popover}
            >
                {children}
            </Popover>
        </>
    );
}

ComplianceAssessmentCard.propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    totalQuestions: PropTypes.number.isRequired,
    userCount: PropTypes.number.isRequired,
    shipCount: PropTypes.number.isRequired,
    cron: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    children: PropTypes.any,
};
