import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, CardContent, Typography, IconButton, Popover } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {
        height: 56,
    },
    content: {
        position: 'relative',
        height: '100%',
        padding: 0 + ' !important',
        '& .MuiTypography-root': {
            padding: 12,
            width: 'calc(100% - 48px)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            position: 'absolute',
        },
    },
    popover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiButton-root': {
            textTransform: 'none',
            minWidth: 120,
        },
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
            fontWeight: 400,
        },
    },
    iconBtn: {
        position: 'absolute',
        right: 0,
        top: 4,
    },
}));

export function SearchCardMini(props) {
    const { title, children } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'search-item-popover' : undefined;

    return (
        <>
            <Card className={classes.root} elevation={2}>
                <CardContent className={classes.content}>
                    <Typography variant="h6">{title ?? 'NONAME'}</Typography>
                    <IconButton aria-describedby={id} className={classes.iconBtn} onClick={handleClick}>
                        <MoreVert />
                    </IconButton>
                </CardContent>
            </Card>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.popover}
            >
                {children}
            </Popover>
        </>
    );
}

SearchCardMini.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
};
