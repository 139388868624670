import React from 'react';
import PropTypes from 'prop-types';
import chartist from 'chartist';
import ChartistGraph from 'react-chartist';
import { Button, ButtonGroup, Card, CardActions, CardHeader, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';
import trendline from 'trendline';
import 'chartist-plugin-legend';
import 'chartist-plugin-tooltips';
import 'chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css';
import moment from 'moment';
import { ArrowLeft, ArrowRight, ZoomIn, ZoomOut } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    wrapper: {
        minHeight: 500,
        position: 'relative',
        '& .ct-chart': {
            minHeight: 400,
            ...chartLineStyle(Colours.primary, 'a'),
            ...chartLineStyle(Colours.secondary, 'b'),
            ...chartLineStyle('#f05b4f', 'c'),
            ...chartLineStyle('#f4c63d', 'd'),
            ...chartLineStyle('#46e459', 'e'),
            ...chartLineStyle('#7646e4', 'f'),
            ...chartLineStyle('#ff0070', 'g'),
        },
        '& .ct-chart-line': {
            minHeight: 379,
        },
        '& .ct-legend': {
            '& li:before': {
                top: 3,
            },
            ...chartLegendStyle(Colours.primary, 1),
            ...chartLegendStyle(Colours.secondary, 2),
            ...chartLegendStyle('#f05b4f', 3),
            ...chartLegendStyle('#f4c63d', 4),
            ...chartLegendStyle('#46e459', 5),
            ...chartLegendStyle('#7646e4', 6),
            ...chartLegendStyle('#ff0070', 7),
        },
        '& .ct-labels': {
            '& foreignObject': {
                fontWeight: 900,
                backgroundColor: 'rgba(255, 255, 255, 0.87)',
            },
        },
    },
    trendline: {
        '& .ct-series-b': {
            '& path': {
                strokeDasharray: '10,10',
            },
        },
    },
    tooltip: {
        top: 0,
        left: 0,
        color: '#000',
        backgroundColor: '#fff',
        borderRadius: 4,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        zIndex: 100,
        '&::before': {
            borderTopColor: 'transparent',
        },
    },
    tooltipHideValue: {
        '& .chartist-tooltip-value': {
            display: 'none',
        },
    },
}));

const chartLegendStyle = (color, index) => ({
    '& li.inactive::before': {
        background: 'transparent !important',
    },
    [`& :nth-child(${index})::before`]: {
        background: color,
        borderColor: color,
    },
});

const chartLineStyle = (color, series) => ({
    [`& g.ct-series-${series}`]: {
        '& .ct-line': {
            stroke: color,
        },
        '& .ct-point': {
            stroke: color,
        },
    },
});

const zoomLevels = [
    {
        range: 4,
        interval: 1,
        name: '4 Weeks',
    },
    {
        range: 8,
        interval: 1,
        name: '8 Weeks',
    },
    {
        range: 12,
        interval: 1,
        name: '12 Weeks',
    },
    {
        range: 24,
        interval: 2,
        name: '24 Weeks',
    },
    {
        range: 52,
        interval: 4,
        name: '1 Year',
    },
];

export function DashboardGraph(props) {
    const { title, labels, series, legend, scatterGraph, loading } = props;
    const [zoomLevel, setZoomLevel] = React.useState(4);
    const [dateRange, setDateRange] = React.useState({
        end: getDataValueFromDate(moment()),
        start: getDataValueFromDate(moment().subtract('week', zoomLevels[zoomLevel].range)),
    });
    const [cache, setCache] = React.useState(true);
    const [rendering, setRendering] = React.useState(true);
    const classes = useStyles();

    const cleanupTooltips = React.useCallback(() => {
        const tooltips = window.document.getElementsByClassName('chartist-tooltip');
        for (let i = 0; i < tooltips.length; i++) {
            tooltips[i].removeAttribute('style');
        }
    }, []);

    React.useEffect(() => {
        const newCache = JSON.stringify({ title, labels, series, legend });
        if (cache !== newCache) {
            setCache(newCache);
            setRendering(false);
            setTimeout(() => {
                setRendering(true);
            }, 50);
        }
        return cleanupTooltips;
    }, [title, labels, series, legend, cache, cleanupTooltips]);

    function handleOffsetDateRange(steps = 1) {
        const start = getDateFromDataValue(dateRange.start);
        const end = getDateFromDataValue(dateRange.end);
        setDateRange({
            end: getDataValueFromDate(end.add('week', steps)),
            start: getDataValueFromDate(start.add('week', steps)),
        });
    }

    function handleZoom(step = 1) {
        const desiredZoom = zoomLevel + step;
        if (desiredZoom >= zoomLevels.length || desiredZoom < 0) {
            return;
        }
        setZoomLevel(desiredZoom);
        setDateRange({
            end: dateRange.end,
            start: getDataValueFromDate(getDateFromDataValue(dateRange.end).subtract('week', zoomLevels[desiredZoom].range)),
        });
    }

    function getDataValueFromDate(value) {
        return value.unix() * 60 * 60 * 24;
    }

    function getDateFromDataValue(value) {
        return moment.unix(value / 24 / 60 / 60);
    }

    function getDateRangeLabels() {
        const out = [];
        const date = getDateFromDataValue(dateRange.start);
        const end = getDateFromDataValue(dateRange.end);
        while (date.isBefore(end)) {
            out.push(date.unix() * 60 * 60 * 24);
            date.add('week', zoomLevels[zoomLevel].interval);
        }
        return out;
    }

    const scatterGraphData = scatterGraph ? series[0]?.data.filter(e => e.x > dateRange.start && e.x < dateRange.end).map(e => ({ ...e, x: e.x, y: e.y !== null ? parseFloat(e.y) : null })) ?? [] : [];

    const trend = trendline(scatterGraphData, 'x', 'y');

    const data = {
        labels: scatterGraph ? getDateRangeLabels() : labels,
        series: scatterGraph
            ? [
                  { name: 'scatter', data: scatterGraphData },
                  { name: 'trend', data: getDateRangeLabels().map(e => ({ x: e, y: trend.calcY(e) })) },
              ]
            : series,
    };

    const options = {
        showGrid: true,
        plugins: [
            chartist.plugins.tooltip({
                class: `${classes.tooltip}${scatterGraph ? ` ${classes.tooltipHideValue}` : ''}`,
                anchorToPoint: true,
                appendToBody: true,
            }),
            chartist.plugins.legend({
                legendNames: legend,
            }),
        ],
        series: scatterGraph
            ? {
                  scatter: {
                      showLine: false,
                  },
                  trend: {
                      showPoint: false,
                  },
              }
            : {},
        axisY: {
            labelInterpolationFnc: function (value) {
                return value % 1 === 0 ? value : null;
            },
            ...(scatterGraph
                ? {
                      low: 0,
                      high: 100,
                  }
                : {}),
        },
        axisX: scatterGraph
            ? {
                  type: scatterGraph ? chartist.FixedScaleAxis : null,
                  ticks: scatterGraph ? getDateRangeLabels() : null,
                  labelInterpolationFnc: scatterGraph
                      ? function (value) {
                            return getDateFromDataValue(value).format('DD.MM.YYYY');
                        }
                      : null,
                  low: scatterGraph ? dateRange.start : null,
                  high: scatterGraph ? dateRange.end : null,
              }
            : {},
    };

    const type = 'Line';

    return (
        <Card className={`${classes.wrapper}${scatterGraph ? ` ${classes.trendline}` : ''}`}>
            {rendering && (
                <>
                    <CardHeader title={title} />
                    <ChartistGraph data={data} options={options} type={type} />
                    {loading ? <CircularProgress size={24} className="progress" /> : null}
                </>
            )}
            {scatterGraph && (
                <CardActions style={{ justifyContent: 'space-between' }}>
                    <Typography variant="body2" style={{ paddingLeft: 12 }}>
                        <b>Showing {zoomLevels[zoomLevel].name}</b>
                    </Typography>
                    <ButtonGroup variant="outlined">
                        <Button onClick={() => handleOffsetDateRange(-1)}>
                            <ArrowLeft />
                        </Button>
                        <Button onClick={() => handleZoom(-1)} disabled={zoomLevel <= 0}>
                            <ZoomIn />
                        </Button>
                        <Button onClick={() => handleZoom(1)} disabled={zoomLevel >= zoomLevels.length - 1}>
                            <ZoomOut />
                        </Button>
                        <Button onClick={() => handleOffsetDateRange(1)}>
                            <ArrowRight />
                        </Button>
                    </ButtonGroup>
                </CardActions>
            )}
        </Card>
    );
}

DashboardGraph.propTypes = {
    title: PropTypes.string.isRequired,
    series: PropTypes.array.isRequired,
    scatterGraph: PropTypes.bool,
    labels: PropTypes.array.isRequired,
    legend: PropTypes.array.isRequired,
    loading: PropTypes.bool,
};
