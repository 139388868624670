import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: 200,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        '& .delete-btn': {
            backgroundColor: Colours.white,
            position: 'absolute',
            top: 8,
            right: 8,
        },
    },
}));

export function ItemImage(props) {
    const { image, disabled, onClose } = props;
    const classes = useStyles();

    return (
        <Card className={classes.root} style={{ backgroundImage: `url(${image}` }}>
            <IconButton className="delete-btn" color="secondary" size="small" onClick={onClose} disabled={disabled}>
                <Cancel />
            </IconButton>
        </Card>
    );
}

ItemImage.propTypes = {
    image: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
};
