import Axios from 'axios';
import UserController from './UserController';
import { APIPath, APIRoute } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const UserComplianceTestLevelController = {
    getUserComplianceTestLevels,
    editUserComplianceTestLevels,
};

async function getUserComplianceTestLevels(userId, complianceTestIds) {
    const userHeaders = await UserController.getUserHeaders();
    const model = {
        userId,
        complianceTestIds,
    };

    return Axios.post(APIRoute + APIPath.GET_USER_COMPLIANCE_TEST_LEVELS, model, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editUserComplianceTestLevels(userId, testLevelIds, complianceTestIds) {
    const userHeaders = await UserController.getUserHeaders();
    const model = {
        userId,
        testLevelIds,
        complianceTestIds,
    };

    return Axios.post(APIRoute + APIPath.EDIT_USER_COMPLIANCE_TEST_LEVELS, model, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default UserComplianceTestLevelController;
