import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ComplianceController from '../../controllers/ComplianceController';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    CircularProgress,
    Grid,
    Typography,
    TextField,
    Card,
    Checkbox,
    FormControl,
    InputLabel,
    Slider,
    FormControlLabel,
    useTheme,
    useMediaQuery,
    RadioGroup,
    Radio,
    MenuItem,
    Select,
    IconButton,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { Colours } from '../../helpers/Colours';
import { ComplianceSelector } from './ComplianceSelector';
import getCustomIcon from '../../helpers/CustomIcon';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
    loading: {
        maxHeight: '60vh',
        height: 512,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        color: Colours.bodyText,
    },
    selection: {
        padding: 8,
        marginTop: 4,
        position: 'relative',
        '&.archived': {
            backgroundColor: Colours.archived,
        },
        '& .checkbox': {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        '& .slider': {
            paddingTop: 24,
        },
        '& .simple-name': {
            paddingRight: 32,
        },
    },
    mandatoryCheckbox: {
        marginLeft: 0,
    },
    recurrenceArea: {
        padding: 10,
        borderRadius: 6,
        fontSize: 16,
        fontWeight: 300,
        color: Colours.bodyText,
        '& .repeatHeader': {
            marginRight: 20,
        },
        '& .dropdownPicker': {
            color: Colours.primary,
            border: 'solid 2px',
            borderColor: Colours.bodyText,
            borderRadius: 6,
            height: 30,
            padding: 5,
            margin: '8px 10px 8px 0px',
            fontSize: 16,
        },
        '& .dateTimePicker': {
            border: 'solid 2px',
            borderColor: Colours.bodyText,
            borderRadius: 6,
            padding: 5,
            margin: '8px 10px 8px 0px',
            height: 30,
            '& input': {
                minWidth: 200,
                textAlign: 'center',
                color: Colours.primary,
                fontSize: 16,
                height: 'auto',
                padding: 0,
                cursor: 'pointer',
            },
        },
        '& .buttonPicker': {
            color: Colours.primary,
            border: 'solid 2px',
            borderColor: Colours.bodyText,
            borderRadius: 6,
            padding: 5,
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: '8px',
            height: '30px',
            width: '50px',
            minWidth: '0',
            '& .MuiButton-label': {
                top: 1,
                position: 'absolute',
            },
        },
        '& .timeHolders': {
            display: 'flex',
        },
        '& .recurrenceHeaders': {
            marginTop: 16,
        },
        '& .recurrenceChecked': {
            'MuiRadio-colorSecondary.Mui-checked': `${Colours.primary} !important`,
        },
    },
    testLevel: {
        display: 'flex',
    },
    iconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginRight: 12,
        marginTop: 4,
        width: '100%',
        '& .MuiSvgIcon-root': {
            cursor: 'pointer',
        },
    },
    levelContainer: {
        '& .MuiGrid-item': {
            display: 'flex',
            alignItems: 'center',
        },
    },
}));

export function ComplianceAddEditAssessment(props) {
    const { assessment, open, onClose, onDone, companyId, usersArray, questionsArray, userGroupsArray, shipsArray, categories } = props;
    const [warning, setWarning] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');

    const [step, setStep] = React.useState(0);
    const [questionFrequencies, setQuestionFrequencies] = React.useState([]);
    const [questionSelections, setQuestionSelections] = React.useState([]);
    const [userSelections, setUserSelections] = React.useState([]);
    const [userGroupSelections, setUserGroupSelections] = React.useState([]);
    const [shipSelections, setShipSelections] = React.useState([]);
    const [questionsToAsk, setQuestionsToAsk] = React.useState('');
    const [questionSearch, setQuestionSearch] = React.useState('');
    const [userSearch, setUserSearch] = React.useState('');
    const [groupSearch, setGroupSearch] = React.useState('');
    const [shipSearch, setShipSearch] = React.useState('');

    const [repeatSelect, setRepeatSelect] = React.useState('Weekly');
    const [endSelect, setEndSelect] = React.useState('On');
    const [startDate, setStartDate] = React.useState(moment());
    const [endDate, setEndDate] = React.useState(moment());
    const [mandatory, setMandatory] = React.useState(false);
    const [name, setName] = React.useState('');
    const [iconIndex, setIconIndex] = React.useState(0);
    const [categoryId, setCategoryId] = React.useState(null);
    const [loadAssessment, setLoadAssessment] = React.useState(false);
    const [complianceTestLevels, setComplianceTestLevels] = React.useState([]);
    const [testLevelCrons, setTestLevelCrons] = React.useState([]);

    const selectedQuestions = useMemo(() => questionSelections.filter(e => e.selected), [questionSelections]);
    const neverDate = moment.utc('2040-01-01');
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    const assessmentExists = () => !isNullOrUndefined(assessment?.id);

    const cacheFrequencies = React.useCallback(
        (step, questionSelections) => {
            if (step === 1 && questionFrequencies.length === 0) {
                setQuestionFrequencies(questionSelections.map(e => e.frequency));
            } else if (step !== 1 && questionFrequencies.length > 0) {
                setQuestionFrequencies([]);
            }
        },
        [questionFrequencies]
    );

    const loadCron = React.useCallback(
        assessment => {
            const splitCron = assessment.cronScheduling.split(' ');
            if (splitCron.length !== 5) {
                setWarning('Invalid schedule pattern, please check all fields have been filled!');
                return;
            }
            if (splitCron[0].includes(',')) {
                const minutes = splitCron[0].split(',');
                const hours = splitCron[1].split(',');
                startDate.minute(minutes[0]);
                startDate.minute(minutes[1]);
                startDate.hour(hours[0]);
                startDate.hour(hours[1]);
                setStartDate(startDate);
            } else {
                startDate.minute(splitCron[0]);
                startDate.hour(splitCron[1]);
                setStartDate(startDate);
            }
            if (splitCron[4].includes('*') && splitCron[3].includes('*') && splitCron[2].includes('*') && moment.utc(assessment.startTime).isSame(moment.utc(assessment.endTime), 'date')) {
                setRepeatSelect('Never');
            }

            if (!isNullOrUndefined(assessment) && !endDate.isSame(neverDate, 'date')) {
                setEndSelect('On');
            } else {
                setEndSelect('Never');
            }
        },
        [startDate, endDate, neverDate]
    );

    function loadAssessmentLevelCrons(level) {
        const splitCron = level.cronScheduling.split(' ');
        let cron = {
            levelIndex: level.levelIndex,
        };

        if (splitCron.length !== 5) {
            setWarning('Invalid schedule pattern, please check all fields have been filled!');
            return;
        }

        cron = { ...cron, repeatPatternDate: splitCron[2].split(',') };
        cron = { ...cron, repeatPatternMonth: splitCron[3].split(',') };
        cron = { ...cron, repeatPatternWeekDay: splitCron[4].split(',') };

        if (!splitCron[3].includes('*')) {
            cron = { ...cron, repeatSelect: 'Yearly' };
        } else if (!splitCron[2].includes('*')) {
            cron = { ...cron, repeatSelect: 'Monthly' };
        } else {
            cron = { ...cron, repeatSelect: 'Weekly' };
        }

        return cron;
    }

    const loadAssessmentData = React.useCallback(() => {
        if (assessment?.id === null || assessment?.id === undefined) {
            setQuestionSelections(questionsArray);
            setUserSelections(usersArray);
            setUserGroupSelections(userGroupsArray);
            setShipSelections(shipsArray);
            setQuestionsToAsk('');
            setStartDate(moment());
            setEndDate(neverDate);
            setName('');
            setIconIndex(0);
        } else {
            const shipSelections = shipsArray.map(e => ({ ...e, selected: assessment.ships.filter(a => a.id === e.id).length > 0 }));
            const assessmentCrons = [];
            const baselineLevel = {
                levelIndex: 0,
                numQuestions: assessment.questionCount,
                numPrevTestsToAccount: assessment.numPrevTestsToAccount ?? 0,
                scoreRequirement: assessment.scoreRequirement ?? 0,
            };
            const testLevelCron = {
                levelIndex: 0,
                repeatSelect: 'Weekly',
                repeatPatternWeekDay: [moment.utc().format('d')],
                repeatPatternDate: [moment.utc().format('D')],
                repeatPatternMonth: [moment.utc().format('M')],
            };

            setQuestionSelections(
                questionsArray.map(e => ({
                    ...e,
                    // filter out any previously selected questions if they have now unselected ships attached
                    selected: assessment.questions.filter(a => a.id === e.id).length > 0 && (e.ships.some(a => shipSelections.map(s => s.id).includes(a.id)) || e.ships.length === 0),
                }))
            );
            setUserSelections(
                usersArray.map(e => ({
                    ...e,
                    // filter out any previously selected users if they are now serving on unselected ships
                    selected: assessment.users.filter(a => a.id === e.id).length > 0 && e.ships.some(a => shipSelections.map(s => s.id).includes(a.shipId)),
                }))
            );

            setUserGroupSelections(userGroupsArray.map(e => ({ ...e, selected: assessment.userGroups.filter(a => a.id === e.id).length > 0 })));
            setShipSelections(shipSelections);
            setQuestionsToAsk(assessment.questionCount.toString());
            setStartDate(moment.utc(assessment.start).local());
            setEndDate(moment.utc(assessment.end).local());
            setName(assessment.title);
            setIconIndex(assessment.iconIndex);
            setComplianceTestLevels([baselineLevel, ...assessment.complianceTestLevels?.filter(e => e.levelIndex !== 0)]);
            setMandatory(!isNullOrUndefined(assessment.cronScheduling));

            if (!isNullOrUndefined(assessment.cronScheduling)) {
                loadCron(assessment);

                if (isNullOrUndefined(assessment.complianceTestLevels[0])) {
                    const baseCron = { levelIndex: 0, cronScheduling: assessment.cronScheduling };
                    assessmentCrons.push(loadAssessmentLevelCrons(baseCron));
                }
            } else {
                assessmentCrons.push(testLevelCron);
            }

            for (let i = 0; i < assessment?.complianceTestLevels.length; i++) {
                const level = assessment?.complianceTestLevels[i];
                if (!isNullOrUndefined(level.cronScheduling)) {
                    assessmentCrons.push(loadAssessmentLevelCrons(level));
                } else {
                    assessmentCrons.push({ ...testLevelCron, levelIndex: level.levelIndex });
                }
            }
            setTestLevelCrons(assessmentCrons);
        }
    }, [assessment, questionsArray, usersArray, userGroupsArray, shipsArray, neverDate, loadCron, loadAssessmentLevelCrons, testLevelCrons, setTestLevelCrons]);

    // initialise
    React.useEffect(() => {
        async function init() {
            setQuestionSearch('');
            setUserSearch('');
            setGroupSearch('');
            setShipSearch('');
            setComplianceTestLevels([]);
            setTestLevelCrons([]);
            setStep(0);
            if (open) {
                setLoadAssessment(true);
            }
        }
        setLoading(true);
        init().then(() => setLoading(false));
    }, [open]);

    // load assessment data when told
    React.useEffect(() => {
        if (loadAssessment) {
            loadAssessmentData();
            setLoadAssessment(false);
            setCategoryId(assessment.categoryId ?? categories[0].id);
        }
    }, [loadAssessment, loadAssessmentData]);

    // step setup
    React.useEffect(() => {
        if (open) {
            cacheFrequencies(step, questionSelections);
        }
    }, [step, open, questionSelections, cacheFrequencies]);

    function handleInput(event) {
        const { name, value } = event.target;

        setErrorText('');
        switch (name) {
            case 'numQuestions':
                if (value > selectedQuestions.length || value < 0) {
                    setErrorText(`Please provide a valid value (0-${selectedQuestions.length})`);
                    return;
                }
                setQuestionsToAsk(value);
                break;
            case 'questionSearch':
                setQuestionSearch(value);
                break;
            case 'userSearch':
                setUserSearch(value);
                break;
            case 'groupSearch':
                setGroupSearch(value);
                break;
            case 'shipSearch':
                setShipSearch(value);
                break;
            case 'repeatSelect':
                setRepeatSelect(value);
                break;
            case 'endSelect':
                setEndSelect(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'iconIndex':
                setIconIndex(value);
                break;
            case 'category':
                setCategoryId(value);
                break;
            default:
                break;
        }
    }

    function handleUpdateNewComplianceTestLevels(e, levelIndex) {
        const { name, value } = e.target;
        setErrorText('');

        switch (name) {
            case 'numQuestions':
                if (value > selectedQuestions.length || value < 0) {
                    setErrorText(`Please provide a valid value (0-${selectedQuestions.length})`);
                    return;
                }
                break;
            case 'scoreRequirement':
                if (value < 0 || value > 100) {
                    setErrorText('Score % must be between 0-100');
                    return;
                }
                break;
            default:
                break;
        }

        const newTestLevels = complianceTestLevels.map(e => (e.levelIndex === levelIndex ? { ...e, [name]: value } : e));
        setComplianceTestLevels(newTestLevels);
    }

    function filterQuestion(question) {
        return ('Q' + question.id.toString() + question.title + question.category.map(e => e.name).join('')).toLowerCase().includes(questionSearch.toLowerCase());
    }

    function filterUser(user) {
        return (user.firstName + user.lastName).toLowerCase().includes(userSearch.toLowerCase());
    }

    function filterUserGroup(userGroup) {
        return userGroup.name.toLowerCase().includes(groupSearch.toLowerCase());
    }

    function filterShip(ship) {
        return ship.name.toLowerCase().includes(shipSearch.toLowerCase());
    }

    function handleToggleCheckedQuestion(questionId, checked) {
        setQuestionSelections(questionSelections.map(e => (e.id === questionId && filterQuestion(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllUnselectedQuestions(checked) {
        setQuestionSelections(questionSelections.map(e => (!e.selected && filterQuestion(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllSelectedQuestions(checked) {
        setQuestionSelections(questionSelections.map(e => (e.selected && filterQuestion(e) ? { ...e, checked } : e)));
    }

    function handleSelectQuestions(selected) {
        setQuestionSelections(questionSelections.map(e => (e.selected !== selected && e.checked && filterQuestion(e) ? { ...e, selected, checked: false } : e)));
    }

    function handleUpdateFrequency(questionId, frequency) {
        setQuestionSelections(questionSelections.map(e => (e.id === questionId ? { ...e, frequency } : e)));
    }

    function handleToggleCheckedUser(userId, checked) {
        setUserSelections(userSelections.map(e => (e.id === userId && filterUser(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllUnselectedUsers(checked) {
        setUserSelections(userSelections.map(e => (!e.selected && filterUser(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllSelectedUsers(checked) {
        setUserSelections(userSelections.map(e => (e.selected && filterUser(e) ? { ...e, checked } : e)));
    }

    function handleSelectUsers(selected) {
        setUserSelections(userSelections.map(e => (e.selected !== selected && e.checked && filterUser(e) ? { ...e, selected, checked: false } : e)));
    }

    function handleToggleCheckedUserGroup(groupId, checked) {
        setUserGroupSelections(userGroupSelections.map(e => (e.id === groupId && filterUserGroup(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllUnselectedUserGroups(checked) {
        setUserGroupSelections(userGroupSelections.map(e => (!e.selected && filterUserGroup(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllSelectedUserGroups(checked) {
        setUserGroupSelections(userGroupSelections.map(e => (e.selected && filterUserGroup(e) ? { ...e, checked } : e)));
    }

    function handleSelectUserGroups(selected) {
        setUserGroupSelections(userGroupSelections.map(e => (e.selected !== selected && e.checked && filterUserGroup(e) ? { ...e, selected, checked: false } : e)));
    }

    function handleToggleCheckedShip(shipId, checked) {
        setShipSelections(shipSelections.map(e => (e.id === shipId && filterShip(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllUnselectedShips(checked) {
        setShipSelections(shipSelections.map(e => (!e.selected && filterShip(e) ? { ...e, checked } : e)));
    }

    function handleCheckAllSelectedShips(checked) {
        setShipSelections(shipSelections.map(e => (e.selected && filterShip(e) ? { ...e, checked } : e)));
    }

    function handleSelectShips(selected) {
        setShipSelections(shipSelections.map(e => (e.selected !== selected && e.checked && filterShip(e) ? { ...e, selected, checked: false } : e)));
    }

    function handleGenerateTestLevelCrons(testLevelCron) {
        let cron = startDate.format('m') + ' ' + startDate.format('H');
        const { repeatSelect, repeatPatternWeekDay, repeatPatternDate, repeatPatternMonth } = testLevelCron;

        switch (repeatSelect) {
            case 'Weekly':
                if (repeatPatternWeekDay.length === 0) {
                    //warn
                    return null;
                }
                cron += ' * * ' + (repeatPatternWeekDay.length === 7 ? '*' : repeatPatternWeekDay.join(','));
                return cron;
            case 'Monthly':
                if (repeatPatternDate.length === 0) {
                    //warn
                    return null;
                }
                cron += ' ' + (repeatPatternDate.length === 31 ? '*' : repeatPatternDate.join(',')) + ' * ' + (repeatPatternWeekDay.length === 7 ? '*' : repeatPatternWeekDay.join(','));
                return cron;
            case 'Yearly':
                if (repeatPatternDate.length === 0 || repeatPatternMonth.length === 0) {
                    //warn
                    return null;
                }
                cron +=
                    ' ' +
                    (repeatPatternDate.length === 31 ? '*' : repeatPatternDate.join(',')) +
                    ' ' +
                    (repeatPatternMonth.length === 12 ? '*' : repeatPatternMonth.join(',')) +
                    ' ' +
                    (repeatPatternWeekDay.length === 7 ? '*' : repeatPatternWeekDay.join(','));
                return cron;
            default:
                //warn
                setWarning('Invalid repeat state ' + repeatSelect);
                return null;
        }
    }

    function handleAssessmentLevelCronInputs(event, assessmentLevelIndex) {
        const { name, value } = event.target;
        switch (name) {
            case 'repeatSelect':
                setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatSelect: value } : e)));
                break;
            case 'repeatPatternWeekDay':
                if (value.includes('*')) {
                    const pattern = testLevelCrons.find(e => e.levelIndex === assessmentLevelIndex).repeatPatternWeekDay;
                    if (pattern.length === 1 && pattern.includes('*')) {
                        const v = value.filter(value => {
                            return value !== '*';
                        });
                        setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternWeekDay: v } : e)));
                        break;
                    }
                    setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternWeekDay: ['*'] } : e)));
                    break;
                }
                setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternWeekDay: value } : e)));
                break;
            case 'repeatPatternDate':
                if (value.includes('*')) {
                    const pattern = testLevelCrons.find(e => e.levelIndex === assessmentLevelIndex).repeatPatternDate;
                    if (pattern.length === 1 && pattern.includes('*')) {
                        const v = value.filter(value => {
                            return value !== '*';
                        });
                        setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternDate: v } : e)));
                        break;
                    }
                    setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternDate: ['*'] } : e)));
                    break;
                }
                setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternDate: value } : e)));
                break;
            case 'repeatPatternMonth':
                if (value.includes('*')) {
                    const pattern = testLevelCrons.find(e => e.levelIndex === assessmentLevelIndex).repeatPatternMonth;
                    if (pattern.length === 1 && pattern.includes('*')) {
                        const v = value.filter(value => {
                            return value !== '*';
                        });
                        setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternMonth: v } : e)));
                        break;
                    }
                    setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternMonth: ['*'] } : e)));
                    break;
                }
                setTestLevelCrons(testLevelCrons.map(e => (e.levelIndex === assessmentLevelIndex ? { ...e, repeatPatternMonth: value } : e)));
                break;
            default:
                break;
        }
    }

    function handleAddNewAssessmentLevel() {
        const newAssessmentLevel = { levelIndex: complianceTestLevels.length, numQuestions: 0, numPrevTestsToAccount: 0, scoreRequirement: 0, cronScheduling: null };
        const testLevelCron = {
            levelIndex: complianceTestLevels.length,
            repeatSelect: 'Weekly',
            repeatPatternWeekDay: [moment.utc().format('d')],
            repeatPatternDate: [moment.utc().format('D')],
            repeatPatternMonth: [moment.utc().format('M')],
        };

        setComplianceTestLevels([...complianceTestLevels, newAssessmentLevel]);
        setTestLevelCrons([...testLevelCrons, testLevelCron]);
    }

    function handleChangeAssessmentLevelPosition(index, direction) {
        const newIndex = index + direction;
        const newComplianceTestLevels = complianceTestLevels.map(e => (e.levelIndex === index ? { ...e, levelIndex: newIndex } : e.levelIndex === newIndex ? { ...e, levelIndex: index } : e));
        const newTestLevelCrons = testLevelCrons.map(e => (e.levelIndex === index ? { ...e, levelIndex: newIndex } : e.levelIndex === newIndex ? { ...e, levelIndex: index } : e));
        setComplianceTestLevels(newComplianceTestLevels);
        setTestLevelCrons(newTestLevelCrons);
    }

    function handleDeleteAssessmentLevel(index) {
        const newComplianceTestLevels = complianceTestLevels.filter(e => e.levelIndex !== index).map(e => (e.levelIndex > index ? { ...e, levelIndex: e.levelIndex - 1 } : e));
        const newTestLevelCrons = testLevelCrons.filter(e => e.levelIndex !== index).map(e => (e.levelIndex > index ? { ...e, levelIndex: e.levelIndex - 1 } : e));
        setComplianceTestLevels(newComplianceTestLevels);
        setTestLevelCrons(newTestLevelCrons);
    }

    async function handleSave(event) {
        event.preventDefault();
        setSaving(true);

        let cron = null;

        const scoreRequirement = complianceTestLevels[0]?.scoreRequirement ?? null;
        const numPrevTestsToAccount = complianceTestLevels[0]?.numPrevTestsToAccount ?? null;
        const questionsToAsk = complianceTestLevels[0]?.numQuestions ?? null;

        const newComplianceTestLevels = complianceTestLevels.map(e => {
            const testLevelCron = testLevelCrons.find(t => t.levelIndex === e.levelIndex);
            const generatedCron = mandatory ? handleGenerateTestLevelCrons(testLevelCron) : null;

            if (e.levelIndex === 0) {
                cron = generatedCron;
            }

            return { ...e, cronScheduling: generatedCron };
        });

        const selectedShips = shipSelections.filter(e => e.selected).map(e => e.id);
        const selectedQuestions = questionSelections.filter(e => e.selected).map(e => ({ id: e.id, frequency: e.frequency }));
        const selectedUsers = userSelections.filter(e => e.selected).filter(e => e.ships.some(s => selectedShips.includes(s.shipId)));
        const selectedUserIds = selectedUsers.map(e => e.id);
        const selectedUserGroups = userGroupSelections.filter(e => e.selected).map(e => e.id);

        const response = assessmentExists()
            ? await ComplianceController.editComplianceTest(
                  companyId,
                  assessment.id,
                  name,
                  iconIndex,
                  categoryId,
                  mandatory ? cron : null,
                  startDate,
                  mandatory ? endDate : neverDate,
                  true,
                  questionsToAsk,
                  scoreRequirement,
                  numPrevTestsToAccount,
                  selectedQuestions,
                  selectedShips,
                  selectedUserIds,
                  selectedUserGroups,
                  newComplianceTestLevels
              )
            : await ComplianceController.addComplianceTest(
                  companyId,
                  name,
                  iconIndex,
                  categoryId,
                  mandatory ? cron : null,
                  startDate,
                  mandatory ? endDate : neverDate,
                  true,
                  questionsToAsk,
                  scoreRequirement,
                  numPrevTestsToAccount,
                  selectedQuestions,
                  selectedShips,
                  selectedUserIds,
                  selectedUserGroups,
                  newComplianceTestLevels
              );
        if (response.hasError) {
            setWarning(response.data);
        } else {
            const assessmentResponse = await ComplianceController.getComplianceTest(companyId, response.data.id);
            if (assessmentResponse.hasError) {
                setWarning(assessmentResponse.data);
            } else {
                const { users, ships } = assessmentResponse.data;
                response.data = { ...response.data, users, ships };
                onDone(response.data);
                onClose();
            }
        }

        setSaving(false);
    }

    function buildErrorDialog() {
        return (
            <Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Alert header="Something went wrong!" text={warning} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setWarning(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildDialogContent() {
        switch (step) {
            case 0:
                return buildPageOne();
            case 1:
                return buildPageTwo();
            case 2:
                return buildPageThree();
            case 3:
                return buildPageFour();
            case 4:
                return buildPageFive();
            default:
                return null;
        }
    }

    function buildQuestionSelection(question, index, showFrequencySlider = false) {
        if (questionFrequencies.length === 0) {
            return null;
        }
        const defaultFrequency = questionFrequencies[index];
        const { id, title, isArchived, category, checked } = question;
        return (
            <Card key={id} square elevation={1} className={`${classes.selection}${isArchived ? ' archived' : ''}`}>
                <Typography>
                    <b>Q{id}</b>
                    {isArchived ? '  (archived)' : ''}
                </Typography>
                <Typography variant="caption" component="div" gutterBottom>
                    {category.map(e => e.name.toUpperCase()).join(', ')}
                </Typography>
                <Typography variant="body2" gutterBottom={showFrequencySlider}>
                    {title}
                </Typography>
                {showFrequencySlider ? (
                    <FormControl fullWidth margin="dense">
                        <InputLabel shrink>Repeat Frequency</InputLabel>
                        <Slider
                            className="slider"
                            defaultValue={defaultFrequency}
                            scale={x => (x * 0.01).toFixed(1)}
                            valueLabelDisplay="auto"
                            onChangeCommitted={(_, v) => handleUpdateFrequency(id, v)}
                            name="frequency"
                            step={1}
                            min={1}
                            max={100}
                        />
                    </FormControl>
                ) : null}
                <Checkbox className="checkbox" checked={checked} onChange={() => handleToggleCheckedQuestion(id, !checked)} />
            </Card>
        );
    }

    function buildSimpleSelection(item, onChange) {
        const { id, name, checked } = item;
        return (
            <Card key={id} square elevation={1} className={classes.selection}>
                <Typography className="simple-name">{name}</Typography>
                <Checkbox checked={checked} onChange={(_, v) => onChange(id, v)} className="checkbox" />
            </Card>
        );
    }

    function buildAssessmentLevelRepeatPatten(assessmentLevelIndex) {
        const testLevelCron = testLevelCrons.find(e => e.levelIndex === assessmentLevelIndex);

        switch (testLevelCron?.repeatSelect) {
            case 'Weekly':
                return (
                    <Select
                        name="repeatPatternWeekDay"
                        onChange={e => handleAssessmentLevelCronInputs(e, assessmentLevelIndex)}
                        value={testLevelCron.repeatPatternWeekDay}
                        placeholder="Day of the week"
                        multiple
                        key="repeatPatternWeekDaySelect"
                        disableUnderline
                        className="dropdownPicker"
                    >
                        <MenuItem value="*">Every Week Day</MenuItem>
                        <MenuItem value="1">Monday</MenuItem>
                        <MenuItem value="2">Tuesday</MenuItem>
                        <MenuItem value="3">Wednesday</MenuItem>
                        <MenuItem value="4">Thursday</MenuItem>
                        <MenuItem value="5">Friday</MenuItem>
                        <MenuItem value="6">Saturday</MenuItem>
                        <MenuItem value="0">Sunday</MenuItem>
                    </Select>
                );
            case 'Monthly':
                return [
                    <Select
                        name="repeatPatternWeekDay"
                        onChange={e => handleAssessmentLevelCronInputs(e, assessmentLevelIndex)}
                        value={testLevelCron.repeatPatternWeekDay}
                        placeholder="Day of the week"
                        multiple
                        key="repeatPatternWeekDaySelect"
                        disableUnderline
                        className="dropdownPicker"
                    >
                        <MenuItem value="*">Every Week Day</MenuItem>
                        <MenuItem value="1">Monday</MenuItem>
                        <MenuItem value="2">Tuesday</MenuItem>
                        <MenuItem value="3">Wednesday</MenuItem>
                        <MenuItem value="4">Thursday</MenuItem>
                        <MenuItem value="5">Friday</MenuItem>
                        <MenuItem value="6">Saturday</MenuItem>
                        <MenuItem value="0">Sunday</MenuItem>
                    </Select>,
                    <Select
                        name="repeatPatternDate"
                        onChange={e => handleAssessmentLevelCronInputs(e, assessmentLevelIndex)}
                        value={testLevelCron.repeatPatternDate}
                        placeholder="Day of the month"
                        multiple
                        key="repeatPatternDateSelect"
                        disableUnderline
                        className="dropdownPicker"
                    >
                        {buildMonthlySelectItems()}
                    </Select>,
                ];
            case 'Yearly':
                return [
                    <Select
                        name="repeatPatternWeekDay"
                        onChange={e => handleAssessmentLevelCronInputs(e, assessmentLevelIndex)}
                        value={testLevelCron.repeatPatternWeekDay}
                        placeholder="Day of the week"
                        multiple
                        key="repeatPatternWeekDaySelect"
                        disableUnderline
                        className="dropdownPicker"
                    >
                        <MenuItem value="*">Every Week Day</MenuItem>
                        <MenuItem value="1">Monday</MenuItem>
                        <MenuItem value="2">Tuesday</MenuItem>
                        <MenuItem value="3">Wednesday</MenuItem>
                        <MenuItem value="4">Thursday</MenuItem>
                        <MenuItem value="5">Friday</MenuItem>
                        <MenuItem value="6">Saturday</MenuItem>
                        <MenuItem value="0">Sunday</MenuItem>
                    </Select>,
                    <Select
                        name="repeatPatternDate"
                        onChange={e => handleAssessmentLevelCronInputs(e, assessmentLevelIndex)}
                        value={testLevelCron.repeatPatternDate}
                        placeholder="Day of the month"
                        multiple
                        key="repeatPatternDateSelect"
                        disableUnderline
                        className="dropdownPicker"
                    >
                        {buildMonthlySelectItems()}
                    </Select>,
                    <Select
                        name="repeatPatternMonth"
                        onChange={e => handleAssessmentLevelCronInputs(e, assessmentLevelIndex)}
                        value={testLevelCron.repeatPatternMonth}
                        placeholder="Months"
                        multiple
                        key="repeatPatternMonthSelect"
                        disableUnderline
                        className="dropdownPicker"
                    >
                        <MenuItem value="*">Every Month</MenuItem>
                        <MenuItem value="1">January</MenuItem>
                        <MenuItem value="2">February</MenuItem>
                        <MenuItem value="3">March</MenuItem>
                        <MenuItem value="4">April</MenuItem>
                        <MenuItem value="5">May</MenuItem>
                        <MenuItem value="6">June</MenuItem>
                        <MenuItem value="7">July</MenuItem>
                        <MenuItem value="8">August</MenuItem>
                        <MenuItem value="9">September</MenuItem>
                        <MenuItem value="10">October</MenuItem>
                        <MenuItem value="11">November</MenuItem>
                        <MenuItem value="12">December</MenuItem>
                    </Select>,
                ];
            case 'Never':
            default:
                return null;
        }
    }

    function buildMonthlySelectItems() {
        const monthlyItems = [
            <MenuItem key={'MonthEveryDay'} value="*">
                Every Day
            </MenuItem>,
        ];
        for (let i = 1; i < 32; i++) {
            monthlyItems.push(
                <MenuItem key={'Month' + i} value={i.toString()}>
                    {i.toString()}
                </MenuItem>
            );
        }
        return monthlyItems;
    }

    function buildCategoryList() {
        const categoryList = [];
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            categoryList.push(
                <MenuItem key={category.id} value={category.id}>
                    {category.name}
                </MenuItem>
            );
        }
        return categoryList;
    }

    function buildRecurrenceArea() {
        return (
            <div className={classes.recurrenceArea}>
                <div>
                    {repeatSelect !== 'Never' ? (
                        <div>
                            <div>Ends:</div>
                            <RadioGroup name="endSelect" value={endSelect} onChange={handleInput} row>
                                <FormControlLabel value="Never" control={<Radio color="primary" />} label="Never" labelPlacement="end" />
                                <FormControlLabel value="On" control={<Radio color="primary" />} label="On" labelPlacement="end" />
                            </RadioGroup>
                        </div>
                    ) : null}
                </div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div>
                        <div className="recurrenceHeaders">Starts On:</div>
                        <DateTimePicker value={startDate} onChange={setStartDate} openTo="year" className="dateTimePicker" InputProps={{ disableUnderline: true }} />
                    </div>
                    <div>
                        <div className="recurrenceHeaders">Ends On:</div>
                        <DateTimePicker value={endDate} onChange={setEndDate} openTo="year" className="dateTimePicker" InputProps={{ disableUnderline: true }} />
                    </div>
                </MuiPickersUtilsProvider>
            </div>
        );
    }

    function buildPageOne() {
        // ships
        const totalSelectedShips = shipSelections.filter(e => e.selected).length;
        const totalUnselectedShips = shipSelections.filter(e => !e.selected).length;
        const selectedShips = shipSelections.filter(filterShip).filter(e => e.selected);
        const unselectedShips = shipSelections.filter(filterShip).filter(e => !e.selected);

        return (
            <>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Ships</b>
                            </Typography>
                            <Typography variant="body2">Select one or more ships that this assessment will be made available to</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="filled" label="Search" onChange={handleInput} value={shipSearch} name="shipSearch" margin="dense" fullWidth />
                            <ComplianceSelector
                                leftTitle="All Ships"
                                leftSubtitle={`Showing ${unselectedShips.length} out of ${totalUnselectedShips}`}
                                leftChildren={unselectedShips.map(e => buildSimpleSelection(e, handleToggleCheckedShip))}
                                rightTitle="Included in Assessment"
                                rightSubtitle={`Showing ${selectedShips.length} out of ${totalSelectedShips}`}
                                rightChildren={selectedShips.map(e => buildSimpleSelection(e, handleToggleCheckedShip))}
                                onSelectAllLeft={(_, val) => handleCheckAllUnselectedShips(val)}
                                onSelectAllRight={(_, val) => handleCheckAllSelectedShips(val)}
                                onMoveLeft={() => handleSelectShips(false)}
                                onMoveRight={() => handleSelectShips(true)}
                                emptyRightMessage="No ships assigned, this assessment will not be available to anyone"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={() => setStep(1)}>
                        Next
                    </Button>
                </DialogActions>
            </>
        );
    }

    function buildPageTwo() {
        const totalSelected = selectedQuestions.length;
        const unselectedQuestions = questionSelections.filter(
            e =>
                !e.selected &&
                (e.ships.length === 0 ||
                    e.ships.some(e =>
                        shipSelections
                            .filter(e => e.selected)
                            .map(s => s.id)
                            .includes(e.id)
                    ))
        );
        const selectedItems = selectedQuestions
            .sort((a, b) => parseInt(a.id) - parseInt(b.id))
            .sort((a, b) => a.isArchived + -b.isArchived)
            .filter(filterQuestion);
        const unselectedItems = unselectedQuestions
            .sort((a, b) => parseInt(a.id) - parseInt(b.id))
            .sort((a, b) => a.isArchived + -b.isArchived)
            .filter(filterQuestion);
        const onSubmit = e => {
            e.preventDefault();
            if (totalSelected !== 0) {
                setStep(2);
            }
        };
        return (
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Questions</b>
                            </Typography>
                            <Typography variant="body2">
                                Use the search box to filter results in the left and right hand panes of the below configurator. Results in the left &apos;ALL QUESTIONS&apos; pane ARE NOT included in
                                this assessment. Results in the right &apos;INCLUDED IN ASSESSMENT&apos; pane ARE included in this assessment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="filled" label="Search by Question or Category" onChange={handleInput} value={questionSearch} name="questionSearch" fullWidth />
                            <ComplianceSelector
                                leftTitle="All Questions"
                                leftSubtitle={`Showing ${unselectedItems.length} out of ${unselectedQuestions.length}`}
                                leftChildren={unselectedItems.map((e, i) => buildQuestionSelection(e, i))}
                                rightTitle="Included in Assessment"
                                rightSubtitle={`Showing ${selectedItems.length} out of ${totalSelected}`}
                                rightChildren={selectedItems.map((e, i) => buildQuestionSelection(e, i, true))}
                                onSelectAllLeft={(_, val) => handleCheckAllUnselectedQuestions(val)}
                                onSelectAllRight={(_, val) => handleCheckAllSelectedQuestions(val)}
                                onMoveLeft={() => handleSelectQuestions(false)}
                                onMoveRight={() => handleSelectQuestions(true)}
                                emptyRightMessage="You must assign at least one question to this assessment to continue."
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setStep(0)}>
                        Back
                    </Button>
                    <Button color="primary" disabled={totalSelected === 0} type="submit">
                        Next
                    </Button>
                </DialogActions>
            </form>
        );
    }

    function buildPageThree() {
        // users
        const shipOnlyUsers = userSelections.filter(e =>
            e.ships.some(e =>
                shipSelections
                    .filter(e => e.selected)
                    .map(s => s.id)
                    .includes(e.shipId)
            )
        );
        const totalSelectedUsers = shipOnlyUsers.filter(e => e.selected).length;
        const totalUnselectedUsers = shipOnlyUsers.filter(e => !e.selected).length;
        const selectedUsers = shipOnlyUsers.filter(filterUser).filter(e => e.selected);
        const unselectedUsers = shipOnlyUsers.filter(filterUser).filter(e => !e.selected);

        // user groups
        const totalSelectedUserGroups = userGroupSelections.filter(e => e.selected).length;
        const totalUnselectedUserGroups = userGroupSelections.filter(e => !e.selected).length;
        const selectedUserGroups = userGroupSelections.filter(filterUserGroup).filter(e => e.selected);
        const unselectedUserGroups = userGroupSelections.filter(filterUserGroup).filter(e => !e.selected);

        // availability message
        let userGroupMsg = 'No users are eligible for this assessment.';
        let usersMsg;
        if (shipOnlyUsers.length > 0) {
            if (selectedUserGroups.length !== 0) {
                userGroupMsg = 'This assessment is available to all users across the following User Groups: ' + selectedUserGroups.map(e => e.name).join(', ') + '.';
            } else {
                userGroupMsg = 'This assessment is available to all users on the selected Ship(s).';
            }
            if (selectedUsers.length !== 0) {
                usersMsg = 'This assessment is assigned to ' + selectedUsers.map(e => e.firstName + ' ' + e.lastName).join(', ') + ', even if they do not belong to a selected User Group.';
            }
        }

        return (
            <>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Users, Groups &amp; Ships</b>
                            </Typography>
                            <Typography variant="body2">
                                Any user directly added to this assessment or by association (through a ship or user group) will have access to the assessment and all associated questions and files.
                                The user may revise the content of the assessment at any time through their companion mobile application.
                            </Typography>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Users</b>
                            </Typography>
                            <TextField variant="filled" label="Search" onChange={handleInput} value={userSearch} name="userSearch" margin="dense" fullWidth />
                            <ComplianceSelector
                                leftTitle="All Users"
                                leftSubtitle={`Showing ${unselectedUsers.length} out of ${totalUnselectedUsers}`}
                                leftChildren={unselectedUsers.map(e => buildSimpleSelection({ ...e, name: e.firstName + ' ' + e.lastName }, handleToggleCheckedUser))}
                                rightTitle="Included in Assessment"
                                rightSubtitle={`Showing ${selectedUsers.length} out of ${totalSelectedUsers}`}
                                rightChildren={selectedUsers.map(e => buildSimpleSelection({ ...e, name: e.firstName + ' ' + e.lastName }, handleToggleCheckedUser))}
                                onSelectAllLeft={(_, val) => handleCheckAllUnselectedUsers(val)}
                                onSelectAllRight={(_, val) => handleCheckAllSelectedUsers(val)}
                                onMoveLeft={() => handleSelectUsers(false)}
                                onMoveRight={() => handleSelectUsers(true)}
                                emptyRightMessage="Assign this assessment to specific crew members"
                            />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>User Groups</b>
                            </Typography>
                            <TextField variant="filled" label="Search" onChange={handleInput} value={groupSearch} name="groupSearch" margin="dense" fullWidth />
                            <ComplianceSelector
                                leftTitle="All Groups"
                                leftSubtitle={`Showing ${unselectedUserGroups.length} out of ${totalUnselectedUserGroups}`}
                                leftChildren={unselectedUserGroups.map(e => buildSimpleSelection(e, handleToggleCheckedUserGroup))}
                                rightTitle="Included in Assessment"
                                rightSubtitle={`Showing ${selectedUserGroups.length} out of ${totalSelectedUserGroups}`}
                                rightChildren={selectedUserGroups.map(e => buildSimpleSelection(e, handleToggleCheckedUserGroup))}
                                onSelectAllLeft={(_, val) => handleCheckAllUnselectedUserGroups(val)}
                                onSelectAllRight={(_, val) => handleCheckAllSelectedUserGroups(val)}
                                onMoveLeft={() => handleSelectUserGroups(false)}
                                onMoveRight={() => handleSelectUserGroups(true)}
                                emptyRightMessage="Assign this assessment to all crew members in a specific user group"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <b>{userGroupMsg}</b>
                            </Typography>
                        </Grid>
                        {usersMsg ? (
                            <Grid item xs={12}>
                                <Typography>
                                    <b>{usersMsg}</b>
                                </Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setStep(1)}>
                        Back
                    </Button>
                    <Button color="primary" onClick={() => setStep(3)}>
                        Next
                    </Button>
                </DialogActions>
            </>
        );
    }

    function buildPageFour() {
        const isError = complianceTestLevels.some(level => level?.numQuestions > selectedQuestions.length || level?.numQuestions < 0 || level?.scoreRequirement > 100 || level?.scoreRequirement < 0);
        return (
            <>
                <DialogContent>
                    <Typography variant="h6" className={classes.title}>
                        <b>Edit Assessment Levels</b>
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: 12 }}>
                        Assessment level description...
                    </Typography>
                    <Grid container style={{ marginBottom: 12, backgroundColor: '#fff' }}>
                        <Grid item xs={12} style={{ marginBottom: 16 }}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Interval</b>
                            </Typography>
                            <FormControlLabel
                                className={classes.mandatoryCheckbox}
                                control={<Checkbox color="primary" checked={mandatory} onChange={(_, v) => setMandatory(v)} />}
                                label="Do you want this assessment to be made mandatory for completion on an interval? "
                                labelPlacement="start"
                            />
                            <Typography variant="caption" component="div">
                                (If not enabled the assigned crew members will still have access to this assessment but will not be forced to complete it by the system)
                            </Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid container item xs={10}>
                            <Grid item xs={mandatory ? 3 : 4}>
                                <Typography className={classes.title}>No. Questions</Typography>
                            </Grid>
                            <Grid item xs={mandatory ? 3 : 4}>
                                <Typography className={classes.title}>Assessments to score</Typography>
                            </Grid>
                            <Grid item xs={mandatory ? 3 : 4}>
                                <Typography className={classes.title}>Score %</Typography>
                            </Grid>
                            {mandatory ? (
                                <Grid item xs={3}>
                                    <Typography className={classes.title}>Frequency</Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.levelContainer}>
                        {complianceTestLevels.sort((a, b) => a.levelIndex - b.levelIndex).map(e => buildComplianceTestLevelEditor(e, mandatory))}
                    </Grid>
                    <div>
                        <Typography variant="caption" style={{ color: 'red' }}>
                            {errorText}
                        </Typography>
                    </div>
                    <IconButton onClick={handleAddNewAssessmentLevel}>
                        <AddIcon></AddIcon>
                    </IconButton>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setStep(2)}>
                        Back
                    </Button>
                    <Button color="primary" disabled={isNullOrWhitespace(questionsToAsk) || isError} onClick={() => setStep(4)}>
                        Next
                    </Button>
                </DialogActions>
            </>
        );
    }

    function buildComplianceTestLevelEditor(assessmentLevel, mandatory) {
        const isBaseline = assessmentLevel?.levelIndex === 0;
        const isInvalidQuestionCount = assessmentLevel?.numQuestions > selectedQuestions.length || assessmentLevel?.numQuestions === 0;
        const isInvalidScorePercent = assessmentLevel?.scoreRequirement > 100 || assessmentLevel?.scoreRequirement < 0;

        return (
            <Grid container item xs={12} key={assessmentLevel.levelIndex}>
                <Grid item xs={1}>
                    {!isBaseline ? (
                        <IconButton onClick={() => handleDeleteAssessmentLevel(assessmentLevel?.levelIndex)}>
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    ) : null}
                    <div className={classes.iconWrapper}>
                        {!isBaseline ? <ArrowDropUpIcon onClick={() => handleChangeAssessmentLevelPosition(assessmentLevel?.levelIndex, -1)}></ArrowDropUpIcon> : null}
                        {complianceTestLevels.length - 1 !== assessmentLevel?.levelIndex ? (
                            <ArrowDropDownIcon onClick={() => handleChangeAssessmentLevelPosition(assessmentLevel?.levelIndex, 1)}></ArrowDropDownIcon>
                        ) : null}
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="h6" className={classes.title}>
                        <b>Level {assessmentLevel.levelIndex}</b>
                    </Typography>
                </Grid>
                <Grid container item xs={10} spacing={2}>
                    <Grid item xs={mandatory ? 3 : 4}>
                        <TextField
                            variant="outlined"
                            onChange={e => {
                                handleUpdateNewComplianceTestLevels(e, assessmentLevel?.levelIndex);
                                if (isBaseline) {
                                    handleInput(e);
                                }
                            }}
                            value={assessmentLevel?.numQuestions}
                            name="numQuestions"
                            type="number"
                            required
                            fullWidth
                            size="small"
                            error={isInvalidQuestionCount}
                        />
                    </Grid>
                    <Grid item xs={mandatory ? 3 : 4}>
                        <TextField
                            variant="outlined"
                            onChange={e => handleUpdateNewComplianceTestLevels(e, assessmentLevel?.levelIndex)}
                            value={assessmentLevel?.numPrevTestsToAccount}
                            name="numPrevTestsToAccount"
                            type="number"
                            required
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={mandatory ? 3 : 4}>
                        <TextField
                            variant="outlined"
                            onChange={e => handleUpdateNewComplianceTestLevels(e, assessmentLevel?.levelIndex)}
                            value={assessmentLevel?.scoreRequirement}
                            name="scoreRequirement"
                            type="number"
                            required
                            fullWidth
                            size="small"
                            error={isInvalidScorePercent}
                        />
                    </Grid>
                    {mandatory ? (
                        <Grid item xs={3}>
                            <div>
                                <span className="repeatHeader">{'Repeat  '}</span>
                                <Select
                                    name="repeatSelect"
                                    onChange={e => handleAssessmentLevelCronInputs(e, assessmentLevel?.levelIndex)}
                                    value={testLevelCrons?.find(e => e.levelIndex === assessmentLevel?.levelIndex)?.repeatSelect}
                                    className="dropdownPicker"
                                    disableUnderline
                                >
                                    <MenuItem value="Weekly">Weekly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                </Select>
                                <span className="repeatHeader">{' on   '}</span>
                                {buildAssessmentLevelRepeatPatten(assessmentLevel?.levelIndex)}
                            </div>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        );
    }

    function buildPageFive() {
        return (
            <form onSubmit={handleSave}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Assessment Name</b>
                            </Typography>
                            <TextField variant="filled" label="Name" onChange={handleInput} value={name} name="name" margin="dense" fullWidth required />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Icon</b>
                            </Typography>
                            <Select name="iconIndex" onChange={handleInput} value={iconIndex} key="iconIndex" disableUnderline className="dropdownPicker">
                                <MenuItem value="0">{getCustomIcon(0)}</MenuItem>
                                <MenuItem value="1">{getCustomIcon(1)}</MenuItem>
                                <MenuItem value="2">{getCustomIcon(2)}</MenuItem>
                                <MenuItem value="3">{getCustomIcon(3)}</MenuItem>
                                <MenuItem value="4">{getCustomIcon(4)}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={classes.title}>
                                <b>Category</b>
                            </Typography>
                            <Select name="category" onChange={handleInput} value={categoryId} key="category" disableUnderline className="dropdownPicker">
                                {buildCategoryList()}
                            </Select>
                        </Grid>
                        {buildRecurrenceArea()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setStep(3)}>
                        Back
                    </Button>
                    <Button color="primary" disabled={saving} type="submit">
                        Save Assessment
                    </Button>
                </DialogActions>
            </form>
        );
    }

    return (
        <>
            <Dialog open={open} fullScreen={matches} maxWidth={step === 3 ? 'lg' : step < 3 ? 'lg' : 'md'} fullWidth>
                <DialogTitle>Add/Edit Assessment</DialogTitle>
                {loading ? (
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                    </div>
                ) : (
                    buildDialogContent()
                )}
            </Dialog>

            {buildErrorDialog()}
        </>
    );
}

ComplianceAddEditAssessment.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    questionsArray: PropTypes.array.isRequired,
    usersArray: PropTypes.array.isRequired,
    userGroupsArray: PropTypes.array.isRequired,
    shipsArray: PropTypes.array.isRequired,
    assessment: PropTypes.object,
    categories: PropTypes.array,
};
