import React from 'react';
import PropTypes from 'prop-types';
import QuestionController from '../../controllers/QuestionController';
import CompanyController from '../../controllers/CompanyController';
import UserGroupController from '../../controllers/UserGroupController';
import ShipController from '../../controllers/ShipController';
import ComplianceController from '../../controllers/ComplianceController';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Add } from '@material-ui/icons';
import { isNullOrUndefined, isNullOrWhitespace } from '../../helpers/Utils';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { ComplianceAddEditQuestion } from './ComplianceAddEditQuestion';
import { ComplianceAddEditAssessment } from './ComplianceAddEditAssessment';
import { ComplianceAssessmentCard } from './ComplianceAssessmentCard';
import { ComplianceQuestionCard } from './ComplianceQuestionCard';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { DarkMenuItem } from '../../components/Common/DarkMenuItem';
import { Alert } from '../../components/Common/Alert';
import { CompanyRole, Role, ShipRole } from '../../helpers/Constants';
import { clearUploadUrls, readUploadUrls } from '../../helpers/UploadReader';
import { Autocomplete, Pagination } from '@material-ui/lab';
import QuestionTagExport from './QuestionTagExport';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '16px 32px',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    },
    topArea: {
        paddingBottom: 8,
        minHeight: 88,
        '& .MuiButtonBase-root': {
            height: '100%',
        },
    },
    leftArea: {
        minWidth: '55%',
    },
    rightArea: {
        minWidth: '45%',
        marginLeft: 16,
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
            marginTop: 16,
        },
    },
    searchWrapper: {
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 200px)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    categoryWrapper: {
        maxWidth: 200,
        width: 200,
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            maxWidth: 'unset',
            width: '100%',
        },
    },
    shipSelectArea: {
        padding: '16px 32px',
    },
}));

const MAX_PER_PAGE = 20;

function QuestionsPaneRender(props) {
    const {
        questions,
        questionCategory,
        questionSearch,
        questionCategories,
        questionShip,
        Auth,
        warning,
        setArchiveQuestionRef,
        setAddEditQuestionRef,
        setShowExcelModal,
        setShowQuestionExport,
        setRedirectUrl,
        handleInput,
        handelDelete,
        page,
        onChangePage,
    } = props;
    const classes = useStyles();

    const sortedQuestions = questions
        .filter(
            e =>
                (e.title + 'q' + e.id).toLowerCase().includes(questionSearch.toLowerCase()) &&
                (isNullOrWhitespace(questionCategory) || e.category.some(c => c.name.includes(questionCategory))) &&
                (isNullOrWhitespace(questionShip) || e.ships.some(c => c.id === questionShip))
        )
        .sort((a, b) => (a.isArchived + -b.isArchived === 0 ? parseInt(a.id) - parseInt(b.id) : a.isArchived + -b.isArchived));

    const totalPages = Math.ceil(sortedQuestions.length / MAX_PER_PAGE);

    function buildQuestionsTopArea() {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2} className={classes.topArea}>
                    <Grid item xs="auto">
                        <Button variant="contained" color="primary" disabled={Auth.companyRole !== CompanyRole.Owner} startIcon={<Add />} onClick={() => setAddEditQuestionRef({})}>
                            Add Question
                        </Button>
                    </Grid>
                    <Grid item xs="auto" className={classes.grower}>
                        <Button variant="contained" color="primary" onClick={() => setShowExcelModal(true)}>
                            Upload Question TSV
                        </Button>
                    </Grid>
                    <Grid item xs="auto" className={classes.grower}>
                        <Button variant="contained" color="primary" onClick={() => setRedirectUrl('/ComplianceCategory')}>
                            Edit Categories
                        </Button>
                    </Grid>
                    <Grid item xs="auto" className={classes.grower}>
                        <Button variant="contained" color="primary" onClick={() => setShowQuestionExport(true)}>
                            Bulk export Tags
                        </Button>
                    </Grid>
                    <Grid item xs="auto" className={classes.grower}>
                        <Button variant="contained" color="primary" onClick={() => setRedirectUrl('/ComplianceAllQuestions')}>
                            Plain Text Questions
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.topArea}>
                        <Grid item className={classes.searchWrapper} xs="auto">
                            <TextField label="Search by Question" variant="filled" value={questionSearch} onChange={handleInput} name="questionSearch" fullWidth />
                        </Grid>
                        <Grid item xs={2} className={classes.categoryWrapper}>
                            <FormControl fullWidth variant="filled">
                                <InputLabel id="question-category-label">Category</InputLabel>
                                <Select labelId="question-category-label" value={questionCategory} onChange={handleInput} name="questionCategory" variant="filled" fullWidth>
                                    {questionCategories.map(e => (
                                        <DarkMenuItem key={e.id} value={e.name}>
                                            {e.name}
                                        </DarkMenuItem>
                                    ))}
                                    <DarkMenuItem key="none" value="">
                                        <em>None Selected</em>
                                    </DarkMenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert header="Something went wrong!" text={warning} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    function buildQuestionItem(question) {
        const { id, title, category, isArchived } = question;
        return (
            <Grid item key={id} xs={12}>
                <ComplianceQuestionCard categories={category} id={id} title={title} isArchived={isArchived}>
                    {Auth.companyRole === CompanyRole.Owner ? (
                        !isArchived ? (
                            <>
                                <Button onClick={() => setArchiveQuestionRef(question)}>Archive</Button>
                                <Button onClick={() => setAddEditQuestionRef(question)}>Edit</Button>
                            </>
                        ) : (
                            <Button onClick={() => handelDelete(id)}>Delete</Button>
                        )
                    ) : null}
                </ComplianceQuestionCard>
            </Grid>
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    Questions
                </Typography>
            </Grid>
            {buildQuestionsTopArea()}
            {sortedQuestions.filter((_, i) => i < (page + 1) * MAX_PER_PAGE && i >= page * MAX_PER_PAGE).map(buildQuestionItem)}
            <Grid item xs={12}>
                <Pagination count={totalPages} page={page + 1} onChange={(_, i) => onChangePage(i - 1)} />
            </Grid>
            <Grid item xs={12}></Grid>
        </Grid>
    );
}

const QuestionsPane = React.memo(QuestionsPaneRender, function areEqual(prevProps, currentProps) {
    return (
        prevProps.questions === currentProps.questions &&
        prevProps.questionCategory === currentProps.questionCategory &&
        prevProps.questionSearch === currentProps.questionSearch &&
        prevProps.questionCategories === currentProps.questionCategories &&
        prevProps.questionShip === currentProps.questionShip &&
        prevProps.ships === currentProps.ships &&
        prevProps.Auth === currentProps.Auth &&
        prevProps.warning === currentProps.warning &&
        prevProps.page === currentProps.page
    );
});
QuestionsPaneRender.propTypes = {
    questions: PropTypes.array,
    questionSearch: PropTypes.string,
    questionCategory: PropTypes.string,
    questionCategories: PropTypes.array,
    questionShip: PropTypes.string,
    ships: PropTypes.array,
    Auth: PropTypes.object,
    warning: PropTypes.string,
    setArchiveQuestionRef: PropTypes.func,
    setAddEditQuestionRef: PropTypes.func,
    setShowQuestionExport: PropTypes.func,
    setShowExcelModal: PropTypes.func,
    handelDelete: PropTypes.func,
    setRedirectUrl: PropTypes.func,
    handleInput: PropTypes.func,
    page: PropTypes.number,
    onChangePage: PropTypes.func,
};

function Compliance(props) {
    const { Auth, PushHistory } = props;
    const [selectedShip, setSelectedShip] = React.useState(null);
    const [questionSearch, setQuestionSearch] = React.useState('');
    const [questionCategory, setQuestionCategory] = React.useState('');
    const [questionPage, setQuestionPage] = React.useState(0);
    const [assessmentSearch, setAssessmentSearch] = React.useState('');
    const [assessmentCategory, setAssessmentCategory] = React.useState('');
    const [assessmentPage, setAssessmentPage] = React.useState(0);
    const [addEditQuestionRef, setAddEditQuestionRef] = React.useState(null);
    const [archiveQuestionRef, setArchvieQuestionRef] = React.useState(null);
    const [addEditAssessmentRef, setAddEditAssessmentRef] = React.useState(null);
    const [duplicateAssessmentRef, setDuplicateAssessmentRef] = React.useState(null);
    const [questionCategories, setQuestionCategories] = React.useState([]);
    const [questions, setQuestions] = React.useState([]);
    const [assessments, setAssessments] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [userGroups, setUserGroups] = React.useState([]);
    const [ships, setShips] = React.useState([]);
    const [excelFile, setExcelFile] = React.useState('');
    const [selectedExcelShips, setSelectedExcelShips] = React.useState([]);
    const [showExcelModal, setShowExcelModal] = React.useState(false);
    const [showQuestionExport, setShowQuestionExport] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [, updateState] = React.useState();
    const classes = useStyles();

    const selectedShipId = selectedShip === 'opt-all-ships' ? '' : selectedShip;

    const sortedAssessments = assessments
        .filter(
            e =>
                e.title.toLowerCase().includes(assessmentSearch.toLowerCase()) &&
                (isNullOrWhitespace(assessmentCategory) || e.linkedCatagories.filter(c => c.includes(assessmentCategory)).length > 0) &&
                (isNullOrWhitespace(selectedShipId) || e.linkedShips.some(r => r.includes(selectedShipId)))
        )
        .sort((a, b) => a.isArchived + -b.isArchived);

    const totalAssessmentPages = Math.ceil(sortedAssessments.length / MAX_PER_PAGE);

    const forceUpdate = React.useCallback(() => {
        updateState({});
    }, []);

    const loadQuestionCategories = React.useCallback(async companyId => {
        const response = await QuestionController.getCategories(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setQuestionCategories(response.data);
        }
    }, []);

    const loadQuestions = React.useCallback(async companyId => {
        const response = await QuestionController.getQuestions(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setQuestions(response.data);
        }
    }, []);

    const loadUsers = React.useCallback(async companyId => {
        const response = await CompanyController.getCompanyUsers(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setUsers(response.data);
        }
    }, []);

    const loadUserGroups = React.useCallback(async companyId => {
        const response = await UserGroupController.getUserGroups(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setUserGroups(response.data);
        }
    }, []);

    const loadShips = React.useCallback(async companyId => {
        const response = await ShipController.getShips(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setShips(response.data.filter(e => e.companyId === companyId));
        }
    }, []);

    const loadAssessments = React.useCallback(async companyId => {
        const testsResponse = await ComplianceController.getComplianceTests(companyId);
        if (testsResponse.hasError) {
            setWarning(testsResponse.data);
        } else {
            const detailResponse = await ComplianceController.getComplianceTestsById(
                companyId,
                testsResponse.data.map(e => e.id)
            );
            if (detailResponse.hasError) {
                setWarning(detailResponse.data);
            } else {
                for (let i = 0; i < testsResponse.data.length; i++) {
                    const assessmentDetail = detailResponse.data.find(e => e.id == testsResponse.data[i].id);
                    testsResponse.data[i] = { ...testsResponse.data[i], ...assessmentDetail };
                }
                setAssessments(testsResponse.data);
            }
        }
    }, []);

    const loadCategories = React.useCallback(async companyId => {
        const response = await ComplianceController.getComplianceTestCategories(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setCategories(response.data);
        }
    }, []);

    // initialise
    React.useEffect(() => {
        async function init() {
            const { companyId, isAuthenticated, isLoggingIn } = Auth;
            if (!isAuthenticated && !isLoggingIn) {
                setRedirectUrl('/Login');
                return;
            }
            await Promise.all([
                loadQuestionCategories(companyId),
                loadQuestions(companyId),
                loadUsers(companyId),
                loadUserGroups(companyId),
                loadShips(companyId),
                loadAssessments(companyId),
                loadCategories(companyId),
            ]);
        }
        setLoading(true);
        init().then(() => setLoading(false));
    }, [Auth, loadQuestionCategories, loadQuestions, loadUsers, loadUserGroups, loadShips, loadAssessments]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    function handleInput(event) {
        const { name, value, files } = event.target;
        switch (name) {
            case 'questionSearch':
                setQuestionSearch(value);
                setQuestionPage(0);
                break;
            case 'questionCategory':
                setQuestionCategory(value);
                setQuestionPage(0);
                break;
            case 'assessmentSearch':
                setAssessmentSearch(value);
                setAssessmentPage(0);
                break;
            case 'assessmentCategory':
                setAssessmentCategory(value);
                setAssessmentPage(0);
                break;
            case 'excelFile':
                clearUploadUrls();
                if (!isNullOrUndefined(files[0])) {
                    setExcelFile(files[0]);
                    readUploadUrls([files[0]], forceUpdate);
                } else {
                    setExcelFile('');
                }
                break;
            default:
                break;
        }
    }

    async function handleAddQuestion(question) {
        await loadQuestionCategories(Auth.companyId);
        if (questions.filter(e => e.id === question.id).length > 0) {
            setQuestions(questions.map(e => (e.id === question.id ? question : e)));
        } else {
            setQuestions([...questions, question]);
        }
    }

    async function handleArchiveQuestion(tryDeletion = false) {
        setLoading(true);
        const { id } = archiveQuestionRef;
        const response = await QuestionController.archiveQuestion(Auth.companyId, id);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setQuestions(questions.map(e => (e.id === id ? { ...e, isArchived: true } : e)));
        }
        if (tryDeletion) {
            return await handelDelete();
        }

        setArchvieQuestionRef(null);
        setLoading(false);
    }

    async function handelDelete(idOveride) {
        setLoading(true);
        let id = idOveride;
        if (!isNullOrUndefined(archiveQuestionRef)) {
            id = archiveQuestionRef.id;
        }
        const response = await QuestionController.deleteQuestion(Auth.companyId, id);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setQuestions(questions.filter(e => e.id !== id));
            setArchvieQuestionRef(null);
        }
        setLoading(false);
    }

    function handleAddEditAssessment(newAssessment) {
        if (assessments.filter(e => e.id === newAssessment.id).length > 0) {
            setAssessments(assessments.map(e => (e.id === newAssessment.id ? newAssessment : e)));
        } else {
            setAssessments([...assessments, newAssessment]);
        }
    }

    async function handleDuplicateAssessment() {
        setLoading(true);
        const { id } = duplicateAssessmentRef;
        setDuplicateAssessmentRef(null);

        const assessmentResponse = await ComplianceController.getComplianceTest(Auth.companyId, id);
        if (assessmentResponse.hasError) {
            setWarning(assessmentResponse.data);
            setLoading(false);
            return;
        }

        const { title, cronScheduling, start, end, allQuestions, questionCount, questions, ships, users, userGroups, categoryId, iconIndex } = assessmentResponse.data;
        const addResponse = await ComplianceController.addComplianceTest(
            Auth.companyId,
            title,
            iconIndex,
            categoryId,
            cronScheduling,
            start,
            end,
            allQuestions,
            questionCount,
            questions.map(e => ({ id: e.id, frequency: e.frequency })),
            ships.map(e => e.id),
            users.map(e => e.id),
            userGroups.map(e => e.id)
        );
        if (addResponse.hasError) {
            setWarning(addResponse.data);
        } else {
            setAssessments([...assessments, assessmentResponse.data]);
        }

        setLoading(false);
    }

    async function handleEditAssessment(id) {
        setLoading(true);
        const assessmentResponse = await ComplianceController.getComplianceTest(Auth.companyId, id);
        if (assessmentResponse.hasError) {
            setWarning(assessmentResponse.data);
        } else {
            setAddEditAssessmentRef(assessmentResponse.data);
        }
        setLoading(false);
    }

    async function handleImportExcel() {
        setLoading(true);
        setShowExcelModal(false);
        const response = await QuestionController.importFromExcel(Auth.companyId, excelFile, selectedExcelShips);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            const { companyId } = Auth;

            await loadQuestions(companyId);
            await loadQuestionCategories(companyId);
        }
        handleCloseExcelDialog();
        setLoading(false);
    }

    function handleCloseExcelDialog() {
        clearUploadUrls();
        setExcelFile('');
        setShowExcelModal(false);
        setSelectedExcelShips([]);
    }

    function buildAssessmentPane() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Assessments
                    </Typography>
                </Grid>
                {buildAssessmentTopArea()}
                {sortedAssessments.filter((_, i) => i < (assessmentPage + 1) * MAX_PER_PAGE && i >= assessmentPage * MAX_PER_PAGE).map(buildAssessmentItem)}
                <Grid item xs={12}>
                    <Pagination count={totalAssessmentPages} page={assessmentPage + 1} onChange={(_, i) => setAssessmentPage(i - 1)} />
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        );
    }

    function buildAssessmentTopArea() {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2} className={classes.topArea}>
                    <Grid item lg="auto" md={12} sm={12} xs={12}>
                        <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setAddEditAssessmentRef({})}>
                            Add Assessment
                        </Button>
                    </Grid>
                    <Grid item className={classes.searchWrapper}>
                        <TextField label="Search by Assessment" variant="filled" value={assessmentSearch} onChange={handleInput} name="assessmentSearch" fullWidth />
                    </Grid>
                    <Grid item xs={4} className={classes.categoryWrapper}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel id="question-category-label">Category</InputLabel>
                            <Select labelId="question-category-label" value={assessmentCategory} onChange={handleInput} name="assessmentCategory" variant="filled" fullWidth>
                                {questionCategories.map(e => (
                                    <DarkMenuItem key={e.id} value={e.name}>
                                        {e.name}
                                    </DarkMenuItem>
                                ))}
                                <DarkMenuItem key="none" value="">
                                    <em>None Selected</em>
                                </DarkMenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            </Grid>
        );
    }

    function buildAssessmentItem(assessment) {
        const { id, title, linkedCatagories, questionCount, addedUserId, users, ships, cronScheduling, start, end } = assessment;
        const canEdit = Auth.role.includes(Role.Admin) || (Auth.shipRoles.includes(ShipRole.Captain) && addedUserId === Auth.id) || Auth.companyRole === CompanyRole.Owner;
        return (
            <Grid item key={id} md={6} sm={12} xs={12}>
                <ComplianceAssessmentCard
                    id={id}
                    title={title}
                    categories={linkedCatagories}
                    totalQuestions={questionCount}
                    userCount={users.length}
                    shipCount={ships.length}
                    cron={cronScheduling}
                    start={start}
                    end={end}
                >
                    <Button onClick={() => setDuplicateAssessmentRef(assessment)}>Duplicate</Button>
                    {canEdit ? <Button onClick={() => handleEditAssessment(id)}>Edit</Button> : null}
                </ComplianceAssessmentCard>
            </Grid>
        );
    }

    function buildArchiveQuestionDialog() {
        return (
            <Dialog open={!isNullOrUndefined(archiveQuestionRef)} onClose={() => setArchvieQuestionRef(null)}>
                <DialogTitle>Archive Q{archiveQuestionRef?.id}</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleArchiveQuestion}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => handleArchiveQuestion(true)}>
                        Yes and try to delete
                    </Button>
                    <Button color="primary" onClick={() => setArchvieQuestionRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildDuplicateAssessmentDialog() {
        return (
            <Dialog open={!isNullOrUndefined(duplicateAssessmentRef)} onClose={() => setDuplicateAssessmentRef(null)}>
                <DialogTitle>Duplicate {duplicateAssessmentRef?.title}</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleDuplicateAssessment}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => setDuplicateAssessmentRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildExcelDialog() {
        return (
            <Dialog open={showExcelModal} onClose={() => handleCloseExcelDialog()}>
                <DialogTitle>Upload Question TSV</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button href="/tsv/QuestionTSV.xlsx" target="_blank" rel="noopener noreferrer" fullWidth>
                                Download Example
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="filled">
                                <InputLabel shrink>Questions TSV</InputLabel>
                                <FilledInput
                                    type="file"
                                    name="excelFile"
                                    onChange={handleInput}
                                    inputProps={{
                                        accept: '.txt',
                                    }}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                value={selectedExcelShips}
                                onChange={(_, v) => setSelectedExcelShips(v)}
                                className={classes.selectArea}
                                options={ships.sort((a, b) => -b.name[0].toUpperCase().localeCompare(a.name[0].toUpperCase())).map(e => e.id)}
                                groupBy={item => ships.find(e => e.id === item).name[0].toUpperCase()}
                                getOptionLabel={item => ships.find(e => e.id === item).name}
                                renderInput={params => <TextField {...params} label="Linked Ships" variant="filled" InputLabelProps={{ shrink: true }} />}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => {
                                        const name = ships.find(e => e.id === option).name;
                                        return <Chip key={index} color="primary" label={name} {...getTagProps({ index })} />;
                                    })
                                }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleCloseExcelDialog()}>
                        Cancel
                    </Button>
                    <Button color="primary" disabled={isNullOrUndefined(excelFile)} onClick={() => handleImportExcel()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div>
            <LoadingOverlay loading={loading} />

            <Box className={classes.shipSelectArea}>
                <FormControl fullWidth variant="filled">
                    <InputLabel id="question-ship-label">Selected Ship</InputLabel>
                    <Select labelId="question-ship-label" value={selectedShip} onChange={event => setSelectedShip(event.target.value)} name="questionShip" variant="filled" fullWidth>
                        {ships.map(e => (
                            <DarkMenuItem key={e.id} value={e.id}>
                                {e.name}
                            </DarkMenuItem>
                        ))}
                        <DarkMenuItem key="all-ships" value="opt-all-ships">
                            All Ships
                        </DarkMenuItem>
                    </Select>
                    {selectedShip === null ? <FormHelperText error={true}>You must select a ship before adding new compliance data</FormHelperText> : null}
                </FormControl>
            </Box>

            <Divider />

            {selectedShip !== null ? (
                <div className={classes.wrapper}>
                    <div className={classes.leftArea}>
                        <QuestionsPane
                            Auth={Auth}
                            handleInput={handleInput}
                            questionCategories={questionCategories}
                            questionCategory={questionCategory}
                            questionSearch={questionSearch}
                            questionShip={selectedShipId}
                            ships={ships}
                            questions={questions}
                            setAddEditQuestionRef={setAddEditQuestionRef}
                            setArchiveQuestionRef={setArchvieQuestionRef}
                            setRedirectUrl={setRedirectUrl}
                            setShowExcelModal={setShowExcelModal}
                            setShowQuestionExport={setShowQuestionExport}
                            handelDelete={handelDelete}
                            page={questionPage}
                            onChangePage={setQuestionPage}
                            warning={warning}
                        />
                    </div>
                    <div className={classes.rightArea}>{buildAssessmentPane()}</div>
                </div>
            ) : null}

            <ComplianceAddEditQuestion
                companyId={Auth.companyId}
                open={!isNullOrUndefined(addEditQuestionRef)}
                onClose={() => setAddEditQuestionRef(null)}
                onDone={handleAddQuestion}
                categoriesArray={questionCategories}
                question={addEditQuestionRef}
                shipsArray={ships}
            />

            <ComplianceAddEditAssessment
                companyId={Auth.companyId}
                open={!isNullOrUndefined(addEditAssessmentRef)}
                onClose={() => setAddEditAssessmentRef(null)}
                onDone={handleAddEditAssessment}
                assessment={addEditAssessmentRef}
                categoriesArray={questionCategories}
                categories={categories}
                questionsArray={questions.map(e => ({ ...e, checked: false, selected: false }))}
                usersArray={users.map(e => ({ ...e, checked: false, selected: false }))}
                userGroupsArray={userGroups.map(e => ({ ...e, checked: false, selected: false }))}
                shipsArray={ships.map(e => ({ ...e, checked: false, selected: false }))}
            />

            {buildArchiveQuestionDialog()}
            {buildDuplicateAssessmentDialog()}
            {buildExcelDialog()}
            <QuestionTagExport
                onClose={() => {
                    setShowQuestionExport(false);
                }}
                open={showQuestionExport}
                questions={questions}
                assessments={assessments}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Compliance);

Compliance.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};
