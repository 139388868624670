import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Card, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { descendingComparator, isNullOrUndefined, scoreToPercentage } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import ComplianceController from '../../controllers/ComplianceController';
import OverviewTableItem from './OverviewTableItem';
import UserGroupController from '../../controllers/UserGroupController';
import { Exposure } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    wrapper: {
        maxHeight: '100vh',
    },
    table: {
        maxHeight: '100vh',
        '& th': {
            fontWeight: 'bold',
        },
        '& .btn-cell': {
            paddingRight: 8,
        },
    },
    card: {
        '& .MuiTableBody-root': {
            '& :last-child': {
                '& > *': {
                    border: 'none',
                },
            },
        },
    },
}));

function OverviewQuestions(props) {
    const { Auth, PushHistory } = props;
    const { shipId, userId } = queryString.parse(props.location.search);
    const [questions, setQuestions] = React.useState([]);
    const [userGroups, setUserGroups] = React.useState([]);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    //Sorting
    const [orderRow, setOrderRow] = React.useState('totalAnswered');
    const [orderDirection, setOrderDirection] = React.useState('desc');

    const classes = useStyles();

    const loadQuestionOverview = React.useCallback(async () => {
        const response = await ComplianceController.getQuestionOverview(Auth.companyId, shipId, userId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setQuestions(response.data);
        }
    }, [Auth, shipId, userId]);

    const loadUserGroups = React.useCallback(async () => {
        const response = await UserGroupController.getUserGroups(Auth.companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setUserGroups(response.data);
        }
    }, [Auth]);

    // init & auth check
    React.useEffect(() => {
        // ensure user is signed in
        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }
        // fetch ships for inital render
        async function init() {
            await Promise.all([loadQuestionOverview(), loadUserGroups()]);
        }
        setLoading(true);
        init().then(() => setLoading(false));
    }, [Auth, loadQuestionOverview, loadUserGroups]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    const handelSortChange = React.useCallback(
        newSort => {
            if (newSort === orderRow) {
                if (orderDirection === 'desc') {
                    setOrderDirection('asc');
                } else {
                    setOrderDirection('desc');
                }
                return;
            }
            setOrderRow(newSort);
            setOrderDirection('desc');
        },
        [orderRow, orderDirection, setOrderDirection, setOrderRow]
    );

    function getComparator(order, orderBy) {
        switch (orderBy) {
            case 'id':
                return order === 'desc' ? (a, b) => idDescendingComparator(a, b) : (a, b) => -idDescendingComparator(a, b);
            default:
                return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
        }
    }

    function idDescendingComparator(a, b) {
        const aComp = Number(a.id);
        const bComp = Number(b.id);
        if (bComp < aComp) {
            return -1;
        }
        if (bComp > aComp) {
            return 1;
        }
        return 0;
    }

    function buildTableHead() {
        return (
            <TableHead>
                <TableRow>
                    <TableCell className="btn-cell">
                        <Exposure style={{ marginTop: 6 }} />
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'id'} direction={orderRow === 'id' ? orderDirection : 'desc'} onClick={() => handelSortChange('id')}>
                            Question Number
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'title'} direction={orderRow === 'title' ? orderDirection : 'desc'} onClick={() => handelSortChange('title')}>
                            Title
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderRow === 'totalAnswered'} direction={orderRow === 'totalAnswered' ? orderDirection : 'desc'} onClick={() => handelSortChange('totalAnswered')}>
                            Total Answered
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel
                            active={orderRow === 'correctlyAnswered'}
                            direction={orderRow === 'correctlyAnswered' ? orderDirection : 'desc'}
                            onClick={() => handelSortChange('correctlyAnswered')}
                        >
                            Correct Answers
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel
                            active={orderRow === 'incorrectlyAnswered'}
                            direction={orderRow === 'incorrectlyAnswered' ? orderDirection : 'desc'}
                            onClick={() => handelSortChange('incorrectlyAnswered')}
                        >
                            Incorrect Answers
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                        <TableSortLabel
                            active={orderRow === 'correctionPercentage'}
                            direction={orderRow === 'correctionPercentage' ? orderDirection : 'desc'}
                            onClick={() => handelSortChange('correctionPercentage')}
                        >
                            Correct Percentage
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    function buildQuestionRow(question) {
        const { id, title, totalAnswered, correctlyAnswered, incorrectlyAnswered, correctionPercentage, userGroupePerformance } = question;
        return (
            <OverviewTableItem
                key={id}
                mainCells={
                    <>
                        <TableCell>Q{id}</TableCell>
                        <TableCell>{title}</TableCell>
                        <TableCell align="center">{totalAnswered}</TableCell>
                        <TableCell align="center">{correctlyAnswered}</TableCell>
                        <TableCell align="center">{incorrectlyAnswered}</TableCell>
                        <TableCell align="right">{scoreToPercentage(correctionPercentage, 1)}%</TableCell>
                    </>
                }
                mainCellLength={6}
                childTitle="User Groups"
                childContent={
                    <Card variant="outlined" className={classes.card}>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell align="center">Total Answered</TableCell>
                                    <TableCell align="center">Correct Answers</TableCell>
                                    <TableCell align="center">Incorrect Answers</TableCell>
                                    <TableCell align="right">Correct Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userGroupePerformance.map((userGroup, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{userGroups.find(e => e.id === userGroup.title)?.name ?? 'No Name'}</TableCell>
                                        <TableCell align="center">{userGroup.totalAnswered}</TableCell>
                                        <TableCell align="center">{userGroup.correctlyAnswered}</TableCell>
                                        <TableCell align="center">{userGroup.incorrectlyAnswered}</TableCell>
                                        <TableCell align="right">{scoreToPercentage(userGroup.correctionPercentage, 1)}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Card>
                }
            />
        );
    }

    function buildRows() {
        const comparator = getComparator(orderDirection, orderRow);

        return (
            questions
                ?.sort((a, b) => {
                    return comparator(a, b, orderRow);
                })
                .map(buildQuestionRow) ?? <></>
        );
    }

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay loading={loading} />
            <Alert text={warning} />
            <Paper square>
                <TableContainer className={classes.table}>
                    <Table stickyHeader size="small">
                        {buildTableHead()}
                        <TableBody>{buildRows()}</TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewQuestions);

OverviewQuestions.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
