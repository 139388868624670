import React from 'react';
import PropTypes from 'prop-types';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import { isNullOrUndefined } from '../../helpers/Utils';

export function Alert(props) {
    const text = getTextFromProps();
    const header = props.header;
    const severity = props.severity ?? 'warning';

    function getErrorFromObject(object) {
        if (!isNullOrUndefined(object.message) && typeof object.message === 'string') {
            return object.message;
        } else if (!isNullOrUndefined(object.error) && typeof object.error === 'string') {
            return object.error;
        } else if (!isNullOrUndefined(object.errorMessage) && typeof object.errorMessage === 'string') {
            return object.errorMessage;
        }
        return 'An error occurred';
    }

    function getTextFromProps() {
        if (isNullOrUndefined(props.text)) {
            return 'An error occurred';
        } else {
            if (typeof props.text === 'string') {
                return props.text;
            } else if ((isNullOrUndefined(props.text.response) || isNullOrUndefined(props.text.response.data)) && !isNullOrUndefined(props.text.message)) {
                return props.text.message;
            } else if (!isNullOrUndefined(props.text.response) && !isNullOrUndefined(props.text.response.data)) {
                const data = props.text.response.data;
                if (typeof data === 'string') {
                    return data;
                } else if (typeof data === 'object') {
                    if (Array.isArray(data)) {
                        if (data.length > 0) {
                            return getErrorFromObject(data[0]);
                        }
                    } else {
                        return getErrorFromObject(data);
                    }
                }
            }
        }
    }

    return !isNullOrUndefined(props.text) ? (
        <MuiAlert severity={severity}>
            <AlertTitle>{header}</AlertTitle>
            {text}
        </MuiAlert>
    ) : null;
}

Alert.propTypes = {
    header: PropTypes.string,
    text: PropTypes.any,
    severity: PropTypes.string,
};
