import React from 'react';
import BridgeImage from '../../images/bridge.png';
import SentiniLogo from '../../images/fathom-final.png';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Alert } from '../../components/Common/Alert';
import { checkPassword, isNullOrUndefined } from '../../helpers/Utils';
import { Button, Container, TextField, Typography, Grid } from '@material-ui/core';
import { AuthLayout } from '../../components/Auth/AuthLayout';

function PasswordReset(props) {
    const [password, setPassword] = React.useState('');
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
    const [warningText, setWarningText] = React.useState('');
    const [showWarning, setShowWarning] = React.useState(false);
    const [passwordRequirements, setPasswordRequirements] = React.useState(null);
    const [passwordErrors, setPasswordErrors] = React.useState([]);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    const values = queryString.parse(props.location.search);

    // initialise
    React.useEffect(() => {
        async function init() {
            if (props.Auth.isAuthenticated) {
                props.PushHistory('/');
            }
            const pwordReqResponse = await UserController.getPasswordRequirements();
            if (!pwordReqResponse.hasError) {
                setPasswordRequirements(pwordReqResponse.data);
            } else {
                setWarningText('Failed to fetch password requirements');
                setShowWarning(true);
            }
        }
        init();
    }, [props]);

    // redirect
    React.useEffect(() => {
        if (isNullOrUndefined(redirectUrl)) {
            return;
        }
        props.PushHistory(redirectUrl);
    }, [redirectUrl, props]);

    async function handleSubmit(event) {
        event.preventDefault();
        setSubmitting(true);
        setShowWarning(false);

        const { userId, emailCode } = values;
        const response = await UserController.resetPassword(userId, emailCode, password, passwordConfirmation);
        if (response.hasError) {
            setWarningText(response.data);
            setSubmitting(false);
            setShowWarning(true);
        } else {
            setRedirectUrl('/Login');
        }
    }

    function handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case 'password':
                setPassword(value);
                break;
            case 'passwordConfirmation':
                setPasswordConfirmation(value);
                break;
            default:
                break;
        }
    }

    async function checkPasswordIsValid() {
        setPasswordErrors(checkPassword(passwordRequirements, password));
    }

    function buildPasswordErrors() {
        if (passwordErrors.length === 0) {
            return null;
        }
        return passwordErrors.map((e, i) => (
            <React.Fragment key={i}>
                <span>{e}</span>
                <br />
            </React.Fragment>
        ));
    }

    return (
        <AuthLayout heroImage={BridgeImage}>
            <Container maxWidth="xs">
                <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <img
                        style={{
                            marginBottom: 32,
                            maxWidth: 300,
                        }}
                        src={SentiniLogo}
                        alt="Sentini Marine"
                    />
                    <Typography align="center" variant="h2" gutterBottom style={{ fontSize: 42 }}>
                        Reset Password
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                id="password-input"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={handleInput}
                                name="password"
                                onBlur={() => checkPasswordIsValid()}
                                helperText={buildPasswordErrors()}
                                variant="filled"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="confirm-password-input"
                                label="Confirm Password"
                                type="password"
                                value={passwordConfirmation}
                                onChange={handleInput}
                                name="passwordConfirmation"
                                variant="filled"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {showWarning ? <Alert header="Something went wrong!" text={warningText} /> : null}
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth disabled={submitting} variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </AuthLayout>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

PasswordReset.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
