import React from 'react';
import NoImage from '../../images/no-img.png';
import PropTypes from 'prop-types';
import { makeStyles, Card, CardMedia, CardContent, Typography, CardActions, IconButton, Popover, CardActionArea } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { isNullOrUndefined } from '../../helpers/Utils';
import useOnScreen from '../../helpers/UseOnScreen';
import { useRef } from 'react';

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 356,
    },
    media: props => ({
        height: 168,
        backgroundSize: props.fitImage === true ? 'contain' : 'cover',
        backgroundColor: 'black',
    }),
    content: {
        minHeight: 126,
    },
    actions: {
        flexDirection: 'row-reverse',
    },
    popover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiButton-root': {
            textTransform: 'none',
            minWidth: 120,
        },
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
            fontWeight: 400,
        },
    },
}));

export function SearchCard(props) {
    const { image, imagePromise, imageGetter, title, description, children, onClick, fitImage } = props;

    const [isInScreen, setIsInScreen] = React.useState(false);
    const [loadedImage, setLoadedImage] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const ref = useRef();
    const isVisible = useOnScreen(ref);

    const classes = useStyles({ fitImage: fitImage === true && (!isNullOrUndefined(image) || !isNullOrUndefined(loadedImage)) });

    React.useEffect(() => {
        async function saveImage() {
            const image = await imagePromise;
            setLoadedImage(!isNullOrUndefined(image) && image.length > 0 ? image : null);
        }
        if (!imagePromise) return;
        if (loadedImage === null) {
            saveImage();
        }
    }, [imagePromise, loadedImage]);

    React.useEffect(() => {
        async function loadImage() {
            const image = await imageGetter();
            setLoadedImage(!isNullOrUndefined(image) && image.length > 0 ? image : null);
        }
        if (!imageGetter || !isVisible || isInScreen) return;
        if (loadedImage === null) {
            setIsInScreen(true);
            loadImage();
        }
    }, [isVisible, imageGetter, loadedImage]);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'search-item-popover' : undefined;
    const imageSrc = !isNullOrUndefined(image) && image.length !== 0 ? image : null;

    return (
        <>
            <Card className={classes.root} elevation={2} ref={ref}>
                <CardActionArea onClick={onClick} disabled={isNullOrUndefined(onClick)}>
                    <CardMedia image={imageSrc ?? loadedImage ?? NoImage} title={title ?? 'NONAME'} className={classes.media} />
                    <CardContent className={classes.content}>
                        <Typography variant="h6" gutterBottom>
                            {title ?? 'NONAME'}
                        </Typography>
                        {description}
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.actions}>
                    {!isNullOrUndefined(children) ? (
                        <IconButton aria-describedby={id} onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                    ) : null}
                </CardActions>
            </Card>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className={classes.popover}
            >
                {children}
            </Popover>
        </>
    );
}

SearchCard.propTypes = {
    image: PropTypes.string,
    imagePromise: PropTypes.object,
    imageGetter: PropTypes.func,
    title: PropTypes.string,
    onClick: PropTypes.func,
    description: PropTypes.any,
    children: PropTypes.any,
    fitImage: PropTypes.bool,
};
