import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LogOut } from '../../stores/Actions/Authentication';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

function CheckToken(props) {
    const { Auth, LogOut, PushHistory } = props;
    const [showExpired, setShowExpired] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const location = useLocation();

    const getErrorFromObject = React.useCallback(object => {
        if (!isNullOrUndefined(object.message) && typeof object.message === 'string') {
            return object.message;
        } else if (!isNullOrUndefined(object.error) && typeof object.error === 'string') {
            return object.error;
        } else if (!isNullOrUndefined(object.errorMessage) && typeof object.errorMessage === 'string') {
            return object.errorMessage;
        }
        return 'An error occurred';
    }, []);

    const getTextFromError = React.useCallback(
        text => {
            if (isNullOrUndefined(text)) {
                return 'An error occurred';
            } else {
                if (typeof text === 'string') {
                    return text;
                } else if ((isNullOrUndefined(text.response) || isNullOrUndefined(text.response.data)) && !isNullOrUndefined(text.message)) {
                    return text.message;
                } else if (!isNullOrUndefined(text.response) && !isNullOrUndefined(text.response.data)) {
                    const data = text.response.data;
                    if (typeof data === 'string') {
                        return data;
                    } else if (typeof data === 'object') {
                        if (Array.isArray(data)) {
                            if (data.length > 0) {
                                return getErrorFromObject(data[0]);
                            }
                        } else {
                            return getErrorFromObject(data);
                        }
                    }
                }
            }
        },
        [getErrorFromObject]
    );

    // check token when location updates
    React.useEffect(() => {
        async function checkToken() {
            if (Auth.isLoggingIn === true || Auth.isAuthenticated === false) {
                return;
            }
            const response = await UserController.getUserInfo();
            if (response.hasError) {
                const error = getTextFromError(response.data);
                if (error.includes('Invalid Token')) {
                    logout();
                    return;
                }
            }
            const expired = await UserController.hasTokenExpired();
            if (expired) {
                logout();
                return;
            }
        }
        function logout() {
            LogOut();
            setRedirectUrl('/Login');
            setShowExpired(true);
        }
        checkToken();
    }, [location, LogOut, Auth, setRedirectUrl, setShowExpired, getTextFromError]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    return (
        <Dialog open={showExpired} onClose={() => setShowExpired(false)}>
            <DialogTitle>Session Expired</DialogTitle>
            <DialogContent>Your previous session has expired, please login again</DialogContent>
            <DialogActions>
                <Button onClick={() => setShowExpired(false)} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    LogOut: () => dispatch(LogOut()),
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckToken);

CheckToken.propTypes = {
    Auth: PropTypes.object,
    LogOut: PropTypes.func,
    PushHistory: PropTypes.func,
};
