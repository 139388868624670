import { Colours } from '../../helpers/Colours';
import { MenuItem, withStyles } from '@material-ui/core';

export const DarkMenuItem = withStyles({
    root: {
        color: Colours.black,
        '&.Mui-selected': {
            backgroundColor: Colours.secondary,
            color: Colours.white,
            '&:hover': {
                backgroundColor: Colours.black,
                color: Colours.white,
            },
        },
        '&:hover': {
            backgroundColor: Colours.black,
            color: Colours.white,
        },
    },
})(MenuItem);
