import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const FeedbackController = {
    createTicket,
};

async function createTicket(message, image) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('Message', message);
    formData.append('Source', 'Yacht Knowledge Admin Portal');
    formData.append('Image', image, 'screenshot.png');
    return Axios.post(APIRoute + APIPath.FEEDBACK_CREATE_TICKET, formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default FeedbackController;
