import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { flattenFormContent, generateResponse } from '../helpers/Utils';

const QuestionController = {
    getCategories,
    addCategories,
    editCategories,
    removeCategories,
    addCategory,
    getQuestions,
    getAllQuestions,
    getQuestion,
    addQuestion,
    editQuestion,
    archiveQuestion,
    deleteQuestion,
    importFromExcel,
};

async function getCategories(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_QUESTION_CATEGORIES(companyId), userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addCategories(companyId, name) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.ADD_QUESTION_CATEGORIES(companyId), { name }, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editCategories(companyId, id, name) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.EDIT_QUESTION_CATEGORIES(companyId), { id, name }, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function removeCategories(companyId, id) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.REMOVE_QUESTION_CATEGORIES(companyId, id), null, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addCategory(companyId, name) {
    const userHeaders = await UserController.getUserHeaders();
    const categoryModel = { name };
    return Axios.post(APIRoute + APIPath.ADD_QUESTION_CATEGORY(companyId), categoryModel, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getQuestions(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANY_QUESTIONS(companyId), userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getAllQuestions(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_ALL_QUESTIONS(companyId), userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getQuestion(companyId, questionId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_QUESTION(companyId, questionId), userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addQuestion(companyId, title, description, type, frequency, serverity, answers, questionAnswerImages, questionCatagoryIds, documentationItems, shipIds, allowNFC) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('Title', title);
    formData.append('Description', description);
    formData.append('Type', type);
    formData.append('Frequency', frequency);
    formData.append('Severity', serverity);
    formData.append('AllowNFC', allowNFC);
    questionAnswerImages.forEach((e, index) => {
        formData.append(`QuestionAnswerImages`, e, index);
    });
    questionCatagoryIds.forEach((e, index) => {
        formData.append(`QuestionCatagoryIds[${index}]`, e);
    });
    answers.forEach((e, index) => {
        flattenFormContent('Answers', formData, e, index);
    });
    documentationItems.forEach((e, index) => {
        flattenFormContent('DocumentationItems', formData, e, index);
    });
    shipIds.forEach((e, index) => {
        formData.append(`ShipIds[${index}]`, e);
    });
    return Axios.post(APIRoute + APIPath.ADD_QUESTION(companyId), formData, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editQuestion(id, companyId, title, description, frequency, serverity, answers, questionAnswerImages, questionCatagoryIds, documentationItems, shipIds, allowNFC) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('Id', id);
    formData.append('Title', title);
    formData.append('Description', description);
    formData.append('Frequency', frequency);
    formData.append('Severity', serverity);
    formData.append('AllowNFC', allowNFC);
    questionAnswerImages.forEach((e, index) => {
        formData.append(`QuestionAnswerImages`, e, index);
    });
    questionCatagoryIds.forEach((e, index) => {
        formData.append(`QuestionCatagoryIds[${index}]`, e);
    });
    answers.forEach((e, index) => {
        flattenFormContent('Answers', formData, e, index);
    });
    documentationItems.forEach((e, index) => {
        flattenFormContent('DocumentationItems', formData, e, index);
    });
    shipIds.forEach((e, index) => {
        formData.append(`ShipIds[${index}]`, e);
    });
    return Axios.post(APIRoute + APIPath.EDIT_QUESTION(companyId), formData, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function archiveQuestion(companyId, questionId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.ARCHIVE_QUESTION(companyId, questionId), null, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function deleteQuestion(companyId, questionId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_QUESTION(companyId, questionId), null, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function importFromExcel(companyId, file, shipIds) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('file', file);
    shipIds.forEach((e, index) => {
        formData.append(`ShipIds[${index}]`, e);
    });
    return Axios.post(APIRoute + APIPath.IMPORT_FROM_EXCEL(companyId), formData, userHeaders)
        .then(async response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default QuestionController;
