/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { useEffect } from 'react';
import { tsvJson } from '../../helpers/UploadReader';
import { CompanyRole, CompanyRoles, ShipRole, ShipRoles } from '../../helpers/Constants';
import { DarkMenuItem } from '../../components/Common/DarkMenuItem';
import ShipController from '../../controllers/ShipController';
import UserGroupController from '../../controllers/UserGroupController';
import UserController from '../../controllers/UserController';
import parsePhoneNumber from 'libphonenumber-js';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';

const useStyles = makeStyles(() => ({
    table: {
        '& th': {
            fontWeight: 'bold',
        },
        '& tr:last-child': {
            '& td': {
                borderBottom: 'none',
            },
        },
    },
    uploadArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 16,
    },
}));

/**
 * @param {{
 *  open: boolean;
 *  onClose: function(): void;
 *  onSave: function;
 *  companyId: string;
 *  userCompanyRole: string;
 *  isUserAdmin: boolean;
 * }} props
 * @returns {ReactNode}
 */
export const UploadUsers = ({ open, onClose, onSave, companyId, userCompanyRole, isUserAdmin }) => {
    const classes = useStyles();

    const [file, setFile] = useState(null);
    const [users, setUsers] = useState([]);
    const [ships, setShips] = React.useState([]);
    const [ranks, setRanks] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [warnings, setWarnings] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    const loadShips = React.useCallback(async () => {
        const response = await ShipController.getShips(companyId);
        if (response.hasError) {
            setWarnings([response.data]);
        } else {
            setShips(response.data);
        }
    }, [companyId]);

    const loadUserGroups = React.useCallback(async () => {
        const response = await UserGroupController.getUserGroups(companyId);
        if (response.hasError) {
            setWarnings([response.data]);
        } else {
            setGroups(response.data);
        }
    }, [companyId]);

    const loadRanks = React.useCallback(async () => {
        const response = await UserController.getRanks();
        if (response.hasError) {
            setWarnings([response.data]);
        } else {
            setRanks(response.data);
        }
    }, [setRanks]);

    // initialise when user or open state changes
    React.useEffect(() => {
        async function init() {
            await loadShips();
            await loadUserGroups();
            await loadRanks();
        }

        setFile(null);
        setWarnings([]);
        setUsers([]);

        if (open) {
            setLoading(true);
            init().then(() => setLoading(false));
        }
    }, [open, loadShips, loadUserGroups, loadRanks]);

    const handleCreateMagicLinks = async event => {
        event.preventDefault();

        const failedUsers = [];
        const failedUserWarnings = [];

        setSaving(true);

        for (let i = 0; i < users.length; i++) {
            const userName = getUserData(i, 'User Name');

            const phoneNumber = getUserData(i, 'Phone Number')?.trim() ?? '';
            const phoneTest = parsePhoneNumber(phoneNumber, 'GB');
            const validPhoneNumber = phoneTest !== undefined && phoneTest.isValid();

            if (phoneNumber !== '' && !validPhoneNumber) {
                failedUsers.push(users[i]);
                failedUserWarnings.push(`${userName} - Please set a valid number or leave the phone number empty`);
                continue;
            }

            const companyRole = getUserData(i, 'Company Role');
            const realCompanyRole = getUserData(i, 'Company Role') === CompanyRole.CrewMember ? '' : companyRole;

            const shipId = getUserData(i, 'Ship Id');
            const ship = ships.find(ship => ship.id === shipId);

            const response = await UserController.createMagicLink(
                userName,
                getUserData(i, 'Email Address'),
                getUserData(i, 'First Name'),
                getUserData(i, 'Last Name'),
                phoneNumber === '' ? null : parsePhoneNumber(phoneNumber, 'GB').formatInternational(),
                null,
                realCompanyRole,
                getUserData(i, 'User Group'),
                ship
                    ? [
                          {
                              shipId,
                              shipRole: getUserData(i, 'Ship Role'),
                              name: ship.name,
                          },
                      ]
                    : null,
                false,
                false,
                getUserData(i, 'Rank')
            );

            if (response.hasError) {
                failedUsers.push(users[i]);
                failedUserWarnings.push(response.data);
            }
        }

        setSaving(false);
        setUsers(failedUsers);
        setWarnings(failedUserWarnings);
        onSave();

        if (failedUsers.length === 0) {
            onClose();
        }
    };

    const handleFileUpload = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setFile(reader.result);
        };

        reader.readAsText(file, 'utf-8');
    };

    const getUserData = (index = 0, key = '') => {
        const user = users[index];
        const userKey = Object.keys(user).find(val => val.includes(key));
        if (!userKey) return null;
        return user[userKey];
    };

    const setUserData = (index = 0, key = '', value = '') => {
        const user = users[index];
        const userKey = Object.keys(user).find(val => val.includes(key)) ?? key;
        setUsers(users => users.map((arrUser, arrIndex) => (index === arrIndex ? { ...arrUser, [userKey]: value } : arrUser)));
    };

    useEffect(() => {
        if (!file) return;
        setUsers(tsvJson(file));
    }, [file]);

    const uploadArea = (
        <div className={classes.uploadArea}>
            <Typography>Upload a TSV to get started</Typography>
            <label htmlFor="upload-tsv">
                <Button variant="outlined" component="span" style={{ marginTop: 8 }}>
                    Upload TSV
                </Button>
                <input id="upload-tsv" hidden accept="text/*" type="file" onChange={handleFileUpload} />
            </label>
        </div>
    );

    const userTable =
        users.length === 0 ? (
            <></>
        ) : (
            <TableContainer component={Card} variant="outlined">
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Name*</TableCell>
                            <TableCell>Email Address*</TableCell>
                            <TableCell>First Name*</TableCell>
                            <TableCell>Last Name*</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Rank*</TableCell>
                            <TableCell>Company Role*</TableCell>
                            <TableCell>User Group*</TableCell>
                            <TableCell>Ship*</TableCell>
                            <TableCell>Ship Role*</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((_, index) => (
                            <TableRow key={index}>
                                <TableCell>{getUserData(index, 'User Name')}</TableCell>
                                <TableCell>{getUserData(index, 'Email Address')}</TableCell>
                                <TableCell>{getUserData(index, 'First Name')}</TableCell>
                                <TableCell>{getUserData(index, 'Last Name')}</TableCell>
                                <TableCell>{getUserData(index, 'Phone Number')}</TableCell>
                                <TableCell>
                                    <FormControl size="small" fullWidth required variant="outlined">
                                        <Select value={getUserData(index, 'Rank')} onChange={event => setUserData(index, 'Rank', event.target.value)} variant="outlined">
                                            {ranks.map(e => (
                                                <DarkMenuItem key={e} value={e}>
                                                    {e}
                                                </DarkMenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <FormControl size="small" fullWidth required variant="outlined">
                                        <Select value={getUserData(index, 'Company Role')} onChange={event => setUserData(index, 'Company Role', event.target.value)} variant="outlined" size="small">
                                            {CompanyRoles.map(e => (
                                                <DarkMenuItem key={e.id} value={e.id} disabled={!(userCompanyRole === CompanyRole.Owner || isUserAdmin ? true : e.id === CompanyRole.CrewMember)}>
                                                    {e.name}
                                                </DarkMenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                {getUserData(index, 'Company Role') === CompanyRole.CrewMember ? (
                                    <>
                                        <TableCell>
                                            <FormControl size="small" fullWidth required variant="outlined">
                                                <Select value={getUserData(index, 'User Group')} onChange={event => setUserData(index, 'User Group', event.target.value)} variant="outlined">
                                                    {groups.map(e => (
                                                        <DarkMenuItem key={e.id} value={e.id}>
                                                            {e.name}
                                                        </DarkMenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl size="small" fullWidth required variant="outlined">
                                                <Select value={getUserData(index, 'Ship Id')} onChange={event => setUserData(index, 'Ship Id', event.target.value)} variant="outlined">
                                                    {ships.map(e => (
                                                        <DarkMenuItem key={e.id} value={e.id}>
                                                            {e.name}
                                                        </DarkMenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <FormControl size="small" fullWidth required variant="outlined">
                                                <Select value={getUserData(index, 'Ship Role')} onChange={event => setUserData(index, 'Ship Role', event.target.value)} variant="outlined">
                                                    {ShipRoles.map(e => (
                                                        <DarkMenuItem key={e.id} value={e.id} disabled={!(userCompanyRole === CompanyRole.Owner || isUserAdmin ? true : e.id === ShipRole.CrewMember)}>
                                                            {e.name}
                                                        </DarkMenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </>
                                ) : (
                                    <TableCell align="center" colSpan={3}>
                                        Crew Members Only
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );

    const errorDialog = (
        <Dialog open={warnings.length > 0} onClose={() => setWarnings([])}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
                {warnings.map((warning, i) => (
                    <Alert key={i} header="Something went wrong!" text={warning} />
                ))}
                ;
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setWarnings([])}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Dialog open={open} maxWidth="xxl">
                <form onSubmit={handleCreateMagicLinks}>
                    <DialogTitle>Upload Users</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs="auto">
                                <Button variant="contained" color="primary" component="a" download href="./tsv/UserTSV.xlsx">
                                    Download TSV Template
                                </Button>
                            </Grid>
                            <Grid item xs="auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                <Typography variant="caption">
                                    When exporting the template from Excel for upload here, please select &apos;Tab Separated Values&apos; (.txt) as the export format.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {loading ? (
                        <></>
                    ) : (
                        <>
                            <Divider />
                            <DialogContent>{users.length === 0 ? uploadArea : userTable}</DialogContent>
                        </>
                    )}
                    <Divider />
                    <DialogActions>
                        <Button color="primary" onClick={() => onClose()} disabled={saving}>
                            Clear & Cancel
                        </Button>
                        <Button variant="contained" color="primary" disabled={saving || users.length === 0} type="submit">
                            Upload Users
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {errorDialog}

            <LoadingOverlay loading={saving} />
        </>
    );
};
