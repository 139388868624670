import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import QuestionController from '../../controllers/QuestionController';

export default function CategorysAddEdit(props) {
    const { category, open, onClose, onError, onDone, companyId } = props;
    const [name, setName] = React.useState('');

    const categoryExists = () => !isNullOrUndefined(category?.id);

    // update fields with current user group info
    React.useEffect(() => {
        setName(category?.name ?? '');
    }, [category]);

    async function handleSave(event) {
        event.preventDefault();
        onError(null);
        const response = categoryExists() ? await QuestionController.editCategories(companyId, category.id, name) : await QuestionController.addCategories(companyId, name);
        if (response.hasError) {
            onError(response.data);
        } else {
            onDone(response.data);
        }
        onClose();
    }

    function handleInput(event) {
        const { value, name } = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            default:
                break;
        }
    }

    return (
        <Dialog open={open}>
            <form onSubmit={handleSave}>
                <DialogTitle>Add/Edit Category</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField variant="filled" label="Name" value={name} onChange={handleInput} name="name" required fullWidth />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" type="submit">
                        Save
                    </Button>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

CategorysAddEdit.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    companyId: PropTypes.string.isRequired,
    category: PropTypes.object,
};
