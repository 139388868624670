import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Add } from '@material-ui/icons';
import { makeStyles, Grid, Button, TextField, Dialog, DialogContent, DialogActions, DialogTitle, Typography, CircularProgress } from '@material-ui/core';
import { isEmpty, isNullOrUndefined, updateQueryString } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { SearchCard } from '../../components/Common/SearchCard';
import { FleetAddEdit } from './FleetAddEdit';
import { clearUploadUrls } from '../../helpers/UploadReader';
import { CompanyRole, ShipRole } from '../../helpers/Constants';
import ShipController from '../../controllers/ShipController';
import { Skeleton } from '../../components/Common/Skeleton';
import { setShips } from '../../stores/Actions/Fleet';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '16px 32px',

        '& .skeleton': {
            margin: 0,
        },

        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
    topArea: {
        paddingBottom: 8,
        '& .MuiButtonBase-root': {
            height: '100%',
        },
    },
}));

function Fleet(props) {
    const { Auth, PushHistory, FleetStore, setShips } = props;
    const { ships } = FleetStore;

    const { name: nameQuery } = queryString.parse(window.location.search);
    const [nameSearch, setNameSearch] = React.useState(nameQuery ?? '');
    const [deleteRef, setDeleteRef] = React.useState(null);
    const [addEditRef, setAddEditRef] = React.useState(null);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const classes = useStyles();

    const userIsCaptain = Auth.shipRoles.includes(ShipRole.Captain) && Auth.companyRole !== CompanyRole.Owner;

    const loadShips = React.useCallback(async () => {
        setLoading(true);
        const response = await ShipController.getShips(Auth.companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            const prevShips = ships;

            response.data = response.data.map(e => {
                const prevShip = prevShips.find(el => el.id === e.id);

                if (prevShip) {
                    const { logoPath } = prevShip;
                    if (Object.keys(prevShip).includes('logoPath')) {
                        return { ...e, logoPath };
                    }
                }
                return e;
            });

            setShips(response.data);

            for (let i = 0; i < response.data.length; i++) {
                const imgResponse = await ShipController.getShipLogo(Auth.companyId, response.data[i].id);
                if (imgResponse.hasError) {
                    continue;
                }
                response.data[i].logoPath = imgResponse.data;
            }
            setShips(response.data);
        }
        setLoading(false);
    }, [Auth]);

    // init & auth check
    React.useEffect(() => {
        // ensure user is signed in
        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }
        // fetch ships for initial render
        async function init() {
            await loadShips();
        }
        init();
    }, [Auth, loadShips]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    function generateQueryString(name) {
        let output = '';
        if (name.trim().length > 0) {
            output += `name=${name}`;
        }
        return output;
    }

    async function handleUpdateShip(ship) {
        const { id } = ship;
        const imgResponse = await ShipController.getShipLogo(Auth.companyId, id);
        if (!imgResponse.hasError) {
            ship.logoPath = imgResponse.data;
        }

        const exists = ships.some(e => e.id === id);
        if (exists) {
            setShips(ships.map(e => (e.id === id ? ship : e)));
        } else {
            setShips([...ships, ship]);
        }
    }

    async function handleDeleteShip() {
        setLoading(true);
        setWarning(null);
        const { id } = deleteRef;
        const response = await ShipController.deleteShip(Auth.companyId, id);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setShips(ships.filter(e => e.id !== id));
        }
        setDeleteRef(null);
        setLoading(false);
    }

    function handleInput(event) {
        const { value, name } = event.target;
        switch (name) {
            case 'name':
                setNameSearch(value);
                updateQueryString(generateQueryString(value));
                break;
            default:
                break;
        }
    }

    function buildTopArea() {
        return (
            <Grid container spacing={2} className={classes.topArea}>
                <Grid item xs="auto">
                    <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setAddEditRef({})} disabled={userIsCaptain}>
                        Add Ship
                    </Button>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <TextField label="Search by Name" variant="filled" value={nameSearch} onChange={handleInput} name="name" fullWidth />
                </Grid>
                {loading ? (
                    <Grid item lg md sm xs>
                        <CircularProgress size={24} style={{ marginTop: 12, marginLeft: 16 }} />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Alert header="Something went wrong!" text={warning} />
                </Grid>
            </Grid>
        );
    }

    function buildShip(ship) {
        const { id, name, created, logoPath } = ship;
        return (
            <Grid key={id} item lg={2} md={3} sm={4} xs={12}>
                <SearchCard
                    title={name}
                    fitImage={logoPath?.length > 0}
                    image={logoPath}
                    description={
                        <>
                            <Typography variant="body2">Created {moment.utc(created).local().format('DD.MM.YYYY')}</Typography>
                        </>
                    }
                >
                    {!userIsCaptain ? <Button onClick={() => setDeleteRef(ship)}>Delete</Button> : null}
                    {!userIsCaptain ? <Button onClick={() => setAddEditRef(ship)}>Edit</Button> : null}
                    <Button onClick={() => setRedirectUrl(`/?shipId=${ship.id}&shipName=${ship.name}`)}>Results</Button>
                </SearchCard>
            </Grid>
        );
    }

    function buildShips() {
        if (isEmpty(ships) || isNullOrUndefined(ships)) {
            return <Skeleton />;
        }

        return (
            <Grid container spacing={2}>
                {ships.filter(e => e.name.toLowerCase().includes(nameSearch.toLowerCase())).map(buildShip)}
            </Grid>
        );
    }

    function buildConfirmDelete() {
        return (
            <Dialog open={!isNullOrUndefined(deleteRef)} onClose={() => setDeleteRef(null)}>
                <DialogTitle>Delete Ship</DialogTitle>
                <DialogContent>Are you sure you want to perform this action?</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => handleDeleteShip()}>
                        Yes
                    </Button>
                    <Button color="primary" onClick={() => setDeleteRef(null)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div className={classes.wrapper}>
            {buildTopArea()}
            {buildShips()}
            {buildConfirmDelete()}
            <FleetAddEdit
                open={!isNullOrUndefined(addEditRef)}
                onClose={() => {
                    setAddEditRef(null);
                    clearUploadUrls();
                }}
                onError={setWarning}
                onDone={handleUpdateShip}
                ship={addEditRef}
                companyId={Auth.companyId}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
    FleetStore: state.Fleet,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    setShips: items => dispatch(setShips(items)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fleet);

Fleet.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    FleetStore: PropTypes.object,
    setShips: PropTypes.func,
};
