import React from 'react';
import NoImage from '../../images/no-img.png';
import PropTypes from 'prop-types';
import ShipController from '../../controllers/ShipController';
import UserGroupController from '../../controllers/UserGroupController';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    Chip,
    makeStyles,
    Typography,
    FilledInput,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { CompanyRole, CompanyRoles, ShipRole, ShipRoles } from '../../helpers/Constants';
import { DarkMenuItem } from '../../components/Common/DarkMenuItem';
import { Autocomplete } from '@material-ui/lab';
import { Alert } from '../../components/Common/Alert';
import { ItemImage } from '../../components/Common/ItemImage';
import { clearUploadUrls, getUploadUrls, readUploadUrls } from '../../helpers/UploadReader';
import UserController from '../../controllers/UserController';
import CompanyController from '../../controllers/CompanyController';
import parsePhoneNumber from 'libphonenumber-js';

const useStyles = makeStyles(() => ({
    selectArea: {
        '& .MuiInputBase-root': {
            paddingTop: 23,
            paddingBottom: 4,
        },
    },
    phoneInput: {},
}));

export default function UsersAddEdit(props) {
    const { user, isMe, isUserAdmin, open, onClose, onDone, companyId, userCompanyRole } = props;
    const [userName, setUserName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [secondaryEmail, setSecondaryEmail] = React.useState('');
    const [forename, setForename] = React.useState('');
    const [surname, setSurname] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [group, setGroup] = React.useState('');
    const [groupsSrc, setGroupsSrc] = React.useState([]);
    const [ranks, setRanks] = React.useState([]);
    const [companyRole, setCompanyRole] = React.useState('');
    const [ships, setShips] = React.useState([]);
    const [shipsSrc, setShipsSrc] = React.useState([]);
    const [restPeriodsEnabled, setRestPeriodsEnabled] = React.useState(false);
    const [canGenerateNFCTags, setCanGenerateNFCTags] = React.useState(false);
    const [rank, setRank] = React.useState(false);
    const [image, setImage] = React.useState('');
    const [removeImage, setRemoveImage] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [warning, setWarning] = React.useState(null);
    const [, updateState] = React.useState();
    const classes = useStyles();

    const forceUpdate = React.useCallback(() => {
        updateState({});
    }, []);

    const userExists = () => !isNullOrUndefined(user?.id);

    const inviteExists = () => !isNullOrUndefined(user?.email);

    const isHeadOfDepartment = ships.some(ship => ship.shipRole === ShipRole.Captain || ship.shipRole === ShipRole.Owner);

    const loadShips = React.useCallback(async () => {
        const response = await ShipController.getShips(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setShipsSrc(response.data);
        }
    }, [companyId]);

    const loadUserGroups = React.useCallback(async () => {
        const response = await UserGroupController.getUserGroups(companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setGroupsSrc(response.data);
        }
    }, [companyId]);

    const loadRanks = React.useCallback(async () => {
        const response = await UserController.getRanks();
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setRanks(response.data);
        }
    }, [setRanks]);

    // initialise when user or open state changes
    React.useEffect(() => {
        async function init() {
            await loadShips();
            await loadUserGroups();
            await loadRanks();
        }
        clearUploadUrls();
        setForename(user?.firstName ?? '');
        setSurname(user?.lastName ?? '');
        setPhoneNumber(user?.phoneNumber ?? '');
        setUserName(user?.userName ?? '');
        setEmail(user?.email ?? '');
        setSecondaryEmail(user?.secondaryEmail ?? '');
        setGroup(user?.userGroupId ?? '');
        setCompanyRole(user?.companyRoleId ?? CompanyRole.CrewMember);
        setShips((user?.ships ?? []).map(e => ({ shipId: e.shipId, shipRole: e.shipRoleId, name: e.name })));
        setRestPeriodsEnabled(user?.restPeriodsEnabled ?? false);
        setImage(user?.imageSrc ?? '');
        setCanGenerateNFCTags(user?.canGenerateNFCTags ?? false);
        setRank(user?.rank ?? '');
        if (open) {
            setLoading(true);
            init().then(() => setLoading(false));
        }
    }, [user, open, loadShips, loadUserGroups, loadRanks]);

    function getImage() {
        return getUploadUrls()[0] ?? (image === '' ? NoImage : image);
    }

    function handleClearImage() {
        clearUploadUrls();
        setImage('');
        setRemoveImage(true);
    }

    async function handleSave(event) {
        event.preventDefault();
        setSaving(true);

        const realCompanyRole = companyRole === CompanyRole.CrewMember ? '' : companyRole;
        if (phoneNumber !== '' && !testPhoneNumber()) {
            setWarning('Please set a valid number or leave the phone number empty');
            setSaving(false);
            return;
        }

        if (!userExists()) {
            const response = !inviteExists()
                ? await UserController.createMagicLink(
                      userName,
                      email,
                      forename,
                      surname,
                      phoneNumber === '' ? null : parsePhoneNumber(phoneNumber, 'GB').formatInternational(),
                      image,
                      realCompanyRole,
                      group,
                      ships,
                      restPeriodsEnabled,
                      canGenerateNFCTags,
                      rank === '' ? null : rank,
                      secondaryEmail
                  )
                : await UserController.editMagicLink(
                      userName,
                      email,
                      forename,
                      surname,
                      phoneNumber === '' ? null : parsePhoneNumber(phoneNumber, 'GB').formatInternational(),
                      image,
                      realCompanyRole,
                      group,
                      ships,
                      restPeriodsEnabled,
                      canGenerateNFCTags,
                      rank === '' ? null : rank,
                      secondaryEmail
                  );
            if (response.hasError) {
                setWarning(response.data);
            } else {
                onDone();
            }
        } else {
            const response = await CompanyController.editUser(
                companyId,
                user.id,
                email,
                forename,
                surname,
                phoneNumber === '' ? null : parsePhoneNumber(phoneNumber, 'GB').formatInternational(),
                image,
                removeImage,
                realCompanyRole,
                group,
                restPeriodsEnabled,
                canGenerateNFCTags,
                rank === '' ? null : rank,
                secondaryEmail
            );
            if (response.hasError) {
                setWarning(response.data);
            } else {
                for (let i = 0; i < ships.length; i++) {
                    const { shipId, shipRole } = ships[i];
                    const needsAdding = user.ships.filter(e => e.shipId === shipId).length === 0;
                    if (needsAdding) {
                        const addResponse = await ShipController.addUserToShip(companyId, shipId, shipRole, user.id);
                        if (addResponse.hasError) {
                            setWarning(addResponse.data);
                            continue;
                        }
                    }
                    const needsEditing = user.ships.find(e => e.shipId === shipId)?.shipRoleId !== shipRole;
                    if (needsEditing) {
                        const editResponse = await ShipController.editUserToShip(companyId, shipId, shipRole, user.id);
                        if (editResponse.hasError) {
                            setWarning(editResponse.data);
                            continue;
                        }
                    }
                }
                for (let i = 0; i < user.ships.length; i++) {
                    const { shipId } = user.ships[i];
                    const needsRemoving = ships.filter(e => e.shipId === shipId).length === 0;
                    if (needsRemoving) {
                        const removeResponse = await ShipController.removeUserFromShip(companyId, shipId, user.id);
                        if (removeResponse.hasError) {
                            setWarning(removeResponse.data);
                            continue;
                        }
                    }
                }
                onDone();
            }
        }

        setSaving(false);
        onClose();
    }

    function handleInput(event) {
        const { value, name, files } = event.target;
        switch (name) {
            case 'forename':
                setForename(value);
                break;
            case 'surname':
                setSurname(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'secondaryEmail':
                setSecondaryEmail(value);
                break;
            case 'userName':
                setUserName(value);
                break;
            case 'companyRole':
                setCompanyRole(value);
                break;
            case 'userGroup':
                setGroup(value);
                break;
            case 'ranks':
                setRank(value);
                break;
            case 'image':
                clearUploadUrls();
                if (!isNullOrUndefined(files[0])) {
                    setImage(files[0]);
                    setRemoveImage(false);
                    readUploadUrls([files[0]], forceUpdate);
                } else {
                    setImage('');
                }
                break;
            default:
                break;
        }
    }

    function handleSelectShipRole(shipId, role) {
        setShips(ships.map(e => (e.shipId === shipId ? { ...e, shipRole: role } : e)));
    }

    function buildShipRoleSelectors() {
        return ships.map((e, i) => {
            const { name, shipRole, shipId } = e;
            return (
                <Grid key={i} item xs={12}>
                    <FormControl fullWidth required variant="filled">
                        <InputLabel id={`ship-${i}-role-label`}>{name} Role</InputLabel>
                        <Select labelId={`ship-${i}-role-label`} value={shipRole ?? ''} onChange={e => handleSelectShipRole(shipId, e.target.value)} name="shipRole" variant="filled">
                            {ShipRoles.filter(e => (userCompanyRole === CompanyRole.Owner || isUserAdmin ? true : e.id === ShipRole.CrewMember)).map(e => (
                                <DarkMenuItem key={e.id} value={e.id}>
                                    {e.name}
                                </DarkMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            );
        });
    }

    function buildErrorDialog() {
        return (
            <Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Alert header="Something went wrong!" text={warning} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setWarning(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function testPhoneNumber() {
        const phoneTest = parsePhoneNumber(phoneNumber, 'GB');
        return phoneTest !== undefined && phoneTest.isValid();
    }

    return (
        <>
            <Dialog open={open} maxWidth="md">
                <form onSubmit={handleSave}>
                    <DialogTitle>Add/Edit User</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={7} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            <b>User information</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField variant="filled" label="User Name" value={userName} onChange={handleInput} name="userName" disabled={userExists()} required fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField variant="filled" label="Email Address" value={email} onChange={handleInput} name="email" required fullWidth />
                                    </Grid>
                                    {isHeadOfDepartment ? (
                                        <Grid item xs={12}>
                                            <TextField variant="filled" label="Correspondence Email Address" value={secondaryEmail} onChange={handleInput} name="secondaryEmail" fullWidth />
                                        </Grid>
                                    ) : null}
                                    <Grid item md={6} xs={12}>
                                        <TextField variant="filled" label="First Name" value={forename} onChange={handleInput} name="forename" required fullWidth />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField variant="filled" label="Last Name" value={surname} onChange={handleInput} name="surname" required fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="filled"
                                            label="Phone Number"
                                            value={phoneNumber}
                                            onChange={handleInput}
                                            name="phoneNumber"
                                            fullWidth
                                            error={phoneNumber !== '' && !testPhoneNumber()}
                                            helperText={
                                                phoneNumber === '' ? 'Defaults to GB international code' : !testPhoneNumber() ? 'Invalid Phone Number' : parsePhoneNumber(phoneNumber, 'GB').country
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={restPeriodsEnabled} onChange={e => setRestPeriodsEnabled(e.target.checked)} color="primary" />}
                                            label="Rest Periods Enabled"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={canGenerateNFCTags} onChange={e => setCanGenerateNFCTags(e.target.checked)} color="primary" />}
                                            label="Can Generate NFC Tags"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth required variant="filled">
                                            <InputLabel id="user-rank-label">Rank</InputLabel>
                                            <Select labelId="user-rank-label" value={rank} onChange={handleInput} name="ranks" variant="filled">
                                                {ranks.map(e => (
                                                    <DarkMenuItem key={e} value={e}>
                                                        {e}
                                                    </DarkMenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {!isMe && (
                                        <Grid item xs={12}>
                                            <FormControl fullWidth required variant="filled">
                                                <InputLabel id="company-role-label">Company Role</InputLabel>
                                                <Select labelId="company-role-label" value={companyRole} onChange={handleInput} name="companyRole" variant="filled">
                                                    {CompanyRoles.filter(e => (userCompanyRole === CompanyRole.Owner || isUserAdmin ? true : e.id === CompanyRole.CrewMember)).map(e => (
                                                        <DarkMenuItem key={e.id} value={e.id}>
                                                            {e.name}
                                                        </DarkMenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    {companyRole === CompanyRole.CrewMember ? (
                                        <>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth required variant="filled">
                                                    <InputLabel id="user-group-label">User Group</InputLabel>
                                                    <Select labelId="user-group-label" value={group} onChange={handleInput} name="userGroup" variant="filled">
                                                        {groupsSrc.map(e => (
                                                            <DarkMenuItem key={e.id} value={e.id}>
                                                                {e.name}
                                                            </DarkMenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2">
                                                    <b>Select ships &amp; roles</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    multiple
                                                    options={shipsSrc.map(e => ({ shipId: e.id, shipRole: null, name: e.name }))}
                                                    getOptionLabel={ship => ship.name}
                                                    getOptionSelected={(e, v) => e.shipId === v.shipId}
                                                    className={classes.selectArea}
                                                    value={ships}
                                                    onChange={(_, v) => setShips(v)}
                                                    name="ships"
                                                    disabled={loading}
                                                    filterSelectedOptions
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            variant="filled"
                                                            label={`Ships${ships.length === 0 ? '' : ' *'}`}
                                                            required={ships.length === 0}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => <Chip key={index} color="primary" label={option.name} {...getTagProps({ index })} />)
                                                    }
                                                />
                                            </Grid>
                                            {buildShipRoleSelectors()}
                                        </>
                                    ) : null}
                                    <Grid item xs={12}></Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={5} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            <b>Profile picture</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="filled">
                                            <InputLabel shrink>Image</InputLabel>
                                            <FilledInput
                                                id="upload-input"
                                                aria-describedby="upload-helper"
                                                type="file"
                                                name="image"
                                                inputProps={{
                                                    accept: 'image/*',
                                                }}
                                                onChange={handleInput}
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ItemImage image={getImage()} onClose={handleClearImage} disabled={image === ''} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" disabled={saving} type="submit">
                            Save
                        </Button>
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {buildErrorDialog()}
        </>
    );
}

UsersAddEdit.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    isMe: PropTypes.bool.isRequired,
    isUserAdmin: PropTypes.bool.isRequired,
    user: PropTypes.object,
    shipsArray: PropTypes.array,
    companyId: PropTypes.string.isRequired,
    userCompanyRole: PropTypes.string.isRequired,
};
