import TokenRefresher from './TokenRefresher';

export function isNullOrUndefined(obj) {
    return obj === null || obj === undefined;
}

export function isNullOrEmpty(obj) {
    return obj === null || obj === undefined || obj === '';
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function isNullOrWhitespace(string) {
    return isNullOrUndefined(string) || string.trim().length === 0;
}

export function isStatusOK(status = 0) {
    return status >= 200 && status < 300;
}

export function generateResponse(hasError = false, data, response = null) {
    if (!isNullOrUndefined(response)) {
        TokenRefresher.checkAndRefeshToken(response);
    }
    return { hasError, data };
}

export function checkPassword(passwordRequirements, password) {
    const outstandingRequirements = [];
    if (isNullOrUndefined(passwordRequirements) || isNullOrUndefined(password)) {
        return outstandingRequirements;
    }
    const validChars = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$?_-');
    const currentChars = Array.from(password);
    currentChars.forEach(element => {
        if (!validChars.includes(element)) {
            outstandingRequirements.push("Invalid character detected '" + element + "'");
        }
    });
    if (currentChars.length < passwordRequirements.requiredLength) {
        outstandingRequirements.push('Password must be at least ' + passwordRequirements.requiredLength + ' characters long');
    }
    if (passwordRequirements.requireDigit === true) {
        if (!/\d/.test(password)) {
            outstandingRequirements.push('Password must contain one number');
        }
    }
    if (passwordRequirements.requireLowercase === true) {
        if (!/[a-z]/.test(password)) {
            outstandingRequirements.push('Password must contain one lowercase character');
        }
    }
    if (passwordRequirements.requireUppercase === true) {
        if (!/[A-Z]/.test(password)) {
            outstandingRequirements.push('Password must contain one uppercase character');
        }
    }
    if (passwordRequirements.requireNonAlphanumeric === true) {
        if (!/[!@$?_-]/.test(password)) {
            outstandingRequirements.push('Password must contain one special character (!@$?_-)');
        }
    }
    return outstandingRequirements;
}

export function flattenFormContent(rootValue, formData, value, index) {
    // NOTES
    // - only goes one layer deep at this time, will need expanding if required in future
    // - will not work with nested files
    for (var key in value) {
        const currentValue = value[key];
        if (isNullOrUndefined(currentValue)) {
            continue;
        }
        if (Array.isArray(currentValue)) {
            for (let i = 0; i < currentValue.length; i++) {
                formData.append(`${rootValue}[${index}][${key}][${i}]`, currentValue[i]);
            }
            continue;
        }
        if (currentValue instanceof File) {
            formData.append(`${rootValue}[${index}][${key}]`, index);
            continue;
        }
        formData.append(`${rootValue}[${index}][${key}]`, currentValue);
    }
}

export function stringToHex(str) {
    var arr1 = [];
    for (var n = 0, l = str.length; n < l; n++) {
        var hex = Number(str.charCodeAt(n)).toString(16);
        arr1.push(hex);
    }
    return arr1.join('');
}

export function scoreToPercentage(score = 0, maxScore = 0) {
    if (isNullOrUndefined(score)) {
        return null;
    }
    if (maxScore === 0) {
        return (0).toFixed(2);
    }

    const percentile = maxScore / 100;
    return (score / percentile).toFixed(2);
}

export function updateQueryString(queryString) {
    if (window.history.replaceState) {
        var newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryString}`;
        window.history.replaceState({ path: newUrl }, '', newUrl);
    }
}

export function descendingComparator(a, b, orderBy, defaultValue = '') {
    const aComp = a[orderBy] ?? defaultValue;
    const bComp = b[orderBy] ?? defaultValue;
    if (bComp < aComp) {
        return -1;
    }
    if (bComp > aComp) {
        return 1;
    }
    return 0;
}
