import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Box, Collapse, Divider, IconButton, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Add, Remove } from '@material-ui/icons';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
    tableRow: {
        '& > *': {
            borderBottom: 'none',
        },
        '&.open': {
            backgroundColor: Colours.archived,
        },
    },
}));

function OverviewTableItem({ mainCells, mainCellLength, childTitle, childContent }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    return (
        <React.Fragment>
            <TableRow className={`${classes.tableRow} ${open ? ' open' : ''}`}>
                <TableCell className="btn-cell">
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)} disabled={isNullOrUndefined(childContent) || childContent.length === 0}>
                        {!open ? <Add /> : <Remove />}
                    </IconButton>
                </TableCell>
                {mainCells}
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={mainCellLength + 1}>
                    <Collapse in={open} timeout={0} unmountOnExit>
                        <Divider />
                        <Box style={{ padding: 16 }}>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                <b>{childTitle}</b>
                            </Typography>
                            {childContent}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewTableItem);

OverviewTableItem.propTypes = {
    mainCells: PropTypes.node,
    mainCellLength: PropTypes.number,
    childTitle: PropTypes.node,
    childContent: PropTypes.any,
};
