const { userGroupsCache, shipsCache, usersCache, companyComplianceCache, assessmentComplianceCache, shipComplianceCache, userComplianceCache } = window?.history?.state ?? {};

const DASHBOARD_INITIAL_STATE = {
    companyCompliance: companyComplianceCache ?? [],
    assessmentCompliance: assessmentComplianceCache ?? [],
    shipCompliance: shipComplianceCache ?? [],
    userCompliance: userComplianceCache ?? [],
    ships: shipsCache ?? [],
    shipUsers: usersCache ?? [],
    userGroups: userGroupsCache ?? [],
};

const DASHBOARD_CLEAR_STATE = {
    companyCompliance: [],
    assessmentCompliance: [],
    shipCompliance: [],
    userCompliance: [],
    ships: [],
    shipUsers: [],
    userGroups: [],
};

const Dashboard = (state = DASHBOARD_INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_COMPANY_COMPLIANCE':
            return { ...state, companyCompliance: action.items };
        case 'SET_SHIP_COMPLIANCE':
            return { ...state, shipCompliance: { ...state.shipCompliance, ...action.items } };
        case 'SET_USER_COMPLIANCE':
            return { ...state, userCompliance: { ...state.userCompliance, ...action.items } };
        case 'SET_ASSESSMENT_COMPLIANCE':
            return { ...state, assessmentCompliance: { ...state.assessmentCompliance, ...action.items } };
        case 'SET_SHIPS':
            return { ...state, ships: action.items };
        case 'SET_SHIP_USERS':
            return { ...state, shipUsers: action.items };
        case 'SET_USER_GROUPS':
            return { ...state, userGroups: action.items };
        case 'INITIALISE_STORE':
            return DASHBOARD_CLEAR_STATE;
        case 'LOGGED_OUT':
            return DASHBOARD_INITIAL_STATE;
        default:
            return state;
    }
};

export default Dashboard;
