import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Paper, TextField, Typography, Button } from '@material-ui/core';
import html2canvas from 'html2canvas';
import { Cancel } from '@material-ui/icons';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import FeedbackController from '../../controllers/FeedbackController';
import { Alert } from '../../components/Common/Alert';
const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        width: '600px',
        bottom: '10px',
        right: '10px',
        zIndex: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 80px)',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 20px)',
        },
        '& .closeButton': {
            position: 'absolute',
            right: 0,
            top: 0,
        },
        '& .feedbackMessage': {
            margin: '20px 0px',
        },
        '& .screenshot': {
            maxHeight: '50vh',
            objectFit: 'contain',
        },
    },
}));

export default function Feedback({ open, onClose }) {
    const classes = useStyles();
    const [image, setImage] = React.useState(null);
    const [message, setMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [warning, setWarning] = React.useState(null);

    React.useEffect(() => {
        async function generateImage() {
            var node = document.getElementById('root');
            try {
                const canvas = await html2canvas(node, { allowTaint: false });
                setImage(canvas.toDataURL());
            } catch (e) {
                console.log(e);
            }
        }

        if (!open) {
            setImage(null);
        } else if (image === null) {
            generateImage();
        }
    }, [open, image, setImage]);

    async function submitFeedback() {
        if (message.trim().length === 0) {
            setWarning('Please enter a message');
            return;
        }

        setLoading(true);
        setWarning(null);
        //Convert image to blob
        const imageBlob = await (await fetch(image)).blob();
        const response = await FeedbackController.createTicket(message, imageBlob);
        if (response.hasError) {
            setWarning('Failed to submit ticket');
            setLoading(false);
            return;
        }

        setLoading(false);
        onClose();
    }

    if (!open || image === null) {
        return null;
    }

    return (
        <Paper className={classes.root}>
            <LoadingOverlay loading={loading} />
            <IconButton onClick={onClose} className="closeButton">
                <Cancel />
            </IconButton>
            <Typography variant="h3" align="center" gutterBottom>
                SubmitFeedback
            </Typography>
            <img src={image} alt="Screenshot" className="screenshot" />
            <TextField variant="outlined" label="Message" rows={3} multiline value={message} onChange={e => setMessage(e.target.value)} className="feedbackMessage" fullWidth />
            <Button variant="contained" onClick={submitFeedback}>
                Submit{' '}
            </Button>
            <Alert header="Something went wrong!" text={warning} />
        </Paper>
    );
}

Feedback.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
};
