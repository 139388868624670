import React from 'react';
import { makeStyles } from '@material-ui/core';
import './style.css';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: 'skeleton 3s infinite ease-out',
        borderRadius: 4,
        margin: 8,
    },
}));

export function Skeleton() {
    const classes = useStyles();

    return <div className={`${classes.root} skeleton`}></div>;
}
