import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { descendingComparator, isNullOrUndefined, scoreToPercentage } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import ComplianceController from '../../controllers/ComplianceController';

const useStyles = makeStyles(() => ({
    wrapper: {
        maxHeight: '100vh',
    },
    table: {
        maxHeight: '100vh',
        '& th': {
            fontWeight: 'bold',
        },
    },
}));

function OverviewAssessments(props) {
    const { Auth, PushHistory } = props;
    const { shipId } = queryString.parse(props.location.search);
    const [assessments, setAssessments] = React.useState([]);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    //Sorting
    const [orderRow, setOrderRow] = React.useState('totalAnswered');
    const [orderDirection, setOrderDirection] = React.useState('desc');

    const classes = useStyles();

    const loadAssessmentOverview = React.useCallback(async () => {
        const response = await ComplianceController.getAssessmentOverview(Auth.companyId, shipId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setAssessments(response.data);
        }
    }, [Auth, shipId]);

    // init & auth check
    React.useEffect(() => {
        // ensure user is signed in
        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }
        // fetch ships for inital render
        async function init() {
            await loadAssessmentOverview();
        }
        setLoading(true);
        init().then(() => setLoading(false));
    }, [Auth, loadAssessmentOverview]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    const handelSortChange = React.useCallback(
        newSort => {
            if (newSort === orderRow) {
                if (orderDirection === 'desc') {
                    setOrderDirection('asc');
                } else {
                    setOrderDirection('desc');
                }
                return;
            }
            setOrderRow(newSort);
            setOrderDirection('desc');
        },
        [orderRow, orderDirection, setOrderDirection, setOrderRow]
    );

    function buildTableHead() {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel active={orderRow === 'title'} direction={orderRow === 'title' ? orderDirection : 'desc'} onClick={() => handelSortChange('title')}>
                            Assessment
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderRow === 'totalAnswered'} direction={orderRow === 'totalAnswered' ? orderDirection : 'desc'} onClick={() => handelSortChange('totalAnswered')}>
                            Total Questions Answered
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel
                            active={orderRow === 'correctlyAnswered'}
                            direction={orderRow === 'correctlyAnswered' ? orderDirection : 'desc'}
                            onClick={() => handelSortChange('correctlyAnswered')}
                        >
                            Correct Answers
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel
                            active={orderRow === 'incorrectlyAnswered'}
                            direction={orderRow === 'incorrectlyAnswered' ? orderDirection : 'desc'}
                            onClick={() => handelSortChange('incorrectlyAnswered')}
                        >
                            Incorrect Answers
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderRow === 'totalMissed'} direction={orderRow === 'totalMissed' ? orderDirection : 'desc'} onClick={() => handelSortChange('totalMissed')}>
                            Missed Assessments
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                        <TableSortLabel
                            active={orderRow === 'correctionPercentage'}
                            direction={orderRow === 'correctionPercentage' ? orderDirection : 'desc'}
                            onClick={() => handelSortChange('correctionPercentage')}
                        >
                            Avg. Score
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    function buildAssessmentRow(question) {
        const { id, title, totalAnswered, correctlyAnswered, incorrectlyAnswered, correctionPercentage, totalMissed } = question;
        return (
            <TableRow key={id}>
                <TableCell>{title}</TableCell>
                <TableCell align="center">{totalAnswered}</TableCell>
                <TableCell align="center">{correctlyAnswered}</TableCell>
                <TableCell align="center">{incorrectlyAnswered}</TableCell>
                <TableCell align="center">{totalMissed}</TableCell>
                <TableCell align="right">{scoreToPercentage(correctionPercentage, 1)}%</TableCell>
            </TableRow>
        );
    }

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay loading={loading} />
            <Alert text={warning} />
            <Paper square>
                <TableContainer className={classes.table}>
                    <Table stickyHeader size="small">
                        {buildTableHead()}
                        <TableBody>
                            {assessments
                                ?.sort((a, b) => {
                                    return orderDirection === 'desc' ? descendingComparator(a, b, orderRow) : -descendingComparator(a, b, orderRow);
                                })
                                .map(buildAssessmentRow) ?? <></>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewAssessments);

OverviewAssessments.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    location: PropTypes.object,
};
