import React from 'react';
import SentiniLogo from '../../images/fathom-final.png';
import BridgeImage from '../../images/bridge.png';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles, TextField, Button, Grid, Container, FormControlLabel, Checkbox, Link, Typography } from '@material-ui/core';
import { isNullOrUndefined, checkPassword } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import { LogIn } from '../../stores/Actions/Authentication';
import { AuthLayout } from '../../components/Auth/AuthLayout';
import { CompanyRole, Role, ShipRole, versionNum } from '../../helpers/Constants';

const useStyles = makeStyles(() => ({
    formRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginBottom: 32,
        maxWidth: 300,
    },
    checkbox: {
        textAlign: 'center',
    },
}));

function Login(props) {
    const [userName, setUserName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [rememberMe, setRememberMe] = React.useState(false);
    const [twoFactorTokenRequired, setTwoFactorTokenRequired] = React.useState(false);
    const [twoFactorToken, setTwoFactorToken] = React.useState('');
    const [passwordResetRequired, setPasswordResetRequired] = React.useState(false);
    const [passwordReset, setPasswordReset] = React.useState('');
    const [passwordResetConfirmation, setPasswordResetConfirmation] = React.useState(null);
    const [passwordRequirements, setPasswordRequirements] = React.useState(null);
    const [passwordErrors, setPasswordErrors] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warningText, setWarningText] = React.useState(null);
    const classes = useStyles();

    // initialise
    React.useEffect(() => {
        async function init() {
            const pwordReqResponse = await UserController.getPasswordRequirements();
            if (!pwordReqResponse.hasError) {
                setPasswordRequirements(pwordReqResponse.data);
            } else {
                setWarningText('Failed to fetch password requirements');
            }
        }
        init();
    }, []);

    // redirect
    React.useEffect(() => {
        if (isNullOrUndefined(redirectUrl)) {
            return;
        }
        props.PushHistory(redirectUrl);
    }, [redirectUrl, props]);

    async function handleSubmit(event) {
        event.preventDefault();
        setSubmitting(true);
        setWarningText(null);

        const response = passwordResetRequired
            ? await UserController.loginWithPasswordReset(userName, password, rememberMe, twoFactorToken, passwordReset, passwordResetConfirmation)
            : await UserController.login(userName, password, rememberMe, twoFactorToken);
        if (!response.hasError) {
            const { userName, role, companyId, companyRole, shipRoles, id } = response.data;
            login(id, userName, role, companyId, companyRole, shipRoles);
        } else {
            if (response.data?.response?.data === 'TwoFactorRequired') {
                setTwoFactorTokenRequired(true);
            } else if (response.data?.response?.data === 'PasswordResetRequired') {
                setPasswordResetRequired(true);
            } else {
                setWarningText(response.data);
            }
            setSubmitting(false);
        }
    }

    function handleInput(event) {
        const { name, value, checked } = event.target;
        switch (name) {
            case 'userName':
                setUserName(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'twoFactorToken':
                setTwoFactorToken(value);
                break;
            case 'newPassword':
                setPasswordReset(value);
                break;
            case 'newPasswordConfirmation':
                setPasswordResetConfirmation(value);
                break;
            case 'rememberMe':
                setRememberMe(checked);
                break;
            default:
                return;
        }
    }

    function login(id, userName, role, companyId, companyRole, shipRoles) {
        if (!role.includes(Role.Admin) && companyRole === CompanyRole.CrewMember && !shipRoles.includes(ShipRole.Owner) && !shipRoles.includes(ShipRole.Captain)) {
            setRedirectUrl('/Logout?redirect=NoAccess');
        } else {
            props.LogIn({
                id,
                userName,
                role,
                isLoggingIn: false,
                isAuthenticated: true,
                companyId,
                companyRole,
                shipRoles,
            });
            setRedirectUrl('/');
        }
    }

    async function checkPasswordIsValid() {
        setPasswordErrors(checkPassword(passwordRequirements, password));
    }

    function buildPasswordErrors() {
        if (passwordErrors.length === 0) {
            return null;
        }
        return passwordErrors.map((e, i) => (
            <React.Fragment key={i}>
                <span>{e}</span>
                <br />
            </React.Fragment>
        ));
    }

    function buildTwoFactorTextField() {
        return twoFactorTokenRequired ? (
            <Grid item xs={12}>
                <TextField
                    id="twoFactorToken-input"
                    label="twoFactorToken"
                    type="twoFactorToken"
                    variant="filled"
                    required
                    defaultValue={twoFactorToken}
                    onChange={handleInput}
                    name="twoFactorToken"
                    fullWidth
                />
            </Grid>
        ) : null;
    }

    function buildPasswordResetForm() {
        return (
            <>
                <Grid item xs={12}>
                    <TextField
                        id="newPassword-input"
                        label="New Password"
                        type="password"
                        variant="filled"
                        required
                        value={passwordReset}
                        onChange={handleInput}
                        name="newPassword"
                        fullWidth
                        helperText={buildPasswordErrors()}
                        onBlur={() => checkPasswordIsValid()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="newPasswordConfirmation-input"
                        label="New Password Confirmation"
                        type="password"
                        variant="filled"
                        required
                        value={passwordResetConfirmation}
                        onChange={handleInput}
                        name="newPasswordConfirmation"
                        fullWidth
                    />
                </Grid>
                {buildTwoFactorTextField()}
            </>
        );
    }

    function buildLoginForm() {
        return (
            <>
                <Grid item xs={12}>
                    <TextField autoComplete="username" id="userName-input" label="User Name" type="text" required value={userName} onChange={handleInput} variant="filled" name="userName" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoComplete="current-password"
                        id="password-input"
                        label="Password"
                        type="password"
                        variant="filled"
                        required
                        value={password}
                        onChange={handleInput}
                        name="password"
                        fullWidth
                    />
                </Grid>
                {buildTwoFactorTextField()}
                <Grid item xs={12} className={classes.checkbox}>
                    <FormControlLabel control={<Checkbox checked={rememberMe} onChange={handleInput} name="rememberMe" color="secondary" />} label="Remember me?" />
                </Grid>
            </>
        );
    }

    function buildFormContent() {
        return (
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    {passwordResetRequired ? (
                        <>
                            {buildPasswordResetForm()}
                            <Grid item xs={12}>
                                <Alert severity="info" text="Password reset required. Please enter a new password." />
                            </Grid>
                        </>
                    ) : (
                        buildLoginForm()
                    )}
                    <Grid item xs={12}>
                        <Button fullWidth type="submit" disabled={submitting} variant="contained" color="primary">
                            Login
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert header="Login failed!" text={warningText} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="div" align="center">
                            <Link href="/ForgotPassword">Forgot password?</Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" align="center">
                            Build no. {versionNum}
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        );
    }

    return (
        <AuthLayout heroImage={BridgeImage}>
            <Container maxWidth="xs" className={classes.formRoot}>
                <img src={SentiniLogo} alt="Sentini Marine" className={classes.logo} />
                {buildFormContent()}
            </Container>
        </AuthLayout>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    LogIn: data => dispatch(LogIn(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    LogIn: PropTypes.func,
};
