const { shipsCache } = window?.history?.state ?? {};

const FLEET_INITIAL_STATE = {
    ships: shipsCache ?? [],
};

const Fleet = (state = FLEET_INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_SHIPS':
            return { ...state, ships: action.items };
        case 'LOGGED_OUT':
            return FLEET_INITIAL_STATE;
        default:
            return state;
    }
};

export default Fleet;
