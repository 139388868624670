import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';
import QuestionController from '../../controllers/QuestionController';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '16px 32px',
        [theme.breakpoints.down('xs')]: {
            padding: 16,
        },
    },
}));

function QuestionsPlainText(props) {
    const { Auth, PushHistory } = props;
    const [questions, setQuestions] = React.useState([]);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const classes = useStyles();

    const loadAllQuestions = React.useCallback(async () => {
        setLoading(true);
        const response = await QuestionController.getAllQuestions(Auth.companyId);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setQuestions(response.data);
        }
        setLoading(false);
    }, [Auth]);

    // init & auth check
    React.useEffect(() => {
        // ensure user is signed in
        const { isAuthenticated, isLoggingIn } = Auth;
        if (!isAuthenticated && !isLoggingIn) {
            setRedirectUrl('/Login');
        }
        // fetch ships for inital render
        async function init() {
            await loadAllQuestions();
        }
        init();
    }, [Auth, loadAllQuestions]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
        }
    }, [redirectUrl, PushHistory]);

    function buildQuestionItem(question) {
        const { id, title, answers } = question;
        return (
            <li key={id} className={classes.item}>
                <b>Q{id}</b> : {title}
                <ol>
                    {answers.map(e => (
                        <li key={e.id}>{e.title}</li>
                    ))}
                </ol>
            </li>
        );
    }

    return (
        <div className={classes.wrapper}>
            <LoadingOverlay loading={loading} />
            <Alert text={warning} />
            <ul>{questions.map(buildQuestionItem)}</ul>
        </div>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsPlainText);

QuestionsPlainText.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};
