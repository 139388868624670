import './app/Polyfill';
import 'pwacompat';
import App from './app/App';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './app/ServiceWorker';
import configureStore, { history } from './stores/Reducers/History';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider, createMuiTheme, CssBaseline } from '@material-ui/core';
import { Colours } from './helpers/Colours';

const store = configureStore();
const rootElement = document.getElementById('root');
const theme = createMuiTheme({
    typography: {
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        allVariants: {
            color: Colours.black,
        },
        body1: {
            color: Colours.bodyText,
        },
        body2: {
            color: Colours.bodyText,
        },
    },
    palette: {
        primary: {
            main: Colours.primary,
        },
        secondary: {
            main: Colours.secondary,
            contrastText: Colours.white,
        },
    },
    status: {
        danger: 'red',
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </MuiThemeProvider>,
    rootElement
);

registerServiceWorker();
