import React from 'react';
import * as signalR from '@microsoft/signalr';
import { Link } from 'react-router-dom';
import { CompanyRole, Role, SIGNALRPath } from '../../helpers/Constants';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge, Divider, Paper, Tooltip, ListItemIcon } from '@material-ui/core';
import NotificationsController from '../../controllers/NotificationsController';
import moment from 'moment';
import { isNullOrUndefined } from '../../helpers/Utils';
import UserController from '../../controllers/UserController';
import { AddNotif, AddNotifs, MarkAsRead, MarkAllAsRead, ClearAll } from '../../stores/Actions/Notifications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import GroupIcon from '@material-ui/icons/Group';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Colours } from '../../helpers/Colours';
import { DarkMenuItem } from '../../components/Common/DarkMenuItem';
import { Documents } from '../Documents/Documents';
import { Feedback, PermDeviceInformation, Business } from '@material-ui/icons';
import SendPushNotification from '../PushNotifcation/SendPushNotification';
import FeedbackDialog from './Feedback';
const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        maxHeight: '100vh',
    },
    drawer: {
        flexShrink: 0,
    },
    appBar: {
        position: 'fixed',
        left: 0,
        height: '100%',
        width: 50,
        paddingTop: 16,
        paddingBottom: 16,
        paddingRight: '0px !important',
        backgroundColor: Colours.white,
        [theme.breakpoints.down('xs')]: {
            width: `100%`,
            height: 50,
            padding: '0 16px',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        '&.hidden-nav': {
            display: 'none',
        },
    },
    menuButton: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'unset',
        },
    },
    drawerPaper: {
        width: drawerWidth,
        '& .MuiTypography-body1': {
            fontSize: 14,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
        },
    },
    content: {
        flexGrow: 1,
        minHeight: '100vh',
        paddingLeft: 50,
        '&.hidden-nav': {
            paddingLeft: 0 + ' !important',
            paddingTop: 0 + ' !important',
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingTop: 50,
        },
    },
    avatarButton: {
        minWidth: 'unset',
        borderRadius: 64,
        padding: 0,
        right: 0,
        top: 0,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 8,
        },
    },
    notifButton: {},
    notifWrapper: {
        maxHeight: 500,
        overflowY: 'auto',
    },
    notif: {
        position: 'relative',
        padding: '12px 16px',
        width: 260,
    },
    notifViewAll: {
        position: 'absolute',
        top: 14,
        right: 16,
    },
    permanentTools: {
        display: 'flex',
        flexDirection: 'column-reverse',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'row',
            height: '100%',
            width: 'unset',
        },
    },
    desktopButton: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}));

/**
 * TODO: refactor to separate SignalR concerns?
 */
function NavMenu(props) {
    const { PushHistory, AddNotif, AddNotifs, MarkAsRead, MarkAllAsRead, Notifications, container, ClearAllNotifs } = props;
    const { userName, isAuthenticated, role, companyRole } = props.LogIn;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [userMenuOpen, setUserMenuOpen] = React.useState(false);
    const [notifTrayOpen, setNotifTrayOpen] = React.useState(false);
    const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
    const [notifAnchorEl, setNotifAnchorEl] = React.useState(null);
    const [showBadge, setShowBadge] = React.useState(false);
    const [totalUnread, setTotalUnread] = React.useState(0);
    const [redirectUrl, setRedirectUrl] = React.useState(null);
    const [hubConnection, setHubConnection] = React.useState(null);
    const [showDocs, setShowDocs] = React.useState(false);
    const [showPushNotif, setShowPushNotif] = React.useState(false);
    const [showFeedbackScreen, setShowFeedbackScreen] = React.useState(false);

    const hasCompany = () => !isNullOrUndefined(props.LogIn.companyId);

    // initialise data
    React.useEffect(() => {
        async function init() {
            const totalResponse = await NotificationsController.getUnreadCount();
            if (!totalResponse.hasError) {
                setTotalUnread(totalResponse.data);
                if (totalResponse.data > 0) {
                    setShowBadge(true);
                }
            }
            const notifsResponse = await NotificationsController.getTop();
            if (!notifsResponse.hasError) {
                AddNotifs(notifsResponse.data);
            }
        }
        if (isAuthenticated) {
            init();
        }
    }, [AddNotifs, isAuthenticated]);

    // intialise signalR
    React.useEffect(() => {
        async function cleanUpNotifications() {
            await hubConnection.stop();
            setHubConnection(null);
            ClearAllNotifs();
            console.log('Cleaned up notifs');
        }

        if (isAuthenticated && hubConnection === null) {
            const options = {
                accessTokenFactory: UserController.getToken,
            };
            const connection = new signalR.HubConnectionBuilder().withUrl(SIGNALRPath.Notifications, options).withAutomaticReconnect().build();

            connection.on('MarkAsRead', MarkAsRead);
            connection.on('MarkAllAsRead', MarkAllAsRead);
            connection.on('NewNotification', AddNotif);
            connection.start().catch(err => console.error(err));
            setHubConnection(connection);
        } else if (!isAuthenticated && hubConnection !== null) {
            cleanUpNotifications();
        }
    }, [AddNotif, MarkAsRead, MarkAllAsRead, ClearAllNotifs, hubConnection, isAuthenticated]);

    // redirect
    React.useEffect(() => {
        if (!isNullOrUndefined(redirectUrl)) {
            PushHistory(redirectUrl);
            setRedirectUrl(null);
        }
    }, [redirectUrl, PushHistory]);

    // notif count
    React.useEffect(() => {
        async function recalculateUnread() {
            let unread = 0;
            const totalResponse = await NotificationsController.getUnreadCount();
            if (!totalResponse.hasError) {
                unread = totalResponse.data;
            }
            setTotalUnread(unread);
        }
        if (isAuthenticated) {
            recalculateUnread();
        }
    }, [Notifications, isAuthenticated]);

    function getHiddenNavClass() {
        if (!isAuthenticated) {
            return 'hidden-nav';
        }
        return '';
    }

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
        handleUserMenuClose();
        handleNotifTrayClose();
    }

    function handleUserMenuToggle(event) {
        setUserMenuOpen(!userMenuOpen);
        setProfileAnchorEl(event.currentTarget);
        handleNotifTrayClose();
    }

    function handleUserMenuClose() {
        setUserMenuOpen(false);
        setProfileAnchorEl(null);
    }

    function handleNotifTrayToggle(event) {
        if (!notifTrayOpen) {
            markUnreadAsRead();
            setShowBadge(false);
        }
        setNotifTrayOpen(!notifTrayOpen);
        setNotifAnchorEl(event.currentTarget);
        handleUserMenuClose();
    }

    function handleNotifTrayClose() {
        setNotifTrayOpen(false);
        setNotifAnchorEl(null);
    }

    function viewAllNotifs() {
        handleNotifTrayClose();
        setRedirectUrl('/Notifications');
    }

    function handleSubmitFeedback() {
        setMobileOpen(false);
        setShowFeedbackScreen(true);
        //BugBattle.startBugReporting();
    }

    function markUnreadAsRead() {
        NotificationsController.markAllAsRead();
        MarkAllAsRead();
        setTotalUnread(0);
    }

    function buildDrawerOptionLink(name, route, icon, key = null) {
        return (
            <ListItem button component={Link} to={route} key={key ?? name} onClick={handleDrawerToggle}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        );
    }

    function buildDrawerOptionFunc(name, func, icon, key = null) {
        return (
            <ListItem
                button
                key={key ?? name}
                onClick={() => {
                    func();
                    handleDrawerToggle();
                }}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        );
    }

    function buildDrawerOptionOnClick(name, func, icon, key = null) {
        return (
            <ListItem button key={key ?? name} onClick={func}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        );
    }

    function buildDrawerOptions() {
        const drawerOptions = [];
        if (userName) {
            drawerOptions.push(buildDrawerOptionLink('Dashboard', '/', <DashboardIcon />));
            drawerOptions.push(buildDrawerOptionLink('Fleet', '/Fleet', <DirectionsBoatIcon />));
            drawerOptions.push(buildDrawerOptionLink('Users', '/Users', <GroupIcon />));
            if (role.includes(Role.Admin)) {
                drawerOptions.push(buildDrawerOptionLink('Companies', '/Company', <Business />));
                drawerOptions.push(buildDrawerOptionLink('Compliance Management', '/Compliance', <HelpOutlineIcon />));
            }
            if (role.includes(Role.Admin) || companyRole === CompanyRole.Owner) {
                drawerOptions.push(buildDrawerOptionFunc('Document Management', () => setShowDocs(true), <FileCopyIcon />));
                drawerOptions.push(buildDrawerOptionFunc('Push Notification', () => setShowPushNotif(true), <PermDeviceInformation />));
            }
            if (role.includes(Role.Admin)) {
                drawerOptions.push(buildDrawerOptionOnClick('Submit Feedback', handleSubmitFeedback, <Feedback />));
            }
        }
        return <List>{drawerOptions}</List>;
    }

    function buildProfileOptions() {
        return userName ? (
            <>
                <Button className={classes.avatarButton} onClick={handleUserMenuToggle} aria-controls="simple-menu" aria-haspopup="true">
                    <Avatar>{userName.charAt(0).toUpperCase()}</Avatar>
                </Button>
                <Menu id="user-menu" anchorEl={profileAnchorEl} keepMounted open={Boolean(userMenuOpen)} onClose={handleUserMenuClose}>
                    <DarkMenuItem onClick={handleUserMenuClose} component={Link} to="/UserManagement" key="userManagement">
                        Profile
                    </DarkMenuItem>
                    <DarkMenuItem onClick={handleUserMenuClose} component={Link} to="/Logout" key="logout">
                        Logout
                    </DarkMenuItem>
                </Menu>
            </>
        ) : null;
    }

    function buildNotificationTray() {
        return userName ? (
            <>
                <Tooltip title="Notifications" placement="right">
                    <IconButton className={classes.notifButton} onClick={handleNotifTrayToggle} aria-controls="simple-menu" aria-haspopup="true" color="inherit">
                        <Badge badgeContent={showBadge ? totalUnread : null} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Menu id="notif-tray" anchorEl={notifAnchorEl} keepMounted open={Boolean(notifTrayOpen)} onClose={handleNotifTrayClose} className={classes.notifWrapper}>
                    {[
                        <Paper elevation={0} key="title-paper" className={classes.notif}>
                            <Typography variant="h5">Notifications</Typography>
                            <Button onClick={() => viewAllNotifs()} size="small" variant="outlined" className={classes.notifViewAll} disabled={Notifications.length === 0}>
                                View All
                            </Button>
                        </Paper>,
                        <Divider key="title-divider" />,
                        buildNotifications(),
                    ]}
                </Menu>
            </>
        ) : null;
    }

    function buildNotifications() {
        if (Notifications.length === 0) {
            return (
                <Paper elevation={0} key="no-notifs" className={classes.notif}>
                    <Typography variant="body1" color="textSecondary">
                        No notifications
                    </Typography>
                </Paper>
            );
        }
        return <div key="notifs">{Notifications.map((e, i) => buildNotification(e, i))}</div>;
    }

    function buildNotification(notif, index) {
        const isLastIndex = index === Notifications.length - 1;
        const { title, message, created } = notif;
        return (
            <div key={index}>
                <Paper elevation={0} className={classes.notif}>
                    <Typography variant="body1" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {message}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {moment.utc(created).local().format('D MMMM YYYY')}
                    </Typography>
                </Paper>
                {!isLastIndex ? <Divider /> : null}
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <FeedbackDialog
                open={showFeedbackScreen}
                onClose={() => {
                    setShowFeedbackScreen(false);
                }}
            />
            <AppBar position="fixed" color="default" className={`${classes.appBar} ${getHiddenNavClass()}`}>
                <IconButton color="inherit" aria-label="Open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                    <MenuIcon />
                </IconButton>
                <div className={classes.permanentTools}>
                    {buildNotificationTray()}
                    {buildProfileOptions()}
                </div>
                <Tooltip title="Dashboard" placement="right">
                    <IconButton color="inherit" aria-label="Dashboard" onClick={() => setRedirectUrl('/')} className={classes.desktopButton}>
                        <DashboardIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Fleet" placement="right">
                    <IconButton color="inherit" aria-label="Fleet" onClick={() => setRedirectUrl('/Fleet')} className={classes.desktopButton}>
                        <DirectionsBoatIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Users" placement="right">
                    <IconButton color="inherit" aria-label="Users" onClick={() => setRedirectUrl('/Users')} className={classes.desktopButton}>
                        <GroupIcon />
                    </IconButton>
                </Tooltip>
                {role.includes(Role.Admin) ? (
                    <>
                        <Tooltip title="Companies" placement="right">
                            <IconButton color="inherit" aria-label="Companies" onClick={() => setRedirectUrl('/Company')} className={classes.desktopButton}>
                                <Business />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Compliance Management" placement="right">
                            <IconButton color="inherit" aria-label="Compliance Management" onClick={() => setRedirectUrl('/Compliance')} className={classes.desktopButton}>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : null}
                {role.includes(Role.Admin) || companyRole === CompanyRole.Owner ? (
                    <>
                        {' '}
                        <Tooltip title="Document Management" placement="right">
                            <IconButton color="inherit" aria-label="Document Management" onClick={() => setShowDocs(true)} className={classes.desktopButton}>
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Push Notification" placement="right">
                            <IconButton color="inherit" aria-label="Push Notification" onClick={() => setShowPushNotif(true)} className={classes.desktopButton}>
                                <PermDeviceInformation />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : null}
                {role.includes(Role.Admin) ? (
                    <Tooltip title="Submit Feedback" placement="right">
                        <IconButton color="inherit" aria-label="Submit Feedback" onClick={handleSubmitFeedback} className={classes.desktopButton}>
                            <Feedback />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </AppBar>
            <nav className={classes.drawer} aria-label="Mailbox folders">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{ paper: classes.drawerPaper }}
                    ModalProps={{ keepMounted: true }}
                >
                    {buildDrawerOptions()}
                </Drawer>
            </nav>
            <main className={`${classes.content} ${getHiddenNavClass()}`}>{props.children}</main>
            {hasCompany() ? (
                <>
                    <Documents open={showDocs} onClose={() => setShowDocs(false)} companyId={props.LogIn.companyId} />
                    <SendPushNotification
                        open={showPushNotif}
                        onClose={() => {
                            setShowPushNotif(false);
                        }}
                        companyId={props.LogIn.companyId}
                    />
                </>
            ) : null}
        </div>
    );
}

const mapStateToProps = state => ({
    LogIn: state.Authentication,
    Notifications: state.Notifications,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    AddNotif: item => dispatch(AddNotif(item)),
    AddNotifs: items => dispatch(AddNotifs(items)),
    MarkAsRead: id => dispatch(MarkAsRead(id)),
    MarkAllAsRead: () => dispatch(MarkAllAsRead()),
    ClearAllNotifs: () => dispatch(ClearAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);

NavMenu.propTypes = {
    container: PropTypes.object,
    children: PropTypes.any,
    LogIn: PropTypes.object,
    PushHistory: PropTypes.func,
    AddNotif: PropTypes.func,
    AddNotifs: PropTypes.func,
    MarkAsRead: PropTypes.func,
    MarkAllAsRead: PropTypes.func,
    Notifications: PropTypes.array,
    ClearAllNotifs: PropTypes.func,
};
