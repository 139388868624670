import React from 'react';
import PropTypes from 'prop-types';
import UserController from '../../controllers/UserController';
import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Button, Grid, Container, Typography, ListItemAvatar, Avatar, ListItemText, ListItem, List, Card } from '@material-ui/core';
import { Alert } from '../../components/Common/Alert';
import { isNullOrUndefined } from '../../helpers/Utils';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MailIcon from '@material-ui/icons/Mail';
import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';

function Profile(props) {
    const [userName, setUserName] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [appRoles, setAppRoles] = React.useState([]);
    const [companyRoles, setCompanyRoles] = React.useState([]);
    const [shipRoles, setShipRoles] = React.useState([]);
    const [warningText, setWarningText] = React.useState(null);
    const [hideEmailButton, setHideEmailButton] = React.useState(false);
    const [emailConfirmed, setEmailConfirmed] = React.useState(false);
    const [hasTwoFactorAuth, setHasTwoFactorAuth] = React.useState(false);
    const [joined, setJoined] = React.useState('');
    const [emailConfirmationSent, setEmailConfirmationSent] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    // initialise
    React.useEffect(() => {
        async function init() {
            if (!props.Auth.isAuthenticated) {
                props.PushHistory('/Login');
                return;
            }
            const cachedInfoResponse = await UserController.fetchCachedUserData();
            if (!cachedInfoResponse.hasError) {
                const { companyRole, shipRoles } = cachedInfoResponse.data;
                setCompanyRoles(!isNullOrUndefined(companyRole) ? [companyRole] : []);
                setShipRoles(shipRoles);
            }
            console.log(cachedInfoResponse);
            const userInfoResponse = await UserController.getUserInfo();
            if (!userInfoResponse.hasError) {
                const { userName, email, emailConfirmed, joined, hasTwoFactor } = userInfoResponse.data;
                setUserName(userName);
                setEmail(email);
                setEmailConfirmed(emailConfirmed);
                setJoined(joined);
                setHasTwoFactorAuth(hasTwoFactor);
                setAppRoles(props.Auth.role);
            } else {
                setWarningText(userInfoResponse.data);
            }
            setLoading(false);
        }
        init();
    }, [props]);

    async function requestNewEmailConfirmation() {
        setHideEmailButton(true);
        setWarningText(null);
        const response = await UserController.requestEmailConfirmation();
        if (!response.hasError) {
            setEmailConfirmed(false);
            setEmailConfirmationSent(true);
        } else {
            setEmailConfirmed(false);
            setHideEmailButton(false);
            setWarningText(response.data);
        }
    }

    function getRoleName(role) {
        switch (role) {
            case 'UserInpersinator':
                return 'UserImpersonator';
            default:
                return role;
        }
    }

    function buildListItem(icon, primary, secondary = null) {
        return (
            <ListItem dense>
                <ListItemAvatar>
                    <Avatar>{icon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={primary} secondary={secondary} />
            </ListItem>
        );
    }

    function buildEmailRequestLink() {
        const emailSent = !(!emailConfirmed && !hideEmailButton && !emailConfirmationSent);
        return (
            <Button variant="contained" color="primary" onClick={requestNewEmailConfirmation} fullWidth disabled={emailSent}>
                {emailSent ? 'Email Sent' : 'Request Email Confirmation'}
            </Button>
        );
    }

    function buildRolesDisplay() {
        let rolesDisplay;
        const allRoles = [...appRoles, ...companyRoles, ...shipRoles];
        if (!isNullOrUndefined(allRoles) && allRoles.length > 0) {
            rolesDisplay = allRoles.map((role, i) => (
                <span key={i}>
                    {getRoleName(role)}
                    {i < allRoles.length - 1 ? ', ' : ''}
                </span>
            ));
        }
        return rolesDisplay ?? 'No Roles';
    }

    return (
        <Container maxWidth="xs" style={{ marginTop: 32 }}>
            <LoadingOverlay loading={loading} />

            <Typography variant="h2" align="center" gutterBottom>
                Profile
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <List>
                            {buildListItem(<PersonIcon />, 'User Name', userName)}
                            {buildListItem(<MailIcon />, email, emailConfirmed ? 'Confirmed' : 'Not Confirmed')}
                            {buildListItem(<AccessTimeIcon />, 'Date Joined', moment(joined).format('Do MMMM YYYY'))}
                            {buildListItem(<WorkIcon />, 'Roles', buildRolesDisplay())}
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Alert text={warningText} />
                </Grid>
                <Grid item xs={12}>
                    <Button component={Link} to="/ChangePassword" color="primary" variant="contained" fullWidth>
                        Change Password
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    {buildEmailRequestLink()}
                </Grid>
                <Grid item xs={12}>
                    <Button component={Link} to="/ManageTwoFactor" color="primary" variant="contained" fullWidth>
                        {!hasTwoFactorAuth ? 'Add Two Factor Auth' : 'Remove Two Factor Auth'}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

Profile.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
};
