import React from 'react';
import PropTypes from 'prop-types';
import { Container, makeStyles } from '@material-ui/core';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100%',
        backgroundColor: Colours.offWhite,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    centered: {
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
    },
    leftSide: {
        flexGrow: 1,
        backgroundImage: props => `url(${props.heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 200,
        },
    },
    rightSide: {
        flexGrow: 1,
        maxWidth: '45%',
        backgroundColor: Colours.offWhite,
        [theme.breakpoints.down('md')]: {
            paddingTop: 40,
            paddingBottom: 40,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}));

export function AuthLayout(props) {
    const { children } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <div className={classes.leftSide} />
            <Container className={`${classes.rightSide} ${classes.centered}`}>{children}</Container>
        </div>
    );
}

AuthLayout.propTypes = {
    heroImage: PropTypes.string.isRequired,
    children: PropTypes.any,
};
