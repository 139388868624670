const AUTH_INITIAL_STATE = {
    id: null,
    isAuthenticated: false,
    userName: '',
    role: [],
    isLoggingIn: true,
    companyId: null,
    companyRole: null,
    shipRoles: [],
};

const Authentication = (state = AUTH_INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGGED_IN':
            return Object.assign({}, state, action.data);
        case 'LOGGED_OUT':
            return Object.assign({}, state, {
                id: null,
                isAuthenticated: false,
                userName: '',
                role: [],
                isLoggingIn: false,
                companyId: null,
                companyRole: null,
                shipRoles: [],
            });
        default:
            return state;
    }
};

export default Authentication;
