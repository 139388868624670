import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, FormControl, InputLabel } from '@material-ui/core';
import { isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import CompanyController from '../../controllers/CompanyController';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { DarkMenuItem } from '../../components/Common/DarkMenuItem';
import moment from 'moment';

export default function UserRestPeriod(props) {
    const { user, open, onClose, companyId } = props;
    const [restPeriodDates, setRestPeriodDates] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState('');
    const [documentDate, setDocumentDate] = React.useState('');
    const [documentUrl, setDocumentLink] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [warning, setWarning] = React.useState(null);

    const loadRestPeriods = React.useCallback(async () => {
        const response = await CompanyController.getUsersRestPeriodDate(companyId, user.id);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setRestPeriodDates(response.data.dates);
        }
    }, [companyId, user]);

    // initialise when user or open state changes
    React.useEffect(() => {
        async function init() {
            setSelectedDate('');
            await loadRestPeriods();
        }

        if (open) {
            setLoading(true);
            init().then(() => setLoading(false));
        }
    }, [user, open, loadRestPeriods]);

    const generateDocument = React.useCallback(async () => {
        const response = await CompanyController.getUsersRestPeriodDocument(companyId, user.id, selectedDate);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setDocumentDate(selectedDate);
            setDocumentLink(response.data);
        }
    }, [companyId, user, selectedDate, setDocumentLink, setDocumentDate]);

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function handelClose() {
        setDocumentLink(null);
        setSelectedDate('');
        setDocumentDate('');

        onClose();
    }

    function buildErrorDialog() {
        return (
            <Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Alert header="Something went wrong!" text={warning} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setWarning(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    let link;
    if (documentUrl != null) {
        const blob = new Blob([base64ToArrayBuffer(documentUrl)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        link = window.URL.createObjectURL(blob);
    }
    return (
        <>
            <Dialog open={open} maxWidth="xs" fullWidth>
                <DialogTitle>User Rest Periods</DialogTitle>
                <DialogContent>
                    <LoadingOverlay loading={loading} />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="filled">
                                <InputLabel id="select-date-label">Rest Period Start Date</InputLabel>
                                <Select variant="filled" labelId="select-date-label" value={selectedDate} onChange={e => setSelectedDate(e.target.value)} required fullWidth>
                                    <DarkMenuItem key="default" value="">
                                        {restPeriodDates.length === 0 ? 'No rest period uploads' : 'Please select a date'}
                                    </DarkMenuItem>
                                    {restPeriodDates.map(e => (
                                        <DarkMenuItem key={e} value={e}>
                                            {moment(e).format('MM/YYYY')}
                                        </DarkMenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={generateDocument} disabled={selectedDate === '' || selectedDate === documentDate} fullWidth>
                                Generate
                            </Button>
                        </Grid>
                        {documentUrl !== null ? (
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={`${user.firstName}-${user.lastName}-${moment(documentDate).format('MM-YYYY')}.xlsx`}
                                    fullWidth
                                >
                                    Download {moment(documentDate).format('MM/YYYY')}
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handelClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {buildErrorDialog()}
        </>
    );
}

UserRestPeriod.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object,
    companyId: PropTypes.string.isRequired,
};
