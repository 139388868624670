import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, TextField, Typography } from '@material-ui/core';
import { isNullOrEmpty, isNullOrUndefined } from '../../helpers/Utils';
import { Alert } from '../../components/Common/Alert';
import ComplianceController from '../../controllers/ComplianceController';
import UserComplianceTestLevelController from '../../controllers/UserComplianceTestLevelController';

const useStyles = makeStyles(() => ({
    selectArea: {
        '& .MuiInputBase-root': {
            paddingTop: 23,
            paddingBottom: 4,
        },
    },
    listItem: {
        borderBottom: `1px solid #eee`,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 8,
        paddingBottom: 8,
        '& .text-wrapper': {
            width: '80%',
        },
        '& .MuiTextField-root': {
            maxWidth: '20%',
        },
        '& .MuiInput-underline:before ': {
            border: 'none',
        },
    },
}));

export default function UsersAssessmentLevels(props) {
    const { user, open, onClose, companyId } = props;
    const [complianceTests, setComplianceTests] = React.useState([]);
    const [warning, setWarning] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);

    const classes = useStyles();

    React.useEffect(() => {
        if (!user) return;

        async function init() {
            const assessments = await loadUserComplianceTests(companyId);
            if (!isNullOrEmpty(assessments)) {
                await loadComplianceTestLevels(assessments);
            }
        }
        init().then(() => setLoading(false));
    }, [user, open]);

    const loadUserComplianceTests = async () => {
        const response = await ComplianceController.getUserComplianceTests(companyId, user?.id);
        if (response.hasError) {
            setWarning(response.data);
            setLoading(false);
        } else {
            setComplianceTests(response.data);
            return response.data;
        }
        return [];
    };

    const loadComplianceTestLevels = async assessments => {
        const response = await UserComplianceTestLevelController.getUserComplianceTestLevels(
            user.id,
            assessments.map(e => e.id)
        );
        if (response.hasError) {
            setWarning(response.data);
            setLoading(false);
        } else {
            const newComplianceTests = [];

            for (let i = 0; i < assessments.length; i++) {
                const test = assessments[i];
                const userLevel = response.data.find(l => l.complianceTestId === test.id);
                const level = test.complianceTestLevels.find(e => e.id === userLevel?.testLevelId);
                newComplianceTests.push({ ...test, userTestLevel: !isNullOrUndefined(level) ? level?.levelIndex : null, userTestLevelId: level?.id });
            }
            setComplianceTests(newComplianceTests);
        }
    };

    const saveUserComplianceTestLevels = async () => {
        setSaving(true);
        const testLevelIds = complianceTests.map(e => e.userTestLevelId);
        const complianceIds = complianceTests.map(e => e.id);

        const response = await UserComplianceTestLevelController.editUserComplianceTestLevels(user.id, testLevelIds, complianceIds);
        if (response.hasError) {
            setWarning(response.data);
        } else {
            setSaving(false);
            onClose();
        }
        setSaving(false);
    };

    const handleChangeUserTestLevel = (e, complianceTestId) => {
        const value = e.target.value;

        if (!/^[0-9]+$/.test(value)) return;

        const testLevelId = complianceTests.find(e => e.id === complianceTestId).complianceTestLevels.find(e => e.levelIndex == value)?.id;

        if (isNullOrUndefined(testLevelId) && value != 0) return;

        const newArray = [...complianceTests.map(e => (e.id == complianceTestId ? { ...e, userTestLevel: value, userTestLevelId: testLevelId } : e))];
        setComplianceTests(newArray);
    };

    function buildErrorDialog() {
        return (
            <Dialog open={!isNullOrUndefined(warning)} onClose={() => setWarning(null)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Alert header="Something went wrong!" text={warning} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setWarning(null)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <>
            <Dialog open={open} maxWidth="md">
                <DialogTitle>Edit User Assessment Levels</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {!loading
                                ? complianceTests
                                      .filter(e => e.complianceTestLevels.length !== 0)
                                      .map((test, i) => {
                                          return (
                                              <Grid key={i} item xs={12}>
                                                  <div className={classes.listItem}>
                                                      <div className="text-wrapper">
                                                          <Typography variant="body2">{test.title}</Typography>
                                                      </div>
                                                      <TextField variant="outlined" type="number" onChange={e => handleChangeUserTestLevel(e, test.id)} value={test.userTestLevel ?? 0}></TextField>
                                                  </div>
                                              </Grid>
                                          );
                                      })
                                : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={saveUserComplianceTestLevels} disabled={saving || loading} type="submit">
                        Save
                    </Button>
                    <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {buildErrorDialog()}
        </>
    );
}

UsersAssessmentLevels.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isMe: PropTypes.bool.isRequired,
    user: PropTypes.object,
    shipsArray: PropTypes.array,
    companyId: PropTypes.string.isRequired,
};
