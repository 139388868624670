import React from 'react';

import { Place, MenuBook, LocalHospital, Directions, DirectionsBoat } from '@material-ui/icons';
// This must MATCH 1-1 with customIcon.dart in the mobile app

export default function getCustomIcon(iconType) {
    switch (iconType) {
        case 0:
            return <Place />;
        case 1:
            return <MenuBook />;
        case 2:
            return <LocalHospital />;
        case 3:
            return <Directions />;
        case 4:
            return <DirectionsBoat />;
        default:
            return <Place />;
    }
}
