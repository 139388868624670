import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserController from '../controllers/UserController';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Layout } from '../domain/Layout/Layout';
import { LogIn, LogOut } from '../stores/Actions/Authentication';
import { IndexedDB } from '../helpers/IndexedDB';
import { isNullOrUndefined } from '../helpers/Utils';
import { PrivateRoute } from '../components/Common/PrivateRoute';
import { Role } from '../helpers/Constants';

// Pages
import Login from '../domain/Auth/Login';
import Logout from '../domain/Auth/Logout';
import FileManager from '../domain/User/FileManager';
import Users from '../domain/Users/Users';
import Profile from '../domain/User/Profile';
import PasswordChange from '../domain/User/PasswordChange';
import EmailConfirm from '../domain/User/EmailConfirm';
import EmailChange from '../domain/User/EmailChange';
import EmailChangeConfirm from '../domain/User/EmailChangeConfirm';
import PasswordForgot from '../domain/User/PasswordForgot';
import PasswordReset from '../domain/User/PasswordReset';
import TwoFactorManager from '../domain/User/TwoFactorManager';
import TwoFactorVerify from '../domain/User/TwoFactorVerify';
import NotFound from '../domain/Error/NotFound';
import NotificationList from '../domain/User/NotificationList';
import Dashboard from '../domain/Dashboard/Dashboard';
import UsersGroups from '../domain/Users/UsersGroups';
import Fleet from '../domain/Fleet/Fleet';
import Company from '../domain/Company/Company';
import Compliance from '../domain/Compliance/Compliance';
import ComplianceCategory from '../domain/Compliance/ComplianceCategory';
import Register from '../domain/Auth/Register';
import NoAccess from '../domain/Auth/NoAccess';
import QuestionsPlainText from '../domain/Compliance/QuestionsPlainText';
import OverviewQuestions from '../domain/Dashboard/OverviewQuestions';
import OverviewAssessments from '../domain/Dashboard/OverviewAssessments';
import OverviewUserGroups from '../domain/Dashboard/OverviewUserGroups';

class App extends Component {
    constructor(props) {
        super(props);
        this.initTokenStore(props);
    }

    initTokenStore = async props => {
        // initialise local data store
        const init = await IndexedDB.init();
        if (init.hasError) {
            console.warn(init.data);
            return;
        }
        // check if existing token is valid
        if (await UserController.hasTokenExpired()) {
            props.LogOut();
            return;
        }
        // check for existing token
        const userData = await UserController.fetchCachedUserData();
        if (userData.hasError) {
            console.warn(userData.data);
            props.LogOut();
            return;
        }
        // save cached data if any
        const { userName, role, companyId, companyRole, shipRoles, id } = userData.data;
        if (!isNullOrUndefined(userName)) {
            props.LogIn({
                userName,
                role,
                isLoggingIn: false,
                isAuthenticated: true,
                companyId,
                companyRole,
                shipRoles,
                id,
            });
        } else {
            props.LogOut();
        }
    };

    render() {
        if (this.props.Auth.isLoggingIn) {
            return <div />;
        }
        return (
            <Layout>
                <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/MagicRegistration" component={Register} />
                    <Route path="/Login" component={Login} />
                    <Route path="/Logout" component={Logout} />
                    <Route path="/NoAccess" component={NoAccess} />
                    <Route path="/Users" component={Users} />
                    <Route path="/UserGroups" component={UsersGroups} />
                    <Route path="/Fleet" component={Fleet} />
                    <Route path="/Company" component={Company} />
                    <PrivateRoute path="/Compliance" component={Compliance} role={Role.Admin} roles={this.props.Auth.role} />
                    <PrivateRoute path="/ComplianceAllQuestions" component={QuestionsPlainText} role={Role.Admin} roles={this.props.Auth.role} />
                    <PrivateRoute path="/ComplianceCategory" component={ComplianceCategory} role={Role.Admin} roles={this.props.Auth.role} />
                    <Route path="/OverviewQuestions" component={OverviewQuestions} />
                    <Route path="/OverviewAssessments" component={OverviewAssessments} />
                    <Route path="/OverviewUserGroups" component={OverviewUserGroups} />
                    <Route path="/Notifications" component={NotificationList} />
                    <Route path="/FileManagement" component={FileManager} />
                    <Route path="/UserManagement" component={Profile} />
                    <Route path="/ChangePassword" component={PasswordChange} />
                    <Route path="/EmailConfirmation" component={EmailConfirm} />
                    <Route path="/ChangeEmail" component={EmailChange} />
                    <Route path="/ChangeEmailConfirmation" component={EmailChangeConfirm} />
                    <Route path="/ForgotPassword" component={PasswordForgot} />
                    <Route path="/ResetPassword" component={PasswordReset} />
                    <Route path="/ManageTwoFactor" component={TwoFactorManager} />
                    <Route path="/VerifyTwoFactor" component={TwoFactorVerify} />
                    <Route path="" component={NotFound} />
                </Switch>
            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    Auth: state.Authentication,
});

const mapDispatchToProps = dispatch => ({
    LogIn: data => dispatch(LogIn(data)),
    LogOut: () => dispatch(LogOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
    Auth: PropTypes.object,
    LogIn: PropTypes.func,
    LogOut: PropTypes.func,
};
