import Axios from 'axios';
import UserController from './UserController';
import { APIRoute, APIPath } from '../helpers/Constants';
import { generateResponse } from '../helpers/Utils';

const DocumentController = {
    getCompanyRootCategories,
    getCompanyCategoryChildren,
    getCompanyCategoryItem,
    getCompanyDocumentItems,
    createCategory,
    editCategory,
    deleteCategory,
    addShip,
    removeShip,
    addUserGroup,
    removeUserGroup,
    addDocumentItem,
    editDocumentItem,
    deleteDocumentItem,
    attachDocumentItem,
    removeDocumentItem,
};

async function getCompanyRootCategories(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANY_ROOT_CATEGORIES(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCompanyCategoryChildren(companyId, categoryId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANY_CATEGORY_CHILDREN(companyId, categoryId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCompanyCategoryItem(companyId, categoryId, itemId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANY_CATEGORY_ITEM(companyId, categoryId, itemId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function getCompanyDocumentItems(companyId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.GET_COMPANY_DOCUMENT_ITEMS(companyId), userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function createCategory(companyId, title, description, parentCategoryId) {
    const userHeaders = await UserController.getUserHeaders();
    const categoryModel = {
        title,
        description,
        parentCategoryId,
    };
    return Axios.post(APIRoute + APIPath.CREATE_CATEGORY(companyId), categoryModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editCategory(companyId, categoryId, title, description, parentCategoryId) {
    const userHeaders = await UserController.getUserHeaders();
    const categoryModel = {
        id: categoryId,
        title,
        description,
        parentCategoryId,
    };
    return Axios.post(APIRoute + APIPath.EDIT_CATEGORY(companyId), categoryModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function deleteCategory(companyId, categoryId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_CATEGORY(companyId, categoryId), null, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addShip(companyId, categoryId, shipId) {
    const userHeaders = await UserController.getUserHeaders();
    const categoryModel = {
        catagoryId: categoryId,
        shipId,
    };
    return Axios.post(APIRoute + APIPath.DOC_ADD_SHIP(companyId), categoryModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function removeShip(companyId, categoryId, shipId) {
    const userHeaders = await UserController.getUserHeaders();
    const categoryModel = {
        catagoryId: categoryId,
        shipId,
    };
    return Axios.post(APIRoute + APIPath.DOC_REMOVE_SHIP(companyId), categoryModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addUserGroup(companyId, categoryId, userGroupId) {
    const userHeaders = await UserController.getUserHeaders();
    const categoryModel = {
        catagoryId: categoryId,
        userGroupId,
    };
    return Axios.post(APIRoute + APIPath.DOC_ADD_USER_GROUP(companyId), categoryModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function removeUserGroup(companyId, categoryId, userGroupId) {
    const userHeaders = await UserController.getUserHeaders();
    const categoryModel = {
        catagoryId: categoryId,
        userGroupId,
    };
    return Axios.post(APIRoute + APIPath.DOC_REMOVE_USER_GROUP(companyId), categoryModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function addDocumentItem(companyId, documentationCategoryId, title, description, file) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('documentationCategoryId', documentationCategoryId);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('file', file);
    return Axios.post(APIRoute + APIPath.ADD_DOCUMENT_ITEM(companyId), formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function editDocumentItem(companyId, itemId, title, description, file) {
    const userHeaders = await UserController.getUserHeaders('multipart/form-data');
    const formData = new FormData();
    formData.append('id', itemId);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('file', file);
    return Axios.post(APIRoute + APIPath.EDIT_DOCUMENT_ITEM(companyId), formData, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function deleteDocumentItem(companyId, itemId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.post(APIRoute + APIPath.DELETE_DOCUMENT_ITEM(companyId, itemId), null, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function attachDocumentItem(companyId, categoryId, itemId) {
    const userHeaders = await UserController.getUserHeaders();
    const documentModel = {
        catagoryId: categoryId,
        itemId,
    };
    return Axios.post(APIRoute + APIPath.ATTACH_DOCUMENT_ITEM(companyId), documentModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

async function removeDocumentItem(companyId, categoryId, itemId) {
    const userHeaders = await UserController.getUserHeaders();
    const documentModel = {
        catagoryId: categoryId,
        itemId,
    };
    return Axios.post(APIRoute + APIPath.REMOVE_DOCUMENT_ITEM(companyId), documentModel, userHeaders)
        .then(response => {
            return generateResponse(false, response.data, response);
        })
        .catch(reason => {
            return generateResponse(true, reason);
        });
}

export default DocumentController;
