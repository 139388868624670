import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CardHeader, CardContent, CardActions, Card, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import UserController from '../../controllers/UserController';
import { isNullOrUndefined } from '../../helpers/Utils';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: 74,
        zIndex: 25,
        right: 10,
        width: 400,
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 20px)',
            top: 66,
        },
    },
}));

function MOTD(props) {
    const [hidden, setHidden] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const classes = useStyles();

    React.useState(() => {
        if (!props.LogIn.isAuthenticated) {
            return;
        }
        UserController.getMOTD().then(response => {
            if (response.hasError || isNullOrUndefined(response.data) || response.data.length === 0) {
                return;
            }
            setMessage(response.data);
            setHidden(false);
        });
    }, [props]);

    function markAsSeen() {
        setHidden(true);
        UserController.seenMOTD();
    }

    return !hidden ? (
        <Card className={classes.root}>
            <CardHeader title="Message of the Day" />
            <Divider />
            <CardContent>{message}</CardContent>
            <CardActions>
                <Button onClick={markAsSeen}>OK</Button>
            </CardActions>
        </Card>
    ) : null;
}

const mapStateToProps = state => ({
    LogIn: state.Authentication,
});

export default connect(mapStateToProps)(MOTD);

MOTD.propTypes = {
    container: PropTypes.object,
    children: PropTypes.object,
    LogIn: PropTypes.object,
};
